


function PublicDirectLogin() {

    const unique_id = window.location.href.replace(window.location.origin + "/auth_login/", "");

    // check if unique id is UUID
    if (unique_id.length !== 36) {
        return (
            <section className="pt-4 pt-lg-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h1 className="fs-2 mb-0">Invalid Payment Slip</h1>
                            <p>Invalid payment slip unique id</p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    window.localStorage.setItem("token", unique_id);
    window.location.href = "/u/index";
}

export default PublicDirectLogin;