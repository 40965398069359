
import { useMutation } from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import axiosClient from "../hooks/useAxios";
import { handleErrors } from "./handleErrors";
import { server4XXError } from "./types";

type typeUserCustomTxn = {
    service_name: string,
    card_id: string,
    card_holder_name: string,
}

type typeSuccessResponse = {
    success: boolean,
}

const mutationUserCustomTxn = async (payload: typeUserCustomTxn): Promise<typeSuccessResponse> => {
    const response = await axiosClient.post<typeUserCustomTxn, AxiosResponse<typeSuccessResponse>>("/other/custom_txn", payload);
    return response.data;
}

const useMutationUserCustomTxn = () => {
    return useMutation<typeSuccessResponse, AxiosError<server4XXError>, typeUserCustomTxn>({
        mutationKey: "adminSendCards",
        mutationFn: mutationUserCustomTxn,
        onError: handleErrors
    })
}

export {useMutationUserCustomTxn}