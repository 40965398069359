// Code Developed and Maintained by: Mayank Gupta

import { useUserTransactions } from "../../../../api/basic";

import { useContext } from "react";
import AuthContext from "../../../../context/auth";

import { TransactionTable } from "../../../../components/Table/table";



function PublicDashboardCardTable() {
    const { isLogin } = useContext(AuthContext)

    const { data } = useUserTransactions(isLogin);

    return (

        data && <TransactionTable data={data} />

    );
}


export default PublicDashboardCardTable;
