
import { Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';



import AuthContext from '../../context/auth';
import { useContext } from 'react';

import DashboardHeader from "../../components/Header/DashboardHeader";
import Sidebar from "../Public/Dashboard/Components/Sidebar";
import { TopAlert1 } from "../../components/TopAlert/TopAlert";

import AdminMasterPlugin from "./MasterPlugin/MasterPlugin";
import AdminDashboard from "./Dashboard/Dashboard";
import AdminPlugin from "./Plugin/Plugin";

function AdminDashboardIndex() {
	const {  isLogin } = useContext(AuthContext);

    if (!isLogin) {
        return <Navigate to="/login" />
    }

	return (
		<>
		<Sidebar />
		<div className="page-content">
			<TopAlert1 />
			<DashboardHeader />

			<div className="page-content-wrapper p-xxl-4">
				<Routes>
					{/* <Route	path='/'	element={<Navigate to="/u/index" />} /> */}
					<Route path='/'	element={<Navigate replace to="/a/index" />} />
					<Route path='/index'	element={<AdminDashboard />} />
					<Route path='/plugin'	element={<AdminPlugin />} />
                    <Route path='/master_plugin'	element={<AdminMasterPlugin />} />


					{/* <Route path='*'	element={<Navigate replace to="/u/index" />} /> */}
				</Routes>

			</div>

		</div>
		</>
	);
}



export default AdminDashboardIndex;