import React, { useRef } from "react";

import {useMutationAdminSendCards, useMutationAdminWithdrawalCards} from "../../../../api/admin";


function AdminSendCards() {
    const formRef = useRef() as React.MutableRefObject<HTMLFormElement>;

    const sendCards = useMutationAdminSendCards();
    const withdrawalCards = useMutationAdminWithdrawalCards();


    function trigger_send_mutate() {
        if (formRef.current["user_id"] && formRef.current["user_id"].value.length > 0 &&
        formRef.current["amount"] && formRef.current["amount"].value.length > 0) {
                console.log("trigger_mutate", formRef.current);
                sendCards.mutate({
                    user_id: formRef.current["user_id"].value,
                    amount: formRef.current["amount"].value,
                })
        }
    }

    function trigger_withdrawal_mutate() {
        if (formRef.current["user_id"] && formRef.current["user_id"].value.length > 0 &&
        formRef.current["amount"] && formRef.current["amount"].value.length > 0) {
                console.log("trigger_mutate", formRef.current);
                withdrawalCards.mutate({
                    user_id: formRef.current["user_id"].value,
                    amount: formRef.current["amount"].value,
                })
        }
    }


    return (
        <>
        <div className="row">
            <div className="col-md-8 mx-auto">
                <div className="card border">
                    <div className="card-header border-bottom">
                        <h5 className="card-header-title">Send/Withdrawal Balance</h5>
                        <p className="mb-0 small">Send/Withdrawal Balance to any user by ID.</p>
                    </div>
                    {/* <!-- Card body START --> */}
                    <form ref={formRef}>
                        <div className="card-body">

                            <div className="mb-3">
                                <label className="form-label">User ID</label>
                                <input type="text" name="user_id" className="form-control" placeholder="User ID"/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Number Of Cards</label>
                                <input type="text" name="amount" className="form-control" placeholder="Amount"/>
                            </div>

                        </div>


                        <div className="card-footer border-top"> 
                            <div className="text-center d-flex justify-content-around">
                                <button className="btn btn-primary fs-5" type="button" disabled={sendCards.isLoading} onClick={trigger_send_mutate}>
                                    <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{display: !sendCards.isLoading? 'None': ""}}></span>
                                    <span role="status">Send</span>
                                </button>
                                <button className="btn btn-primary fs-5" type="button" disabled={withdrawalCards.isLoading} onClick={trigger_withdrawal_mutate}>
                                    <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{display: !withdrawalCards.isLoading? 'None': ""}}></span>
                                    <span role="status">Withdrawal</span>
                                </button>
                            </div>
                            
                        </div>

                        

                        
                    </form>
                    {/* <!-- Card body END --> */}
                </div>
            </div>
        </div>
        </>
    )
}

export default AdminSendCards;