import { useContext } from 'react';
import AuthContext from '../../../../context/auth';
import { useUserStats } from '../../../../api/basic';
import Loader from '../../../../components/Loader/Loader';

function PublicDashboardMono() {
    const { isLogin} = useContext(AuthContext);

    const { isLoading, data } = useUserStats(isLogin);

    if (isLoading) {
        return (
            // <div className="d-flex justify-content-center" style={{height: '10vh'}}>
            //     <img src="https://cdn.svgator.com/assets/landing-pages/static/css-loader/57579212-0-Loaders-2.svg" alt="loading" style={{maxWidth: "150px", position: "absolute"}}/>
            // </div>
            <Loader middle={false}/>
        )
    }

    return (
        // <!-- Counter boxes START -->
        <div className="row g-4 mb-3">
            {/* Display loading state while data is fetching */}

            {/* <!-- Counter item --> */}
            <div className="col-md-6 col-xxl-3">
                <div className="card card-body bg-warning bg-opacity-10 border border-warning border-opacity-25 p-4 h-100">
                    <div className="d-flex justify-content-between align-items-center">
                        {/* <!-- Digit --> */}
                        <div>
                            <h4 className="mb-0">{data?.user_balance}</h4>
                            <span className="h6 fw-light mb-0">Balance</span>
                        </div>
                        {/* <!-- Icon --> */}
                        <div className="icon-lg rounded-circle bg-warning text-black mb-0"><i className="fa-solid fa-landmark fa-fw"></i></div>
                    </div>
                </div>
            </div>

            {/* <!-- Counter item --> */}
            <div className="col-md-6 col-xxl-3">
                <div className="card card-body bg-success bg-opacity-10 border border-success border-opacity-25 p-4 h-100">
                    <div className="d-flex justify-content-between align-items-center">
                        {/* <!-- Digit --> */}
                        <div>
                            <h4 className="mb-0">{isLoading ? "-" : data?.total_cardlogs}</h4>
                            <span className="h6 fw-light mb-0">Cards</span>
                        </div>
                        {/* <!-- Icon --> */}
                        <div className="icon-lg rounded-circle bg-success text-black mb-0"><i className="fa-solid fa-id-card fa-fw"></i></div>
                    </div>
                </div>
            </div>

            {/* <!-- Counter item --> */}
            <div className="col-md-6 col-xxl-3">
                <div className="card card-body bg-primary bg-opacity-10 border border-primary border-opacity-25 p-4 h-100">
                    <div className="d-flex justify-content-between align-items-center">
                        {/* <!-- Digit --> */}
                        <div>
                            <h4 className="mb-0">{isLoading ? "-" : data?.total_transactions}</h4>
                            <span className="h6 fw-light mb-0">Transactions</span>
                        </div>
                        {/* <!-- Icon --> */}

                        <div className="icon-lg rounded-circle bg-primary text-black mb-0"><i className="fa-solid fa-arrow-right-arrow-left fa-fw"></i></div>
                    </div>
                </div>
            </div>

            {/* <!-- Counter item --> */}
            <div className="col-md-6 col-xxl-3">
                <div className="card card-body bg-info bg-opacity-10 border border-info border-opacity-25 p-4 h-100">
                    <div className="d-flex justify-content-between align-items-center">
                        {/* <!-- Digit --> */}
                        <div>
                            <h4 className="mb-0">{isLoading ? "-" : data?.total_payments}</h4>
                            <span className="h6 fw-light mb-0">Payments</span>
                        </div>
                        {/* <!-- Icon --> */}
                        <div className="icon-lg rounded-circle bg-info text-black mb-0"><i className="fa-solid fa-credit-card fa-fw"></i></div>
                    </div>
                </div>
            </div>
                
            
        </div>
        // <!-- Counter boxes END -->
    )
}

export default PublicDashboardMono;
