
import { useEffect } from "react";
import Branding from "../../Branding/Branding";

import { Link } from "react-router-dom";

import { getTimeAgo } from "../../helper/Time";

function Footer() {
	const { logo_light } = Branding();

	const timeAgo = getTimeAgo(import.meta.env.VITE_BUILD_TIME)
	const commitSha = import.meta.env.VITE_COMMIT_HASH && import.meta.env.VITE_COMMIT_HASH.slice(0, 7)

	let add_class_on_scroll = () => { };
	let remove_class_on_scroll = () => { };

	const backtopbtn = document.querySelector(".back-top");
	if (backtopbtn) {
		add_class_on_scroll = function () {
			backtopbtn.classList.add('back-top-show');
		};

		remove_class_on_scroll = function () {
			backtopbtn.classList.remove('back-top-show');
		};

		backtopbtn.addEventListener('click', () => window.scrollTo({
			top: 0,
			behavior: 'smooth',
		}));
	}

	function onScroll() {
		let scrollpos = window.scrollY;
		if (scrollpos >= 300) {
			add_class_on_scroll()
		} else {
			remove_class_on_scroll()
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', onScroll)
		return () => {
			window.removeEventListener('scroll', onScroll)
		}

	}, []);


	return (
		<>
			<footer className="bg-dark pt-5">
				<div className="container">
					{/* <!-- Row START --> */}
					<div className="row g-4">
						{/* <!-- Widget 1 START --> */}
						<div className="col-lg-3">
							{/* <!-- logo --> */}
							<Link to="/">
								<img className="h-40px" src={logo_light} alt="logo" />

							</Link>
							<p className="my-3 text-muted">Documents simplified, From papers to portable cards.</p>
							<p className="mb-2">
								<Link to="https://chat.whatsapp.com/EoiRd94r6Bc09409JPDsB8" target="_blank" rel="noopener noreferrer" className="text-muted text-primary-hover">
									<i className="bi bi-whatsapp me-2"></i>
									Whatsapp
								</Link>
							</p>
							
							<p className="mb-0">
								<Link to="mailto:support@idcard.store" className="text-muted text-primary-hover">
									<i className="bi bi-envelope me-1"></i>
									support@idcard.store
								</Link>
							</p>
						</div>
						{/* <!-- Widget 1 END --> */}

						{/* <!-- Widget 2 START --> */}
						<div className="col-lg-4 ms-auto">
							<div className="row g-4">
								{/* <!-- Link block --> */}
								<div className="col-6">
									<h5 className="text-white mb-2 mb-md-4">Links</h5>
									<ul className="nav flex-column text-primary-hover">
										<Link className="nav-item nav-link text-muted" to="/login">Login</Link>
										<Link className="nav-item nav-link text-muted" to="/signup">Signup</Link>
										<Link className="nav-item nav-link text-muted" to="/forgot_password">Forgot Password</Link>
										<Link className="nav-item nav-link text-muted" to="contact">Contact us</Link>
										<Link className="nav-item nav-link text-muted" to="/faq">FAQ</Link>
										
									</ul>
								</div>

								{/* <!-- Link block --> */}
								<div className="col-6">
									<h5 className="text-white mb-2 mb-md-4">Page</h5>
									<ul className="nav flex-column text-primary-hover">
										<Link className="nav-item nav-link text-muted" to="/terms">Terms</Link>
										<Link className="nav-item nav-link text-muted" to="/privacy">Privacy Policy</Link>
										<Link className="nav-item nav-link text-muted" to="/refund">Refund</Link>
										<Link className="nav-item nav-link text-muted" to="/how-we-make-cards">How we make cards</Link>
										<Link className="nav-item nav-link text-muted" to="/about">About us</Link>
										
									</ul>
								</div>
							</div>
						</div>
						{/* <!-- Widget 2 END --> */}


						{/* <!-- Payment and card --> */}
						<div className="row g-4 justify-content-between mt-0 mt-md-2">

							{/* <!-- Payment card --> */}
							<div className="col-sm-7 col-md-6 col-lg-4">
								<h5 className="text-white mb-2">Payment & Security</h5>
								<ul className="list-inline mb-0 mt-3">
									<img src="/static/assets/images/upi.png" className="h-50px me-2" alt="" />
									<img src="/static/assets/images/visa.png" className="h-30px me-2" alt="" />
									<img src="/static/assets/images/mastercard.png" className="h-50px me-2" alt="" />
									{/* <img src="/static/assets/images/paytm.png" className="h-40px" alt="" /> */}
									<img src="/static/assets/images/phonepe_icon.png" className="h-40px" alt="" />
								</ul>
							</div>

						</div>

						{/* <!-- Divider --> */}
						<hr className="mt-4 mb-0" />

						{/* <!-- Bottom footer --> */}
						<div className="row">
							<div className="container">
								<div className="d-lg-flex justify-content-between align-items-center py-3 text-center text-lg-start">
									{/* <!-- copyright text --> */}
									<div className="text-muted text-primary-hover">
										Copyrights <i className="bi bi-c-circle"></i> {new Date().getFullYear()} <Link to="https://github.com/mayankfawkes" target="_blank" rel="noopener noreferrer" className="text-muted">
											MayankFawkes
										</Link> - UDYAM-MP-15-0001259
									</div>

									{/* Release/Build info */}
									<div className="text-muted text-primary-hover">
										Build: <i className="fa-solid fa-check fa-shake text-success mx-2"></i> 
										<a className="text-muted text-primary-hover py-0">{commitSha}</a> -  {timeAgo} <i className="fa-solid fa-code-branch mx-2"></i> Master
									</div>
									
									{/* <!-- copyright links--> */}
									{/* <div className="nav mt-2 mt-lg-0">
										<ul className="list-inline text-primary-hover mx-auto mb-0">
											<li className="nav-link text-muted py-0">Build: 2d4cf932</li>
										</ul>
									</div> */}
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</footer>

			<div className="back-top"></div>
		</>
	)
}


export default Footer;