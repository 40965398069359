import { Link } from "react-router-dom";
import applyTheme from "../Theme/Theme";

import {ProfileDropdown, NotificationDropdown} from "./Elements";
import { useContext } from "react";
import AuthContext from "../../context/auth";

import Branding from "../../Branding/Branding";
import { useUser } from "../../api/basic";

function DashboardHeader() {
	applyTheme();

	const branding = Branding();
	
	const { isLogin } = useContext(AuthContext);
	const { isLoading, data } = useUser(isLogin)

	return (
        <>

		{/* <!-- Top bar START --> */}
		<nav className="navbar top-bar navbar-light py-0 py-xl-3">
			<div className="container-fluid p-0">
				<div className="d-flex align-items-center w-100">
	
					{/* <!-- Logo START --> */}
					<div className="d-flex align-items-center d-xl-none">
						<Link className="navbar-brand" to="index.html">
							<img className="navbar-brand-item h-40px" src={branding.logo} alt=""/>
						</Link>
					</div>
					{/* <!-- Logo END --> */}
	
					{/* <!-- Toggler for sidebar START --> */}
					<div className="navbar-expand-xl sidebar-offcanvas-menu">
						<button className="navbar-toggler me-auto p-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSidebar" aria-controls="offcanvasSidebar" aria-expanded="false" aria-label="Toggle navigation" data-bs-auto-close="outside">
							<i className="bi bi-list text-primary fa-fw" data-bs-target="#offcanvasMenu"></i>
						</button>
					</div>
					{/* <!-- Toggler for sidebar END --> */}
					
					{/* <!-- Top bar left --> */}
					<div className="navbar-expand-lg ms-auto ms-xl-0">
						{/* <!-- Toggler for menubar START --> */}
						<button className="navbar-toggler ms-auto p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTopContent" aria-controls="navbarTopContent" aria-expanded="false" aria-label="Toggle navigation">
							<i className="bi bi-search"></i>
						</button>
						{/* <!-- Toggler for menubar END --> */}
						{/* <!-- Topbar menu START --> */}
						<div className="collapse navbar-collapse w-100 z-index-1" id="navbarTopContent">
							{/* <!-- Top search START --> */}
							<div className="nav my-3 my-xl-0 flex-nowrap align-items-center">
								<div className="nav-item w-100">
									<h4 className="mb-0">Hey, {isLoading? "User": (data?.profile? data?.profile.full_name: data?.full_name)}</h4>
								</div>
							</div>
							{/* <!-- Top search END --> */}
						</div>
						{/* <!-- Topbar menu END --> */}
					</div>
					{/* <!-- Top bar left END --> */}
					
					{/* <!-- Top bar right START --> */}
					<ul className="nav flex-row align-items-center list-unstyled ms-xl-auto">
						{/* <!-- Dark mode options START --> */}
						<li className="nav-item dropdown ms-3">
							<button className="nav-notification lh-0 btn btn-light p-0 mb-0" id="bd-theme"
							type="button"
							aria-expanded="false"
							data-bs-toggle="dropdown"
							data-bs-display="static">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-half fa-fw theme-icon-active" viewBox="0 0 16 16">
									<path d="M8 15A7 7 0 1 0 8 1v14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"/>
									<use href="#"></use>
								</svg>
							</button>

							<ul className="dropdown-menu min-w-auto dropdown-menu-end" aria-labelledby="bd-theme">
								<li className="mb-1">
									<button type="button" className="dropdown-item d-flex align-items-center" data-bs-theme-value="light">
										<svg width="16" height="16" fill="currentColor" className="bi bi-brightness-high-fill fa-fw mode-switch me-1" viewBox="0 0 16 16">
											<path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"/>
											<use href="#"></use>
										</svg>Light						
									</button>
								</li>
								<li className="mb-1">
									<button type="button" className="dropdown-item d-flex align-items-center" data-bs-theme-value="dark">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-moon-stars-fill fa-fw mode-switch me-1" viewBox="0 0 16 16">
											<path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z"/>
											<path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z"/>
											<use href="#"></use>
										</svg>Dark
									</button>
								</li>
								<li>
									<button type="button" className="dropdown-item d-flex align-items-center active" data-bs-theme-value="auto">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-half fa-fw mode-switch" viewBox="0 0 16 16">
											<path d="M8 15A7 7 0 1 0 8 1v14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"/>
											<use href="#"></use>
										</svg>Auto
									</button>
								</li>
							</ul>
						</li>
						{/* <!-- Dark mode options END--> */}

						{/* <!-- Notification dropdown START --> */}
						<NotificationDropdown />
						{/* <!-- Notification dropdown END --> */}
		
						{/* <!-- Profile dropdown START --> */}
						<ProfileDropdown />
						{/* <!-- Profile dropdown END --> */}
					</ul>
					{/* <!-- Top bar right END --> */}
				</div>
			</div>
		</nav>
		{/* <!-- Top bar END --> */}

        </>
    )
}



export default DashboardHeader;