import axiosClient from "../hooks/useAxios";
import {AxiosError, AxiosResponse} from "axios";

import { useMutation } from "react-query";
import { handleErrors } from "./handleErrors";
import { server4XXError } from "./types";
import CustomAlert  from "../helper/Alerts";

type typeFormPayload = {
    endpoint: string,
    form: FormData
}

export type typeFormResponseCard = {
    front?: string,
    back?: string,
    a4?: string,
    a4_img?: string,
    html?: string,
}

export type typeFormResponse = {
    cards: typeFormResponseCard[],
    a4_common: string | undefined,
    sample: boolean,
}


const postForm = async (payload: typeFormPayload): Promise<typeFormResponse> => {
    const response = await axiosClient<typeFormPayload, AxiosResponse<typeFormResponse>>({
        method: 'POST',
        url: payload.endpoint,
        data: payload.form,
        headers: { "Content-Type": "multipart/form-data" }
    })
    return response.data;
}

const useMutationSendForm = () => {
    return useMutation<typeFormResponse, AxiosError<server4XXError>, typeFormPayload>({
        mutationKey: ["sendForm"],
        mutationFn: postForm,
        onError: handleErrors,
        onSuccess: (data) => {
            if (data.sample) {
                CustomAlert.warning("Low Balance", "Recharge your account to remove SAMPLE from cards.")
            }
        }
    })
}

export { useMutationSendForm }