import axiosClient from "../hooks/useAxios";
import {AxiosError } from "axios";
import { useQuery } from "react-query";
import { typeUserPayment, typeUserTransaction, typeUserStats, server4XXError, typeUser, typeUserCardLog } from "./types";
import { handleErrors } from "./handleErrors";

const getUserPayments = async (): Promise<typeUserPayment[]> => {
    const response = await axiosClient.post<typeUserPayment[]>("/payments", {});
    return response.data;
};
const useUserPayments = (isLogin:boolean) => {
    return useQuery<typeUserPayment[], AxiosError<server4XXError>>({
        queryKey: ["userPayments"],
        queryFn: getUserPayments,
        enabled: isLogin,
        onError: handleErrors
    });
};

const getUserTransactions = async (): Promise<typeUserTransaction[]> => {
    const response = await axiosClient.post<typeUserTransaction[]>("/transactions", {});
    return response.data;
};
const useUserTransactions = (isLogin:boolean) => {
    return useQuery<typeUserTransaction[], AxiosError<server4XXError>>({
        queryKey: ["userTransactions"],
        queryFn: getUserTransactions,
        enabled: isLogin,
        onError: handleErrors
    });
};

const getUserStats = async (): Promise<typeUserStats> => {
    const response = await axiosClient.post<typeUserStats>("/stats", {});
    return response.data;
}
const useUserStats = (isLogin:boolean) => {
    return useQuery<typeUserStats,  AxiosError<server4XXError>>({
        queryKey: ["userStats"],
        queryFn: getUserStats,
        enabled: isLogin,
        onError: handleErrors
    });
};


const getUser = async (): Promise<typeUser> => {
    const response = await axiosClient.post<typeUser>("/user", {});
    return response.data;
}
const useUser = (isLogin:boolean) => {
    return useQuery<typeUser, AxiosError<server4XXError>>({
        queryKey: ["user"],
        queryFn: getUser,
        enabled: isLogin,
        refetchOnWindowFocus: false,
        onError: handleErrors
    });
}

const getCardlogs = async (): Promise<typeUserCardLog[]> => {
    const response = await axiosClient.post<typeUserCardLog[]>("/cardlogs", {});
    return response.data;

}
const useCardlogs = (isLogin:boolean) => {
    return useQuery<typeUserCardLog[], AxiosError<server4XXError>>({
        queryKey: ["cardlogs"],
        queryFn: getCardlogs,
        enabled: isLogin,
        onError: handleErrors
    });
}

export { 
    useUserPayments, 
    useUserTransactions, 
    useUserStats, 
    useUser,
    useCardlogs
}