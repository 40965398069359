import {useState} from "react";
import {TypeOutput} from "../api/types";

function handleSubmit(e: any) {
    // Prevent the browser from reloading the page
    e.preventDefault();


    console.log("Submitted");
}


function getOutputState() {
    return useState<TypeOutput>({
        cards: [],
        a4_common: "",
        sample: false,
    });
}



export { handleSubmit, getOutputState }