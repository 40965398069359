import applyTheme from '../../../components/Theme/Theme';
import { Link } from 'react-router-dom';

import AuthContext from '../../../context/auth';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import Branding from '../../../Branding/Branding';

import PublicGoogleLogin from './components/GoogleSignin';
import { useMutationLoginDirect } from '../../../api/login';


import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';


import GoogleCaptchaV3 from '../../../components/Captcha/google';

function PublicLogin() {
    applyTheme();

    type LoginPayload = {
        email: string,
        password: string
    }

    const emailRules = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const schema = yup.object().shape({
        email: yup.string().required("Email is required").matches(emailRules, "Email is not valid").lowercase(),
        password: yup.string().required("Password is required")
    });

    const { register, handleSubmit, formState: { errors, dirtyFields } } = useForm<LoginPayload>(
        { resolver: yupResolver(schema), mode: 'all' }
    )

    const { setToken, isLogin } = useContext(AuthContext);
    const { mutateAsync, isLoading } = useMutationLoginDirect();

    const captcha = GoogleCaptchaV3();

    const branding = Branding();

    async function onSubmit(data: LoginPayload) {
        const captcha_answer = await captcha.sureChanllenge("login");

        const response = await mutateAsync(
            {
                ...data,
                ...(branding.login_payload || {}),
                captcha: captcha_answer || ""
            }
        )

        // console.log(response);

        setToken(response.token);
    }


    const searchParams = new URLSearchParams(document.location.search)
    const redirect = searchParams.get("redirect")

    if (isLogin) {
        if (redirect) {
            return <Navigate to={redirect} />
        }
        return <Navigate to="/u/index" />
    }


    return (
        <main>


            <section className="vh-100">
                <div className="container h-100 d-flex px-0 px-sm-4">
                    <div className="row justify-content-center align-items-center m-auto">
                        <div className="col-12">
                            <div className="bg-mode shadow rounded-3 overflow-hidden">
                                <div className="row g-0">
                                    {/* <!-- Vector Image --> */}

                                    <div className="col-lg-6 d-flex align-items-center order-2 order-lg-1">
                                        <div className="p-3 p-lg-5">
                                            <img src="/static/assets/images/element/signin.svg" alt="" />
                                        </div>
                                        <div className="vr opacity-1 d-none d-lg-block"></div>
                                    </div>

                                    {/* <!-- Information --> */}
                                    <div className="col order-1">

                                        <div className="p-4 p-sm-7">
                                            {/* <!-- Logo --> */}

                                            <Link to="/">
                                                <img className="h-50px mb-4" src={branding.logo} alt="logo" />
                                            </Link>
                                            {/* <!-- Title --> */}
                                            <h1 className="mb-2 h3">Welcome Back</h1>

                                            {
                                                !branding.refadmin && (
                                                    <p className="mb-0">
                                                        New here?
                                                        <Link to="/signup" className="mx-2">Create an account</Link>
                                                    </p>
                                                )
                                            }


                                            {
                                                !branding.refadmin && (
                                                    // <form className="mt-4 text-start" onSubmit={onSubmit}>
                                                    //     {/* <!-- Email --> */}
                                                    //     <div className="mb-3">
                                                    //         <label className="form-label">Enter email id</label>
                                                    //         <input type="email" className="form-control" name='email' required />
                                                    //     </div>
                                                    //     {/* <!-- Password --> */}
                                                    //     <div className="mb-3 position-relative">
                                                    //         <label className="form-label">Enter password</label>
                                                    //         <input className="form-control" type={showPassword ? "password" : "text"} name='password' required />
                                                    //         <span style={{ cursor: "pointer" }} className="position-absolute top-50 end-0 translate-middle-y p-0 mt-3" onClick={() => setShowPassword(!showPassword)}>
                                                    //             <i className={showPassword ? "fas fa-eye-slash p-2" : "fas fa-eye p-2"}></i>
                                                    //         </span>
                                                    //     </div>

                                                    //     <div className="mb-3">
                                                    //         {
                                                    //             cloudflareTurnstile.TurnstileDOM
                                                    //         }
                                                    //     </div>

                                                    //     {/* <!-- Remember me --> */}
                                                    //     <div className="mb-3 d-sm-flex justify-content-between">
                                                    //         <div>
                                                    //             <input type="checkbox" className="form-check-input me-2" id="rememberCheck" required />
                                                    //             <label className="form-check-label" htmlFor="rememberCheck">Remember me?</label>
                                                    //         </div>
                                                    //         <Link to="/forgot_password" className="">Forgot password?</Link>
                                                    //     </div>
                                                    //     {/* <!-- Button --> */}
                                                    //     <div>
                                                    //         <button type="submit" className="btn btn-primary w-100 mb-0" disabled={isLoading || cloudflareTurnstile.isLoading}>
                                                    //             <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"
                                                    //                 style={{ display: (isLoading || cloudflareTurnstile.isLoading) ? 'inline-block' : 'none' }}></span>
                                                    //             Login
                                                    //         </button>

                                                    //     </div>

                                                    // </form>

                                                    <Form className="mt-4 text-start" onSubmit={handleSubmit(onSubmit)}>

                                                        <Form.Group className="mb-3" controlId="email">
                                                            <Form.Label>Email address</Form.Label>
                                                            <Form.Control type="email" placeholder="Enter email" {...register("email")} required isInvalid={!!errors.email} isValid={dirtyFields.email && !errors.email} />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.email?.message}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group className="mb-3" controlId="password">
                                                            <Form.Label>Password</Form.Label>
                                                            <Form.Control type="password" placeholder="Password" {...register("password")} required isInvalid={!!errors.password} isValid={dirtyFields.password && !errors.password} />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.password?.message}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <div className="mb-3 d-sm-flex justify-content-between">
                                                            <div>
                                                                <Form.Check type="checkbox" label="Remember me?" required />
                                                            </div>
                                                            <Link to="/forgot_password" className="">Forgot password?</Link>
                                                        </div>

                                                        <div className="mb-3">
                                                            <p className='small text-secondary text-opacity-75'>
                                                                This site is protected by reCAPTCHA and the Google
                                                                <Link className="text-primary-emphasis link-opacity-25" to="https://policies.google.com/privacy"> Privacy Policy</Link> and
                                                                <Link className="text-primary-emphasis text-opacity-50" to="https://policies.google.com/terms"> Terms of Service</Link> apply.
                                                            </p>
                                                        </div>

                                                        <div>
                                                            <button type="submit" className="btn btn-primary w-100 mb-0" disabled={isLoading || captcha.isLoading}>
                                                                <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"
                                                                    style={{ display: (isLoading || captcha.isLoading) ? 'inline-block' : 'none' }}></span>
                                                                Login
                                                            </button>

                                                        </div>
                                                    </Form>

                                                )
                                            }


                                            {/* <!-- Divider --> */}
                                            <div className="position-relative my-4">
                                                <hr />
                                                <p className="small bg-mode position-absolute top-50 start-50 translate-middle px-2">Or sign in with</p>
                                            </div>


                                            {/* <!-- Google and facebook button --> */}

                                            <PublicGoogleLogin />

                                            {/* <!-- Divider --> */}

                                            {/* <!-- Copyright --> */}
                                            <div className="text-primary-hover mt-3 text-center">
                                                Copyrights <i className="bi bi-c-circle"></i> {new Date().getFullYear()} <Link to="https://github.com/mayankfawkes" target="_blank" rel="noopener noreferrer" className="text-muted">
                                                    MayankFawkes
                                                </Link>
                                                <br />
                                                Registration No: UDYAM-MP-15-0001259
                                            </div>


                                            {/* <!-- Form END --> */}
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}


export default PublicLogin;