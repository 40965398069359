

import PublicDashboardTitle from "../../Public/Dashboard/Components/Title";

import { useContext } from "react";
import AuthContext from "../../../context/auth";
import AdminDhasboardCards from "../Components/DashboardCards";
import UsersTable from "../Components/UsersTable";

import { TransactionTable, PaymentsTable, CardLogsTable} from "../../../components/Table/table";

import { useAdminInit } from "../../../api/admin";

import Loader from "../../../components/Loader/Loader";

function AdminDashboard() {
    const { isLogin, user } = useContext(AuthContext);
    const { data, isLoading } = useAdminInit(isLogin);

    if (isLoading || !data) {
        return (
            <Loader middle={true}/>
        )
    }

    var card_logs_today = {}
    var card_logs_by_user = {}

    Object.entries(data.card_logs_today).map(([_ , payload]) => {
        Object.entries(payload).map(([card, count]) => {
            if (card_logs_today[card]) {
                card_logs_today[card] += count
            } else {
                card_logs_today[card] = count
            }
        })
    })

    Object.entries(data.card_logs_today).map(([user, payload]) => {
        Object.entries(payload).map(([_, count]) => {
            if (card_logs_by_user[user]) {
                card_logs_by_user[user] += count
            } else {
                card_logs_by_user[user] = Number(count)
            }
        })
    })

    let card_logs_by_user_entries = Object.entries(card_logs_by_user).sort(function(a:any[], b:any[]) {
        return b[1] - a[1];
    });

    console.log("todays users count", card_logs_by_user_entries.length)
    console.log("Top_users_list", card_logs_by_user_entries)

    console.log(card_logs_today)

    const data_for_cards: [string, string][] = [
        ["Total Users", String(data.total_users)],
        ["Today's Users", String(data.total_users_today)],

        ["Total Transactions", String(data.total_transactions)],
        ["Today's Card Logs", String(data.total_card_logs_today)],

        ["Total Payments", String(data.total_payments)],
        ["Today's Payments", String(data.total_payments_today)],

        ["Aadhaar", String(card_logs_today["aadhaar"] || 0)],
        ["Passport Size Photo", String(card_logs_today["passport_size_photo"] || 0)],
        ["Driving Licence", String(card_logs_today["driving_licence"] || 0)],

        ["Advance Ayushman", String(card_logs_today["advance_ayushman"] || 0)],
        ["Voter Epic", String(card_logs_today["voter_epic"] || 0)],
        ["Ration UP", String(card_logs_today["ration_up"] || 0)],
    ]

    return (
        <>

        <PublicDashboardTitle title={"Admin Dashboard"} icon={"bi bi-house-door fa-fw me-1"} addcardbtn={false} />

        <AdminDhasboardCards data={data_for_cards} />

        <UsersTable data={data.users} />
        
        <TransactionTable data={data.transactions} />

        {
            data && <CardLogsTable data={data.cardlogs} />
        }


        {
            data && !(user.acquired_by || user.is_subadmin) && <PaymentsTable data={data.payments} />
        }

    </>
    )
}




export default AdminDashboard;