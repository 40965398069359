
import { useState } from 'react';
import { Link } from 'react-router-dom';


type typeFaq = {
    question: string,
    answer: string
}

type typeFaqCategory = {
    [key: string]: typeFaq[]
}



function PublicFAQPage() {
    const data = {
        "Aadhar Card FAQs": [
            {
                "question": "What is Aadhar Card?",
                "answer": "Aadhar Card is a unique identification number issued by the Indian government to Indian citizens."
            },
            {
                "question": "How can I apply for an Aadhar Card?",
                "answer": "You can apply for an Aadhar Card by visiting the nearest Aadhar enrolment center with your identity proof and address proof documents."
            },
            {
                "question": "What documents are required to apply for an Aadhar Card?",
                "answer": "You need to provide a proof of identity and a proof of address document to apply for an Aadhar Card."
            },
            {
                "question": "How long does it take to get an Aadhar Card?",
                "answer": "It takes around 90 days to get an Aadhar Card after you have applied for it."
            },
            {
                "question": "What is the fee for applying for an Aadhar Card?",
                "answer": "There is no fee for applying for an Aadhar Card."
            }
        ],
        "Vaccine Certificate FAQs": [
            {
                "question": "What is a Vaccine Certificate?",
                "answer": "A Vaccine Certificate is a document that proves that you have been vaccinated against COVID-19."
            },
            {
                "question": "How can I download my Vaccine Certificate?",
                "answer": "You can download your Vaccine Certificate from the CoWIN portal or the Aarogya Setu app."
            },
            {
                "question": "What information does the Vaccine Certificate contain?",
                "answer": "The Vaccine Certificate contains your name, age, gender, date of vaccination, vaccine name, and other details related to your vaccination."
            },
            {
                "question": "Is the Vaccine Certificate mandatory for travel?",
                "answer": "Yes, the Vaccine Certificate is mandatory for travel in some countries and states in India."
            },
            {
                "question": "What should I do if there is an error in my Vaccine Certificate?",
                "answer": "If there is an error in your Vaccine Certificate, you can get it corrected by visiting the nearest vaccination center or contacting the helpline number."
            }
        ],
        "Payment FAQs": [
            {
                "question": "What payment methods are accepted on ID Maker?",
                "answer": "You can add any amount ranging from ₹20 to ₹500 to your account via any payment method such as UPI, Debit card, credit card etc."
            },
            {
                "question": "How secure are my payments on ID Maker?",
                "answer": "Your payments on ID Maker are secure and encrypted using SSL technology."
            },
            {
                "question": "What should I do if my payment fails on ID Maker?",
                "answer": "If your payment fails on ID Maker, please wait for 15 minutes and try again. If it still doesn't work, email support@idcard.store with your email and phone number with a payment screenshot."
            },
            {
                "question": "Is there any fee for making IDs on ID Maker?",
                "answer": "You can make the first 5 IDs for free after signing up with Google. After that, you need to add money to your account via any payment method such as UPI, Debit card, credit card etc."
            },
            {
                "question": "Can I get a refund if I am not satisfied with my IDs on ID Maker?",
                "answer": "No, there are no refunds available once you have made your IDs on ID Maker."
            }
        ],
        "Privacy FAQs": [
            {
                "question": "Does ID Maker store my IDs locally?",
                "answer": "No, ID Maker does not store your IDs locally as we care about users' privacy. Processing/processed IDs are stored in memory and get flushed out of the memory as the request ends."
            },
            {
                "question": "What information does ID Maker collect from me while making IDs?",
                "answer": "We collect minimal information required for ID creation, such as your name and the data you provide for the ID card. We do not store any sensitive data."
            },
            {
                "question": "How long does it take to make an ID using ID Maker?",
                "answer": "ID Maker processes your ID in a matter of seconds, ensuring a quick and efficient service."
            },
            {
                "question": "Is ID Maker affiliated with any government authority?",
                "answer": "No, ID Maker is an independent service provider and is not affiliated with any government entity. Our IDs are for informational purposes only."
            },
            {
                "question": "How can I contact ID Maker's customer support?",
                "answer": "You can reach our customer support team by emailing support@idcard.store or by calling +91-89897 94676 (12 PM - 6 PM). We aim to respond to all queries within 48 hours."
            },
            {
                "question": "Do you offer customer support on messaging apps?",
                "answer": "Yes, we have support groups on Telegram and WhatsApp. You can join these groups for quick assistance."
            }
        ]
    }

    const [activeFaq , setActiveFaq] = useState<typeFaqCategory>(data)

    function updateActiveFaq(search) {
        if (search === "") {
            setActiveFaq(data);
            return;
        }
        const newActiveFaq = {};
        // add mark tag to search words
        Object.keys(data).forEach(faqCategory => {
            newActiveFaq[faqCategory] = data[faqCategory].filter(faq => {
                return faq.question.toLowerCase().includes(search.toLowerCase()) || faq.answer.toLowerCase().includes(search.toLowerCase())
            })

            newActiveFaq[faqCategory] = newActiveFaq[faqCategory].map(faq => {
                faq.question = faq.question.replace(search, `<mark>${search}</mark>`);
                faq.answer = faq.answer.replace(search, `<mark>${search}</mark>`);
                return faq;
            })
        })

        setActiveFaq(newActiveFaq);
    }

    function onSubmit(e) {
        e.preventDefault();
        
        const search = e.target.tagName === "FORM" ? e.target.search.value : e.target.value;
        updateActiveFaq(search);
    }


 


    return (
        <>
            <section className="pt-4 pt-lg-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto text-center">
                            {/* <!-- Breadcrumb --> */}
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-dots justify-content-center">
                                    <li className="breadcrumb-item"><Link to="/"><i className="bi bi-house me-1"></i> Home</Link></li>
                                    <li className="breadcrumb-item active">Faqs</li>
                                </ol>
                            </nav>
                            {/* <!-- Title --> */}
                            <h6 className="text-primary">Faqs</h6>
                            <h1>Frequently Asked Questions</h1>
                            {/* <!-- Info --> */}
                            <p>have questions? We're here to help you</p>
                            {/* <!-- Search --> */}
                            <form className="col-lg-8 position-relative mx-auto" onChange={onSubmit} onSubmit={onSubmit}>
                                <input className="form-control form-control-lg pe-5" name="search" type="search" placeholder="Type search words" aria-label="Search" />
                                <button className="text-primary-hover text-reset border-0 bg-transparent position-absolute top-50 end-0 translate-middle-y pe-3 py-0"><i className="fas fa-search fs-6"></i></button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 mx-auto">
                            <div className="vstack gap-4">

                                {
                                    activeFaq && Object.keys(activeFaq).map((faqCategory, index) => {
                                        if (activeFaq[faqCategory].length === 0) return null;
                                        return (
                                            <div className="card border bg-transparent p-0" key={index}>
                                                {/* <!-- Card header --> */}
                                                <div className="card-header bg-transparent border-bottom p-4">
                                                    <h5 className="mb-0">{faqCategory}</h5>
                                                </div>

                                                {/* <!-- Card body --> */}
                                                <div className="card-body p-4 pt-0">
                                                    {
                                                        activeFaq[faqCategory].map((faq, index) => {
                                                            return (
                                                                <div className="mt-4" key={index}>
                                                                    <h6 className="fw-normal" dangerouslySetInnerHTML={{__html: faq.question}}></h6>
                                                                    <p className="mb-0" dangerouslySetInnerHTML={{__html: faq.answer}}></p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-4 pt-lg-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto text-center fst-italic">
                            {/* <!-- Info --> */}
                            <p>Please be aware that some of the FAQs provided here were generated by AI and may contain information unrelated to ID Maker. 
                                We recommend independently verifying any information that is not directly related to ID Maker services. 
                                ID Maker is not responsible for the accuracy or reliability of information outside the scope of its services.</p>
                            {/* <!-- Search --> */}

                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default PublicFAQPage;