import { useUserPayments } from "../../../../api/basic";

import { useContext } from "react";
import AuthContext from "../../../../context/auth";


import { PaymentsTable } from "../../../../components/Table/table";


function PublicDashboardOrderTable() {
    const {isLogin} = useContext(AuthContext);

    const { data } = useUserPayments(isLogin);

    return (
        data && <PaymentsTable data={data} />
    )

}


export default PublicDashboardOrderTable;
