
import { useMutationPaymentStatus } from '../../../../api/admin';
import { useMutationPaytmPaymentStatus } from '../../../../api/paytm';
import { useRef } from 'react';


function AdminPaymentStatus() {
    const { isLoading, mutate } = useMutationPaytmPaymentStatus();
    const { isLoading: isLoading2, mutate: mutate2 } = useMutationPaymentStatus();

    var order_id_ref = useRef<HTMLInputElement>(null);

    function onSubmitPaytm() {
        if (order_id_ref?.current?.value) {
            mutate({
                order_id: order_id_ref.current.value
            })
        }
    }

    function onSubmit() {
        if (order_id_ref?.current?.value) {
            mutate2({
                order_id: order_id_ref.current.value
            })
        }
    }


    return (
     <div className="row">
        <div className="col-md-6 mx-auto">
            <div className="card border">
                <div className="card-header border-bottom">
                    <h5 className="card-header-title">Payment Status</h5>
                    <p className="mb-0 small">Check payment status by order id.</p>
                </div>
                {/* <!-- Card Body START --> */}

                <div className="card-body">

                    <div className="mb-3">
                        <label className="form-label">Order ID <span className="text-danger">*</span></label>
                        <input type="text" name="order_id" className="form-control" placeholder="Order ID" required ref={order_id_ref}/>
                    </div>

                </div>


                <div className="card-footer border-top">
                    <div className="row text-center">
                        <button className="col m-2 btn btn-primary fs-5" onClick={onSubmitPaytm} disabled={isLoading}>
                            <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{display: !isLoading? 'None': ""}}></span>
                            <span role="status">Paytm</span>
                        </button>
                        <button className="col m-2 btn btn-primary fs-5" onClick={onSubmit} disabled={isLoading2}>
                            <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{display: !isLoading2? 'None': ""}}></span>
                            <span role="status">Database</span>
                        </button>
                    </div>
                </div>
                    

                {/* <!-- Card Body END --> */}
            </div>
        </div>
    </div>
    )
}


export default AdminPaymentStatus;