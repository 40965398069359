import axios from 'axios';

const getToken = () => {
  return localStorage.getItem('token');
}

const axiosClient = () => {
  const defaultOptions = {
    baseURL: import.meta.env.VITE_API_URL, // process.env.REACT_APP_API_PATH,
    timeout: 1000 * 60 * 10,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = getToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });


  return instance;
};

export default axiosClient();