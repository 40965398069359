import { NavLink, Link } from "react-router-dom";
import BrandingLogo from "../../../../components/Branding/Logo";

import { useContext } from "react";
import AuthContext from "../../../../context/auth";

function Sidebar() {
	const {logout, user} = useContext(AuthContext);


    return (
        <>
	
	{/* <!-- Sidebar START --> */}
	<nav className="navbar sidebar navbar-expand-xl navbar-light">
		{/* <!-- Navbar brand for xl START --> */}
		<div className="">
			{/* <!-- Logo START --> */}
			<BrandingLogo  to={"/u"}/>
		</div>
		{/* <!-- Navbar brand for xl END --> */}
		
		<div className="offcanvas offcanvas-start flex-row custom-scrollbar h-100" data-bs-backdrop="true" tabIndex={-1} id="offcanvasSidebar">
			<div className="offcanvas-body sidebar-content d-flex flex-column pt-4">
	
				{/* <!-- Sidebar menu START --> */}
				<ul className="navbar-nav flex-column" id="navbar-sidebar" style={{fontSize: "1.05rem"}}>
					{/* <!-- Menu item --> */}
					<li className="nav-item"><NavLink to="/u/index" className="nav-link"><i className="bi bi-house-door fa-fw me-1"></i> Dashboard</NavLink></li>

					{/* <!-- Title --> */}
					<li className="nav-item ms-2 my-2">Services</li>

					<li className="nav-item"> <NavLink className="nav-link" to="/u/passport_size_photo"><i className="bi bi-camera fa-fw me-1"></i> Passport Photo</NavLink></li>
					<li className="nav-item"> <NavLink className="nav-link" to="/u/editor"><i className="fa-solid fa-pen fa-fw me-1"></i> Editor</NavLink></li>
					<li className="nav-item"> <NavLink className="nav-link" to="/u/all_cards"><i className="far fa-address-card fa-fw me-1"></i> Cards</NavLink></li>
					<li className="nav-item"> <NavLink className="nav-link" to="/u/all_free"><i className="fa-solid fa-fire fa-fw me-1"></i> Free Cards</NavLink></li>
					<li className="nav-item"> <NavLink className="nav-link" to="/u/kundli"><i className="fa-solid fa-meteor fa-fw me-1"></i> Kundli</NavLink></li>
					<li className="nav-item"> <NavLink className="nav-link" to="/u/page_maker"><i className="bi bi-file-earmark-fill fa-fw me-1"></i> Page Maker</NavLink></li>
					<li className="nav-item"> <NavLink className="nav-link" to="/u/resume_maker"><i className="fa-solid fa-file-lines fa-fw me-1"></i> Resume Maker</NavLink></li>
					<li className="nav-item"> <NavLink className="nav-link" to="/u/school_id"><i className="bi bi-person-badge fa-fw me-1"></i> ID Cards</NavLink></li>
					<li className="nav-item"> <NavLink className="nav-link" to="/u/add_money"><i className="bi bi-bank fa-fw me-1"></i> Add Money</NavLink></li>

					{
						(!user?.is_subadmin && !user?.is_admin) && (
							<>
								<li className="nav-item ms-2 my-2">Other</li>

								<li className="nav-item"> <NavLink className="nav-link" to="/contact">Contact</NavLink></li>
								<li className="nav-item"> <NavLink className="nav-link" to="/faq">FAQ</NavLink></li>
							</>
						)
					}



					{
						(user?.is_subadmin || user?.is_admin) && (
							<>
								<li className="nav-item ms-2 my-2">Admin</li>

								<li className="nav-item"> <NavLink className="nav-link" to="/a/index">Dashboard</NavLink></li>
								<li className="nav-item"> <NavLink className="nav-link" to="/a/plugin">Plugins</NavLink></li>
							</>
						)
					}
					{
						user?.is_admin && (
							<>
								<li className="nav-item"> <NavLink className="nav-link" to="/a/master_plugin">Admin Plugin</NavLink></li>
							</>
						)
					}


				</ul>
				{/* <!-- Sidebar menu end --> */}
	
				{/* <!-- Sidebar footer START --> */}
				<div className="d-flex align-items-center justify-content-between text-primary-hover mt-auto p-3">
					<Link className="h6 fw-light mb-0 text-body" to="/login" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Sign out" onClick={logout}>
						<i className="fa-solid fa-arrow-right-from-bracket"></i> Log out
					</Link>
					<Link className="h6 mb-0 text-body" to="/u/settings" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Settings">
						<i className="bi bi-gear-fill"></i>
					</Link>
				</div>
				{/* <!-- Sidebar footer END --> */}
				
			</div>
		</div>
	</nav>
	{/* <!-- Sidebar END --> */}

        </>
    )
}


export default Sidebar;