import { AxiosError } from "axios";
import CustomAlert from "../helper/Alerts";
import { server4XXError } from "./types";


function handleErrors(error: AxiosError<server4XXError>) {
    console.log(error);

    if (error.response) {
        console.log(error.response);
        const { data, status } = error.response;
        if (status === 422) {
            CustomAlert.error("Oops! Something went wrong", data.detail[0]["loc"][1] + " : "+ data.detail[0]["msg"]);
            return;
        }
        if (status === 401) {
            CustomAlert.error("Oops! Something went wrong", "Invalid credentials, please try again.");
            window.location.href = "/login";
            return;
        }
        else if (data.detail) {
            CustomAlert.error("Oops! Something went wrong", data.detail);
            return;
        } else if (status >= 500) {
            CustomAlert.error(
                "Server Error",
                "Encountered an error while processing your request. Please try again later or contact admin."
            );

            return;
        } else {
            CustomAlert.error("Unknown Error", JSON.stringify(data));
            return;
        }
    } else if (error.request) {
        if (error.code == "ERR_NETWORK") {
            CustomAlert.error(error.message, "Something went wrong with the server, please contact admin or try again later.");
            return;
        } else if (error.code == "ECONNABORTED") {
            CustomAlert.error(error.message, "Request timeout, please try again later,  Path: " + error.config?.url);
            return;
        } else {
            CustomAlert.error("Unknown Error", `${error.message}<br>${error.config?.url}<br>$}`);
            return;
        }
    } else {
        CustomAlert.error("Unknown Error", `${error.message}, ${error.config?.url}}`);
        return;
    }
}


export { handleErrors }