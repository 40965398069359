
import Header from "../../../components/Header/Header";
import { TopAlert1 } from "../../../components/TopAlert/TopAlert";

import PublicDashboardSettingsProfile from "./Profile/Profile";
import { Routes, Route }
	from 'react-router-dom';

// import { Navigate } from 'react-router-dom';
import PublicDashboardSettingsSidebar from "./Components/Sidebar";
import PublicDashboardSettingsOrders from "./Payments/Payments";
import PublicDashboardSettingsTransactions from "./Transactions/Transactions";
import PublicDashboardSettingsSecurity from "./Security/Security";


import AuthContext from '../../../context/auth';
import { useContext, useEffect } from 'react';
import { alertLogin } from '../../../helper/Alerts';
import { Navigate } from 'react-router-dom';


function PublicDashboardSettings() {
	const { isLogin } = useContext(AuthContext);

	useEffect(() => {
		if (!isLogin) {
			alertLogin()
		}
	}, [isLogin])


	return (
		<>

			<div>
				<TopAlert1 />
				<Header to="/u" />
			</div>

			<main>
				{/* <!-- ======================= Content START --> */}
				<section className="pt-3">
					<div className="container">
						<div className="row">
							<PublicDashboardSettingsSidebar />

							<div className="col-lg-8 col-xl-9">
								{/* <!-- Offcanvas menu button --> */}
								<div className="d-grid mt-3 mb-0 d-lg-none w-100">
									<button className="btn btn-primary mb-4" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSidebar" aria-controls="offcanvasSidebar">
										<i className="fas fa-sliders-h"></i> Menu
									</button>
								</div>

								<Routes>
									{/* <Route exact path='/'  element={<Navigate replace to="index" />} /> */}
									<Route path='/' element={<PublicDashboardSettingsProfile />} />
									<Route path='/payments' element={<PublicDashboardSettingsOrders />} />
									<Route path='/transactions' element={<PublicDashboardSettingsTransactions />} />
									<Route path='/security' element={<PublicDashboardSettingsSecurity />} />

									<Route path='*' element={<Navigate to="/" />} />
								</Routes>
							</div>

						</div>
					</div>
				</section>
			</main>
    // {/* <!-- ======================= Content END --> */}


		</>
	);
}


export default PublicDashboardSettings;