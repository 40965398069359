import PropTypes from 'prop-types';
import { LiteAlert } from '../../../../components/Alert/Alert';
import { useUserNotification } from '../../../../api/notification';
import { useState } from 'react';

import {NewCardRequestModal} from "../../../../helper/Modal";

function PublicDashboardTitle({ title, icon, addcardbtn }) {
	const [show, setShow] = useState(false);
	const modalClose = () => setShow(false);
    const modalShow = () => setShow(true);

	const { data } = useUserNotification();
	
	const top_alert = data?.pages[window.location.pathname];


	return (
		<>
			<div className="row">
				<div className="col-12 mb-4 mb-sm-5">
					<div className="d-sm-flex justify-content-between align-items-center">
						<h1 className="h3 mb-2 mb-sm-0 text-capitalize d-flex align-items-center"> <i className={icon + " me-3"}></i> {title}</h1>
						{addcardbtn && (<div className="d-grid">
							<button className="btn btn-primary-soft mb-0" onClick={modalShow}>
							✨  New Service Request
							</button>
						</div>)}
					</div>
				</div>
			</div>

			<NewCardRequestModal show={show} onClose={modalClose} />

			{
				top_alert && top_alert.map((item, index) => {
					return <LiteAlert key={index} icon={item.type} title={item.title} description={item.description} link={item.link} />
				})
			
			}

		</>
	)
}

// type of props
PublicDashboardTitle.propTypes = {
	title: PropTypes.string.isRequired,
	icon: PropTypes.string,
	addcardbtn: PropTypes.bool
}


export default PublicDashboardTitle;