import { Link } from 'react-router-dom';
import Branding from '../../../../../Branding/Branding';

function PublicDashboardIDCard(props: any) {

    const { icon, name, to, imgclass, price } = props;
    const branding = Branding();

    return (

        <div className="col-sm-6 col-md-4 col-xl-3">
            <div className="card shadow text-center align-items-center h-100 p-4">
                {/* <!-- Image --> */}
                {/* <div className="bg-white overflow-hidden rounded-circle text-center" style={{ width: "7rem", height: "7rem" }}>
                    <img src={icon} className={imgclass} loading="lazy" alt="" />
                </div> */}
                <div className="bg-white overflow-hidden rounded-circle d-flex align-items-center justify-content-center" style={{ width: "7rem", height: "7rem" }}>
                    <img src={icon} className={imgclass} loading="lazy" alt="" />
                </div>
                {/* <!-- Card body --> */}
                <div className="card-body px-0 pb-0">
                    <h5 className="card-title"><Link to={to} className="stretched-link">{name}</Link></h5>
                    {!branding.refadmin && (<span>{price ? price : "Rs. 2.2 / Card"}</span>)}
                </div>
            </div>
        </div>

    )
}

export default PublicDashboardIDCard;