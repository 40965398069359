
import { AmitAddMoney, LalitAddMoney, KapilAddMoney } from "../pages/RefAdmin/AddMoney";

export type Settings = {
    owner?: string;
    site_name: string;
    index_page: boolean;
    logo: string;
    logo_light: string;
    noindex: boolean;
    refadmin: boolean;
    google_analytics: string;
    add_money?: JSX.Element;
    login_payload?: {
        acquired_by: number
    }
}

const SETTINGS:{[key:string] : Settings} = {
    "idcard.store": {
        site_name: "ID Maker",
        index_page: true,
        noindex: false,
        logo: "/static/img/nobg-out.gif",
        logo_light: "/static/img/nobg-out.gif",
        google_analytics: "G-XTQ8ZYSED5",
        refadmin: false,
    },
    "store": {
        site_name: "ID Maker",
        index_page: true,
        noindex: false,
        logo: "/static/img/nobg-out.gif",
        logo_light: "/static/img/nobg-out.gif",
        google_analytics: "G-XTQ8ZYSED5",
        refadmin: false,
    },
    "default": {
        site_name: "ID Maker",
        index_page: true,
        noindex: true,
        logo: "/static/img/nobg-out.gif",
        logo_light: "/static/img/nobg-out.gif",
        google_analytics: "G-XTQ8ZYSED5",
        refadmin: false,
    },
    "smartidcard.in": {
        site_name: "ID Maker",
        index_page: false,
        noindex: true,
        logo: "/static/img/nobg-out.gif",
        logo_light: "/static/img/nobg-out.gif",
        google_analytics: "G-XTQ8ZYSED5",
        owner: "amit",
        add_money: <AmitAddMoney />,
        login_payload: {
            acquired_by: 3652,
        },
        refadmin: true,
    },
    "documentconvert.in": {
        site_name: "ID Maker",
        index_page: false,
        noindex: true,
        logo: "/static/img/nobg-out.gif",
        logo_light: "/static/img/nobg-out.gif",
        google_analytics: "G-XTQ8ZYSED5",
        owner: "kapil",
        add_money: <KapilAddMoney />,
        login_payload: {
            acquired_by: 482,
        },
        refadmin: true,
    },
    "superprint.website": {
        site_name: "ID Maker",
        index_page: false,
        noindex: true,
        logo: "/static/img/nobg-out.gif",
        logo_light: "/static/img/nobg-out.gif",
        google_analytics: "G-XTQ8ZYSED5",
        owner: "lalit",
        add_money: <LalitAddMoney />,
        login_payload: {
            acquired_by: 4136,
        },
        refadmin: true,
    },
    "superprint.cloud": {
        site_name: "ID Maker",
        index_page: false,
        noindex: true,
        logo: "/static/img/nobg-out.gif",
        logo_light: "/static/img/nobg-out.gif",
        google_analytics: "G-XTQ8ZYSED5",
        owner: "lalit",
        add_money: <LalitAddMoney />,
        login_payload: {
            acquired_by: 4136,
        },
        refadmin: true,
    },
    "website": {
        site_name: "ID Maker",
        index_page: false,
        noindex: true,
        logo: "/static/img/nobg-out.gif",
        logo_light: "/static/img/nobg-out.gif",
        google_analytics: "G-XTQ8ZYSED5",
        owner: "lalit",
        add_money: <LalitAddMoney />,
        login_payload: {
            acquired_by: 4136,
        },
        refadmin: true,
    },
}

function Branding(): Settings {
    const site = window.location.hostname;
    return SETTINGS[site] || SETTINGS["default"]
}


export default Branding;

export { SETTINGS };