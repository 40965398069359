import { useMutationAdminSendBalance } from '../../../../api/admin';


function AdminSendBalance() {
    const { isLoading, mutate } = useMutationAdminSendBalance();

    function onSubmit(e: any) {
        e.preventDefault();

        mutate({
            user_id: e.target.user_id.value,
            amount: e.target.amount.value,
            reason: e.target.reason.value
        })
    }


    return (
     <div className="row">
        <div className="col-md-6 mx-auto">
            <div className="card border">
                <div className="card-header border-bottom">
                    <h5 className="card-header-title">Payment Status</h5>
                    <p className="mb-0 small">Check payment status by order id.</p>
                </div>
                {/* <!-- Card Body START --> */}
                <form onSubmit={onSubmit}>
                    <div className="card-body">

                        <div className="mb-3">
                            <label className="form-label">User ID <span className="text-danger">*</span></label>
                            <input type="text" name="user_id" className="form-control" placeholder="User ID" required/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Amount <span className="text-danger">*</span></label>
                            <input type="text" name="amount" className="form-control" placeholder="Amount" required/>
                        </div>
                        {/* select reason */}
                        <div className="mb-3">
                            <label className="form-label">Reason <span className="text-danger">*</span></label>
                            <select className="form-select" name="reason" required>
                                <option value="reward">Reward</option>
                                <option value="refund">Refund</option>
                                <option value="purchase">Purchase</option>
                            </select>
                        </div>

                    </div>


                    <div className="card-footer border-top">
                        <div className="text-center">
                            <button className="btn btn-primary fs-5">
                                <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{display: !isLoading? 'None': ""}}></span>
                                <span role="status">Submit</span>
                            </button>
                        </div>
                    </div>
                    
                </form>
                {/* <!-- Card Body END --> */}
            </div>
        </div>
    </div>
    )
}


export default AdminSendBalance;