import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

function AlertWithIcon({ title, description, icon = "bi bi-exclamation-octagon-fill", colour = "blue" }) {
    let cls = "";
    if (colour === "red") cls = "alert-danger";
    else if (colour === "green") cls = "alert-success";
    else if (colour === "blue") cls = "alert-primary";
    else if (colour === "yellow") cls = "alert-warning";
    else if (colour === "purple") cls = "alert-secondary";
    else if (colour === "pink") cls = "alert-info";
    else if (colour === "grey") cls = "alert-dark";

    return (
        <div className={"alert " + cls + " d-flex align-items-center rounded-3 mb-4 "} role="alert">
            <h4 className="mb-0 alert-heading"><i className={icon + " me-2"}></i> </h4>
            <div className="ms-3">
                <h6 className=" alert-heading">{title}</h6>
                <p className="mb-0">{description}</p>
            </div>
        </div>
    )
}

AlertWithIcon.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string,
    colour: PropTypes.string.isRequired
}


function InfoAlert({ title, content, close = true, img = "https://cdn-icons-png.flaticon.com/512/7959/7959364.png", element=null }: { title: string, img?: string, content: string[], close: boolean, element?: any }) {
    return (
        <div className="alert alert-success alert-dismissible fade show" role="alert">
            {/* <!-- Title --> */}
            <div className="d-sm-flex align-items-center mb-3">
                <img src={img} className="w-60px me-3 mb-2 mb-sm-0" alt="" />
                <h5 className="alert-heading mb-0">{title}</h5>
            </div>

            {/* <!-- Content --> */}

            {
                content.map((item, index) => {
                    return <p key={index} className="my-4">{item}</p>
                })
            }

            {/* <!-- Element --> */}
            {
                element
            }

            {/* <!-- Close button --> */}

            {
                close && <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            }


        </div>
    )
}



InfoAlert.propTypes = {
    title: PropTypes.string.isRequired,
    img: PropTypes.string,
    content: PropTypes.array.isRequired,
    close: PropTypes.bool,
    element: PropTypes.element
}


function CardNotification({ title, message, sample_files }: { title: string, message: string, sample_files: string[] }) {
    return (
        <div className="col-12 col-md-10 col-lg-8 mx-auto text-center">
            <div className="alert alert-success rounded-3" role="alert">
                <h4 className="alert-heading"> {title} </h4>
                <div dangerouslySetInnerHTML={{ __html: message }}></div>
                {
                    sample_files.length > 0 && <hr />
                }
                <p className="mb-0">
                    {sample_files.map((item, index) => {
                        return <a key={index} className="btn btn-success-soft me-2" href={item}>Sample {index + 1}</a>
                    })}
                </p>
            </div>
        </div>
    )
}


function LiteAlert({ icon, title, description, link }: { icon: string, title: string, description: string, link?: string }) {
    const svgs = {
        "danger": `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill" 
            viewBox="0 0 16 16"><path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 
            5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" /></svg>`,
        "warning": `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
            className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                <path
                d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>`,
        "primary": `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
            className="bi bi-info-circle-fill" viewBox="0 0 16 16">
                <path
                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
            </svg>`
    }

    return (
        <div className={`alert alert-${icon} d-flex align-items-center rounded-3 alert-dismissible`} role="alert">
            <div className="me-2" dangerouslySetInnerHTML={{ __html: svgs[icon] }}></div>
            {/* <div>{message}</div> */}
            <div className="ms-3">
                <h6 className="alert-heading m-0">{title}</h6>
                <p className="mb-0">
                    {description}
                    {
                        link && <Link to={link} className="btn btn-sm btn-primary-soft ms-3 mb-0">Learn more !</Link>
                    }
                </p>


            </div>
            {/* <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button> */}
        </div>
    )
}


export { AlertWithIcon, InfoAlert, CardNotification, LiteAlert }