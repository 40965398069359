import confetti from 'canvas-confetti';

function GetConfetti() {

	function Firework(duration = 3) {
		var duration = duration * 1000;
		var animationEnd = Date.now() + duration;
		var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

		function randomInRange(min, max) {
			return Math.random() * (max - min) + min;
		}

		var interval = setInterval(function () {
			var timeLeft = animationEnd - Date.now();

			if (timeLeft <= 0) {
				return clearInterval(interval);
			}

			var particleCount = 50 * (timeLeft / duration);
			// since particles fall down, start a bit higher than random
			confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } }));
			confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } }));
		}, 250);
	}

	function PartyPride(duration = 1) {
		var end = Date.now() + (duration * 1000);

		// go Buckeyes!
		(function frame() {
			// launch a few confetti from the left edge
			confetti({
			  particleCount: 7,
			  angle: 60,
			  spread: 55,
			  origin: { x: 0 }
			});
			// and launch a few from the right edge
			confetti({
			  particleCount: 7,
			  angle: 120,
			  spread: 55,
			  origin: { x: 1 }
			});
		  
			// keep going until we are out of time
			if (Date.now() < end) {
			  requestAnimationFrame(frame);
			}
		  }());
	}


	return { Firework, PartyPride }
}


export default GetConfetti;