import { useContext } from 'react';
import AuthContext, { FnType } from "./context/auth";
import { BrowserRouter } from 'react-router-dom';
import { useEffect } from 'react';

import PublicDashboardIndex from "./pages/Public/Dashboard/Index";
import PublicDashboardSettings from "./pages/Public/Settings/Settings";
import PublicStaticPages from "./pages/Public/Index/Routes";

import PublicLogin from './pages/Public/Index/Login';
import PublicSignup from './pages/Public/Index/Signup';
import PublicForgotPassword from './pages/Public/Index/ForgotPassword';

import AdminDashboardIndex from "./pages/Admin/Admin";

import Branding, { Settings } from "./Branding/Branding";
import { Routes, Route, Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import GetConfetti from './helper/Confetti';
import { typeWebsiteSettings } from "./api/static";

import websiteDate from "./json/settings.json";
import ReactGA from 'react-ga4';
import LoadingSVG from "./svg/idcard_logo.svg";

import { useSystemSettings } from './api/system';
import { Toaster } from 'react-hot-toast';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Helmet } from 'react-helmet-async';

// function createElementInHead(name, props) {
//     // make selector just with name or property what avaiable in props
//     let selector = name
//     if (props.name) {
//         selector += `[name='${props.name}']`;
//     } else if (props.property) {
//         selector += `[property='${props.property}']`;
//     } else if (props.rel) {
//         selector += `[rel='${props.rel}']`;
//     }

//     if (document.head.querySelector(selector)) {
//         return updateElementInHead(selector, props);
//     }
//     const element = Object.assign(document.createElement(name), props);
//     document.head.appendChild(element);
// }

// function updateElementInHead(selector, props) {
//     const element = document.head.querySelector(selector);
//     if (element) {
//         Object.assign(element, props);
//     }
// }

function MakeMetaTags({ description, title, branding }: { description: string | undefined, title: string, branding: Settings }) {
    return (
        <>
            <Helmet>
                <title>{title}</title>

                <meta name="title" content={title} />
                <meta name="description" content={description} />

                <meta property="og:description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:url" content={window.location.href} />
                <meta property='og:type' content='website' />
                <meta property="og:image" content="https://framerusercontent.com/images/eMHlm9ywFlKEdTqdex3em4POUV8.png" />

                <meta name="twitter:description" content={description} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:url" content={window.location.href} />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:image" content="https://framerusercontent.com/images/eMHlm9ywFlKEdTqdex3em4POUV8.png" />

                <link rel="canonical" href={`https://idcard.store${window.location.pathname}`} />

                {branding.noindex && <meta name="googlebot" content="noindex" />}
                {branding.noindex && <meta name="robots" content="noindex" />}
            </Helmet>
        </>
    )

}


function ChangeTitle({ settings, fn }: { settings: typeWebsiteSettings | undefined, fn: FnType }) {
    const { pathname } = useLocation();
    const branding = Branding();

    function makePathnameToTitle(pathname: string) {
        let title = pathname.replace("/", "");
        title = title.replace(/_/g, " ");
        title = title.replace(/-/g, " ");
        title = title.replace(/\//g, " ");
        // capital first leter after start

        title = title.split(" ").map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(" ");

        return title
    }

    function makeTitle() {
        let urlPath = window.location.pathname;

        if (urlPath.startsWith("/u/")) {
            urlPath = urlPath.replace("/u/", "");

            if (urlPath.startsWith("all_cards/")) {
                urlPath = urlPath.replace("all_cards/", "");
                return `All ${fn.fullStateName(urlPath)} Cards - ID Maker Store`
            }

            if (urlPath.startsWith("card/")) {
                urlPath = urlPath.replace("card/", "");
                return `${makePathnameToTitle(urlPath)} - ID Maker Store`
            }

            if (urlPath.startsWith("free/")) {
                urlPath = urlPath.replace("free/", "");
                return `Free ${makePathnameToTitle(urlPath)} - ID Maker Store`
            }

            if (urlPath.startsWith("settings")) {
                urlPath = urlPath.replace("settings", "");

                if (urlPath.startsWith("/")) {
                    urlPath = urlPath.replace("/", "");
                    return `Settings ${makePathnameToTitle(urlPath)} - ID Maker Store`
                }
                return `Settings - ID Maker Store`
            }

            if (urlPath.startsWith("index")) {
                return `Dashboard - ID Maker Store`
            }

            return `${makePathnameToTitle(urlPath)} - ID Maker Store`;
        }


        return `${branding.site_name} - Making ID's online`;
    }

    const current_path = window.location.pathname;
    const pageSettings = settings?.head[current_path];

    // Set description and title
    const description = pageSettings?.description || settings?.default.description
    const title = pageSettings?.title || makeTitle();


    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);


        // const host = window.location.host;

        // createElementInHead("link", { rel: "canonical", href: "https://idcard.store" + window.location.pathname });

        // // set description and title
        // updateElementInHead("meta[name='twitter:description']", { content: description });

        // updateElementInHead("meta[name='description']", { content: description });
        // updateElementInHead("meta[property='og:description']", { content: description });
        // updateElementInHead("meta[name='twitter:description']", { content: description });
        // updateElementInHead("meta[property='og:title']", { content: title });
        // updateElementInHead("meta[property='title']", { content: title });
        // updateElementInHead("meta[name='twitter:title']", { content: title });

        // // Set noindex if branding.noindex is true
        // if (branding.noindex) {
        //     createElementInHead("meta", { name: "googlebot", content: "noindex" });
        //     createElementInHead("meta", { name: "robots", content: "noindex" });
        // }

        // // Set og:url and twitter:url
        // updateElementInHead("meta[property='og:url']", { content: window.location.href });
        // updateElementInHead("meta[name='twitter:url']", { content: window.location.href });


        // ReactGA.set({
        //     location: `${window.location.origin}${window.location.pathname}${window.location.search}`,
        // });
        // ReactGA.pageview(window.location.pathname + window.location.search);

        let ga4_timeout = setTimeout(() => {
            ReactGA.send({ hitType: "pageview" });
        }, 1000);

        return () => {
            clearTimeout(ga4_timeout);
            clearTimeout(timer);
        }

    }, [pathname]);

    return (
        <MakeMetaTags description={description} title={title} branding={branding} />
    );
}

function SeoInit({ settings, fn }: { settings: typeWebsiteSettings | undefined, fn: FnType }) {
    const branding = Branding();

    function makePathnameToTitle(pathname: string) {
        let title = pathname.replace("/", "");
        title = title.replace(/_/g, " ");
        title = title.replace(/-/g, " ");
        title = title.replace(/\//g, " ");
        // capital first leter after start

        title = title.split(" ").map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(" ");

        return title
    }

    function makeTitle() {
        let urlPath = window.location.pathname;

        if (urlPath.startsWith("/u/")) {
            urlPath = urlPath.replace("/u/", "");

            if (urlPath.startsWith("all_cards/")) {
                urlPath = urlPath.replace("all_cards/", "");
                return `All ${fn.fullStateName(urlPath)} Cards - ID Maker Store`
            }

            if (urlPath.startsWith("card/")) {
                urlPath = urlPath.replace("card/", "");
                return `${makePathnameToTitle(urlPath)} - ID Maker Store`
            }

            if (urlPath.startsWith("free/")) {
                urlPath = urlPath.replace("free/", "");
                return `Free ${makePathnameToTitle(urlPath)} - ID Maker Store`
            }

            if (urlPath.startsWith("settings")) {
                urlPath = urlPath.replace("settings", "");

                if (urlPath.startsWith("/")) {
                    urlPath = urlPath.replace("/", "");
                    return `Settings ${makePathnameToTitle(urlPath)} - ID Maker Store`
                }
                return `Settings - ID Maker Store`
            }

            if (urlPath.startsWith("index")) {
                return `Dashboard - ID Maker Store`
            }

            return `${makePathnameToTitle(urlPath)} - ID Maker Store`;
        }


        return `${branding.site_name} - Making ID's online`;
    }

    const current_path = window.location.pathname;
    const pageSettings = settings?.head[current_path];

    // Set description and title
    const description = pageSettings?.description || settings?.default.description
    const title = pageSettings?.title || makeTitle();


    return (
        <MakeMetaTags description={description} title={title} branding={branding} />
    );
}

function BulkRedirect({ data }) {
    return data.redirects.map((redirect, index) => {
        return <Route key={index} path={redirect.from} element={<Navigate to={redirect.to} />} />
    })
}


function MainRouter() {
    const { isReady, fn, user } = useContext(AuthContext);
    const { Firework } = GetConfetti();
    const branding = Branding();
    const data = websiteDate as typeWebsiteSettings;
    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        const firework_timeout = setTimeout(() => {
            if (window.location.pathname.startsWith("/u/index") && window.innerWidth > 768) {
                Firework();
            }
        }, 1000);

        return () => {
            clearTimeout(firework_timeout);
        }
    }, [])


    const { data: systemSettings, isLoading } = useSystemSettings(Boolean(user));

    // console.log(import.meta.env, "ENV")

    if (!isReady || !executeRecaptcha) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh', background: "#fff" }}>
                {/* add gif */}
                <SeoInit settings={data} fn={fn} />
                <div className="gradient"></div>
                <img src={LoadingSVG} alt="loading" style={{ maxWidth: "390px", position: "absolute" }} />
            </div>
        )
    }

    if (systemSettings && !isLoading) {
        // console.log(systemSettings, "systemSettings")
        if (systemSettings.PUBLIC_MAINTENANCE && !import.meta.env.DEV) {
            return (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh', background: "#fff" }}>
                    <iframe src="https://temp15445.blr1.cdn.digitaloceanspaces.com/maintenance.html" style={{ width: "100%", height: "100%", border: "none" }}></iframe>
                </div>
            )
        }
    }


    if (user) {
        // console.log(String(user.id), "GA init")
        ReactGA.initialize('G-XTQ8ZYSED5', { gaOptions: { userId: String(user.id) } });
    } else {
        ReactGA.initialize('G-XTQ8ZYSED5');
    }





    const BulkRedirects = BulkRedirect({ data })

    return (
        <BrowserRouter>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <ChangeTitle settings={data} fn={fn} />

            <Routes>
                <Route path='/*' element={branding.index_page ? <PublicStaticPages /> : <Navigate to="/login" />} />
                <Route path='/login' element={<PublicLogin />} />
                <Route path='/signup' element={<PublicSignup />} />
                <Route path='/forgot_password' element={<PublicForgotPassword />} />

                <Route path='/u/*' element={<PublicDashboardIndex />} />
                <Route path='/a/*' element={<AdminDashboardIndex />} />
                <Route path='/u/settings/*' element={<PublicDashboardSettings />} />


                {/* navigate user from /u/page_maker to /u/card/page_maker */}
                {/* <Route path='/u/page_maker' element={<Navigate to="/u/card/page_maker" />} /> */}

                {BulkRedirects}

            </Routes>
        </BrowserRouter>

    );
}



export default MainRouter;