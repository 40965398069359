// Code Developed and Maintained by: Mayank Gupta

import { useCardlogs } from "../../../../api/basic";

import { useContext } from "react";
import AuthContext from "../../../../context/auth";

import { CardLogsTable } from "../../../../components/Table/table";



function PublicDashboardCardLogs() {
    const { isLogin } = useContext(AuthContext)

    const { data } = useCardlogs(isLogin);

    return (

        data && <CardLogsTable data={data} />

    );
}


export default PublicDashboardCardLogs;
