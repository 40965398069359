import { useMutation } from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import axiosClient from "../hooks/useAxios";
import { handleErrors } from "./handleErrors";
import { server4XXError } from "./types";
import CustomAlert from "../helper/Alerts";

export type typeUserProfilePayload = {
    full_name: string,
    full_address: string,
    gender: string,
    state: string,
    pincode: string,
    dob: string,
    account_type: string,
    phone: number,

    captcha: string
}

type typeSuccessResponse = {
    success: boolean,
}

const mutationUserProfile = async (payload: typeUserProfilePayload): Promise<typeSuccessResponse> => {
    const response = await axiosClient.post<typeUserProfilePayload, AxiosResponse<typeSuccessResponse>>("/profile/update", payload);
    return response.data;
}

const useMutationUserProfileUpdate = () => {
    return useMutation<typeSuccessResponse, AxiosError<server4XXError>, typeUserProfilePayload>({
        mutationKey: ["userProfileUpdate"],
        mutationFn: mutationUserProfile,
        onError: handleErrors,
        onSuccess: (data) => {
            if (data.success) {
                CustomAlert.success("Profile Updated", "Profile updated successfully");
            }
        }
    })
}


type typeUserTokenResponse = {
    token: string,
}

const mutationUserAuth = async (): Promise<typeUserTokenResponse> => {
    const response = await axiosClient.post<null, AxiosResponse<typeUserTokenResponse>>("/refresh_auth", null);
    return response.data;
}

const useMutationUserAuthUpdate = () => {
    return useMutation<typeUserTokenResponse, AxiosError<server4XXError>, null>({
        mutationKey: ["userAuthTokenUpdate"],
        mutationFn: mutationUserAuth,
        onError: handleErrors,
    })
}

export { useMutationUserProfileUpdate, useMutationUserAuthUpdate }