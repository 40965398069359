
import axiosClient from "../hooks/useAxios";
import { AxiosResponse, AxiosError } from "axios";

import { server4XXError, typePaymentStatusPayload, typeMessageResponse } from "./types";
import { useMutation } from "react-query";
import { handleErrors } from "./handleErrors";
import {alertPaymentSuccess} from "../helper/Alerts";
import CustomAlert from "../helper/Alerts";


export  type typePaytmPayPayload = {
    amount: number;
}

export type typePaytmPayResponse = {
    root: string;
    flow: string;
    merchant: {
        mid: string;
        name: string;
        logo: string;
        redirect: boolean;
    }; 
    data: {
        orderId: string;
        token: string;
        tokenType: string;
        amount: string;
    };
    userDetail: {
        mobileNumber: string;
        name: string;
    };
}


const mutationPaytmPay = async (payload: typePaytmPayPayload): Promise<typePaytmPayResponse>  => {
    const response = await axiosClient.post<typePaytmPayPayload, AxiosResponse<typePaytmPayResponse>>("/payment/paytm/pay", payload);
    return response.data;
}

const useMutationUserPaytmPay = () => {
    return useMutation<typePaytmPayResponse, AxiosError<server4XXError>, typePaytmPayPayload>({
        mutationKey: ["userPaytmPay"],
        mutationFn: mutationPaytmPay,
        onError: handleErrors
    })
}

export type typePaytmProcessPayload = {
    BANKNAME: string;
    BANKTXNID: string;
    CHECKSUMHASH: string;
    CURRENCY: string;
    GATEWAYNAME: string;
    MID: string;
    ORDERID: string;
    PAYMENTMODE: string;
    RESPCODE: string;
    RESPMSG: string;
    STATUS: string;
    TXNAMOUNT: string;
    TXNDATE: string;
    TXNID: string;
}

type typePaytmProcessResponse = {
    message: string;
}

const mutationPaytmProcess = async (payload: typePaytmProcessPayload): Promise<typePaytmProcessResponse>  => {
    const response = await axiosClient.post<typePaytmProcessPayload, AxiosResponse<typePaytmProcessResponse>>("/payment/paytm/process", payload);
    return response.data;
}

const useMutationUserPaytmProcess = () => {
    return useMutation<typePaytmProcessResponse, AxiosError<server4XXError>, typePaytmProcessPayload>({
        mutationKey: ["userPaytmProcess"],
        mutationFn: mutationPaytmProcess,
        onError: handleErrors,
        onSuccess: (data) => {
            if (data.message) {
                alertPaymentSuccess("Payment Successful", data.message);
            }
        }
    })
}


type typeAdminGetAllPendingPaymentsPaytmResponse = {
    merchanyOrderId: string,
    orderCreatedTime: string,
    orderCompletedTime: string,
    orderSearchType: string,
    orderSearchStatus: string,
    payMode: string,
    amount: string,
}

async function mutationGetAllPendingPaymentsPaytm(): Promise<typeAdminGetAllPendingPaymentsPaytmResponse> {
    const response = await axiosClient.post("/payment/paytm/pending_orders", {})
    return response.data;
}

function useMutationGetAllPendingPaymentsPaytm() {
    return useMutation<typeAdminGetAllPendingPaymentsPaytmResponse, AxiosError<server4XXError>, null>({
        mutationKey: ["adminGetAllPendingPaymentsPaytm"],
        mutationFn: mutationGetAllPendingPaymentsPaytm,
        onError: handleErrors
    })
}



async function mutationPaytmPaymentStatus(payload: typePaymentStatusPayload): Promise<typeMessageResponse> {
    const response = await axiosClient.post("/payment/paytm/order_status", payload)
    return response.data;
}

function useMutationPaytmPaymentStatus() {
    return useMutation<typeMessageResponse, AxiosError<server4XXError>, typePaymentStatusPayload>({
        mutationKey: ["adminPaytmPaymentStatus"],
        mutationFn: mutationPaytmPaymentStatus,
        onError: handleErrors,
        onSuccess: (data) => {
            CustomAlert.success(
                "Success",
                data.message
            )
        },
    })
}



export { useMutationUserPaytmPay, useMutationUserPaytmProcess, useMutationGetAllPendingPaymentsPaytm, useMutationPaytmPaymentStatus };