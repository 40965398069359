import axiosClient from "../hooks/useAxios";
import {AxiosError, AxiosResponse} from "axios";
import { useQuery } from "react-query";
import { typeAdminUserInit, typeAdminInit, typeUserTransaction, server4XXError, typeRefUserPayment, typeUser } from "./types";

import { useMutation } from "react-query";
import CustomAlert from "../helper/Alerts";
import { handleErrors } from "./handleErrors";

import { typePaymentStatusPayload, typeMessageResponse } from "./types";

const getAdminInit = async () => {
    const response = await axiosClient.post<any, AxiosResponse<typeAdminInit>>("/admin/init", {});
    return response.data;
}
const useAdminInit = (isLogin) => {
    return useQuery<typeAdminInit, AxiosError<server4XXError>>({
        queryKey: ["adminInit"],
        queryFn: getAdminInit,
        onError: handleErrors,
        enabled: isLogin,
        retry: false,
        refetchOnWindowFocus: false,
    });
};

type typeAdminUserInitPayload = {
    email?: string,
    user_id?: string,
}

const getAdminUserInit = async (payload: typeAdminUserInitPayload): Promise<typeAdminUserInit> => {
    const response = await axiosClient.post<typeAdminUserInitPayload, AxiosResponse<typeAdminUserInit>>("/admin/user_init", payload);
    return response.data;
}

const useMutationAdminGetUserInit = () => {
    return useMutation<typeAdminUserInit, AxiosError<server4XXError>, typeAdminUserInitPayload>({
        mutationKey: ["adminUserInit"],
        mutationFn: getAdminUserInit,
        onError: handleErrors
    })
}

type typeAdminTransactionsPayload = {
    transaction_id: string,
}

const getAdminTransaction = async (payload: typeAdminTransactionsPayload): Promise<typeUserTransaction> => {
    const response = await axiosClient.post<typeAdminTransactionsPayload, AxiosResponse<typeUserTransaction>>("/transaction", payload);
    return response.data;
}

const useMutationAdminGetTransaction = () => {
    return useMutation<typeUserTransaction, AxiosError<server4XXError>, typeAdminTransactionsPayload>({
        mutationKey: ["adminUserTransaction"],
        mutationFn: getAdminTransaction,
        onError: handleErrors
    })
}


type typeAdminGetAllTxnPayload = {
    user_id: string,
}

const adminGetAllTxn = async (payload: typeAdminGetAllTxnPayload): Promise<typeRefUserPayment[]> => {
    const response = await axiosClient.post<typeAdminGetAllTxnPayload, AxiosResponse<typeRefUserPayment[]>>("/admin/all_txns", payload);
    return response.data;
}

const useMutationAdminAllTxn = () => {
    return useMutation<typeRefUserPayment[], AxiosError<server4XXError>, typeAdminGetAllTxnPayload>({
        mutationKey: ["adminGetAllTxn"],
        mutationFn: adminGetAllTxn,
        onError: handleErrors
    })
}


export type typeAdminUserPayload = {
    email?: string,
    user_id?: string | null,
}

const getAdminUser = async (payload: typeAdminUserPayload): Promise<typeUser> => {
    const response = await axiosClient.post<typeAdminUserPayload, AxiosResponse<typeUser>>("/user", payload);
    return response.data;
}

const useMutationAdminGetUser = () => {
    return useMutation<typeUser, AxiosError<server4XXError>, typeAdminUserPayload>({
        mutationKey: "adminGetUser",
        mutationFn: getAdminUser,
        onError: handleErrors
    })
}


export type typeAdminUsersPayload = {
    phone?: string,
    ip?: string,
}

const getAdminUsers = async (payload: typeAdminUsersPayload): Promise<typeUser[]> => {
    const response = await axiosClient.post<typeAdminUsersPayload, AxiosResponse<typeUser[]>>("/users", payload);
    return response.data;
}

const useMutationAdminGetUsers = () => {
    return useMutation<typeUser[], AxiosError<server4XXError>, typeAdminUsersPayload>({
        mutationKey: "adminGetUsers",
        mutationFn: getAdminUsers,
        onError: handleErrors
    })
}

// ===========================================================================================

type typeAdminCardsAddRemPayload = {
    user_id: string,
    amount: string,
}

type typeSuccessResponse = {
    success: boolean,
}

const mutationAdminSendCards = async (payload: typeAdminCardsAddRemPayload): Promise<typeSuccessResponse>  => {
    const response = await axiosClient.post<typeAdminCardsAddRemPayload, AxiosResponse<typeSuccessResponse>>("/admin/transfer_balance", payload);
    return response.data;
}

const mutationAdminWithdrawalCards = async (payload: typeAdminCardsAddRemPayload): Promise<typeSuccessResponse> => {
    const response = await axiosClient.post<typeAdminCardsAddRemPayload, AxiosResponse<typeSuccessResponse>>("/admin/withdrawal_balance", payload);
    return response.data;
}

type typeAdminUserStatusPayload = {
    user_id: string,
    status: string,
}

const mutationAdminUserStatus = async (payload: typeAdminUserStatusPayload): Promise<typeSuccessResponse> => {
    const response = await axiosClient.post<typeAdminUserStatusPayload, AxiosResponse<typeSuccessResponse>>("/admin/user_status", payload);
    return response.data;
}

const useMutationAdminSendCards = () => {
    return useMutation<typeSuccessResponse, AxiosError<server4XXError>, typeAdminCardsAddRemPayload>({
        mutationKey: "adminSendCards",
        mutationFn: mutationAdminSendCards,
        onSuccess: (data, variable:typeAdminCardsAddRemPayload) => {
            if (data.success) {
                CustomAlert.success(
                    "Success",
                    `${variable.amount} Cards sent successfully to ${variable.user_id}.`
                )
            }
        },
        onError: handleErrors
    })
}

const useMutationAdminWithdrawalCards = () => {
    return useMutation<typeSuccessResponse, AxiosError<server4XXError>, typeAdminCardsAddRemPayload>({
        mutationKey: "adminWithdrawalCards",
        mutationFn: mutationAdminWithdrawalCards,
        onSuccess: (data, variable:typeAdminCardsAddRemPayload) => {
            if (data.success) {
                CustomAlert.success(
                    "Success",
                    `${variable.amount} Cards withdrawal successfully from ${variable.user_id}.`
                )
            }
        },
        onError: handleErrors
    })
}

const useMutationAdminUserStatus = () => {
    return useMutation<typeSuccessResponse, AxiosError<server4XXError>, typeAdminUserStatusPayload>({
        mutationKey: "adminUserStatus",
        mutationFn: mutationAdminUserStatus,
        onSuccess: (data, variable:typeAdminUserStatusPayload) => {
            if (data.success) {
                CustomAlert.success(
                    "Success",
                    `Status of user:${variable.user_id} updated successfully.`
                )
            }
        },
        onError: handleErrors
    })
}


async function mutationAdminGetAllUsers(): Promise<typeUser[]> {
    const response = await axiosClient.post("/admin/all_users", null)
    return response.data;

}

function useMutationAdminGetAllUsers() {
    return useMutation<typeUser[], AxiosError<server4XXError>, null>({
        mutationKey: ["adminGetAllUsers"],
        mutationFn: mutationAdminGetAllUsers,
        onError: handleErrors
    })
}

export type typeAdminGetAllPendingPaymentsResponse = {
    id: number,
    user_id: number,
    payment_amount: number,
    order_id: string,
    payment_gateway: string,
    check_count: number,
    created_at: string,
    updated_at: string,
}

async function mutationGetAllPendingPayments(): Promise<typeAdminGetAllPendingPaymentsResponse> {
    const response = await axiosClient.post("/payment/pending_payments", {})
    return response.data;
}

function useMutationGetAllPendingPayments() {
    return useMutation<typeAdminGetAllPendingPaymentsResponse, AxiosError<server4XXError>, null>({
        mutationKey: ["adminGetAllPendingPayments"],
        mutationFn: mutationGetAllPendingPayments,
        onError: handleErrors
    })
}

// admin payments manuall process
// admin check payment status
// all pending payments
// admin send free balance

type typeAdminSendBalancePayload = {
    user_id: string,
    amount: number,
    reason: string,
}

async function mutationAdminSendBalance(payload:typeAdminSendBalancePayload): Promise<typeSuccessResponse> {
    const response = await axiosClient.post("/admin/send_balance", payload)
    return response.data;
}

function useMutationAdminSendBalance() {
    return useMutation<typeSuccessResponse, AxiosError<server4XXError>, typeAdminSendBalancePayload>({
        mutationKey: ["adminSendBalance"],
        mutationFn: mutationAdminSendBalance,
        onError: handleErrors,
        onSuccess: (data) => {
            if (data.success) {
                CustomAlert.success(
                    "Success",
                    "Balance sent successfully."
                )
            }
        }
    })
}


async function mutationPaymentStatus(payload: typePaymentStatusPayload): Promise<typeMessageResponse> {
    const response = await axiosClient.post("/payment/payment_status", payload)
    return response.data;
}

function useMutationPaymentStatus() {
    return useMutation<typeMessageResponse, AxiosError<server4XXError>, typePaymentStatusPayload>({
        mutationKey: ["adminPaymentStatus"],
        mutationFn: mutationPaymentStatus,
        onError: handleErrors,
        onSuccess: (data) => {
            CustomAlert.success(
                "Success",
                data.message
            )
        },
    })
}


type typeAdminProcessPaymentPayload = {
    order_id: string,
    email: string,
}

async function mutationAdminProcessPayment(payload: typeAdminProcessPaymentPayload): Promise<typeMessageResponse> {
    const response = await axiosClient.post("/payment/paytm/admin_payment_process", payload)
    return response.data;
}

function useMutationAdminProcessPayment() {
    return useMutation<typeMessageResponse, AxiosError<server4XXError>, typeAdminProcessPaymentPayload>({
        mutationKey: ["adminProcessPayment"],
        mutationFn: mutationAdminProcessPayment,
        onError: handleErrors,
        onSuccess: (data) => {
            CustomAlert.success(
                "Success",
                data.message
            )
        },
    })
}

type typeAdminMakeGiftCardPayload = {
    amount: number,
    description: string,
}

type typeAdminMakeGiftCardResponse = {
    card_code: string,
}


async function mutationAdminMakeGiftCard(payload: typeAdminMakeGiftCardPayload): Promise<typeAdminMakeGiftCardResponse> {
    const response = await axiosClient.post("/admin/make_giftcard", payload)
    return response.data;
}

function useMutationAdminMakeGiftCard() {
    return useMutation<typeAdminMakeGiftCardResponse, AxiosError<server4XXError>, typeAdminMakeGiftCardPayload>({
        mutationKey: ["adminMakeGiftCard"],
        mutationFn: mutationAdminMakeGiftCard,
        onError: handleErrors,
        onSuccess: (data) => {
            CustomAlert.success(
                "Success",
                `Gift card created successfully. Card code: ${data.card_code}`
            )
        },
    })
}





export { useAdminInit, useMutationAdminGetUserInit, useMutationAdminGetTransaction, useMutationAdminAllTxn, useMutationAdminGetUser
    , useMutationAdminSendCards, useMutationAdminWithdrawalCards, useMutationAdminUserStatus, mutationAdminUserStatus, useMutationAdminGetAllUsers,

    useMutationAdminSendBalance, useMutationPaymentStatus, useMutationAdminProcessPayment, useMutationAdminGetUsers, useMutationGetAllPendingPayments,
    useMutationAdminMakeGiftCard
}
 