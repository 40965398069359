import axios, {AxiosError } from "axios";
import { useQuery } from "react-query";
import { handleErrors } from "./handleErrors";
import { server4XXError } from "./types";


export type typeNotification = {
    top_alert: {
        message: string;
        link?: string ;
    }
    pages: {
        [key: string]: {
            type: string;
            title: string;
            description: string;
            link?: string;
        }[]
    }
}

const userNotification = async (): Promise<typeNotification> => {
    let url = "https://news.mayankfawkes.workers.dev";
    if (import.meta.env.DEV) {
        url = "https://news.mayankfawkes.workers.dev/test";
    }
    const response = await axios.get<typeNotification>(url, {});
    return response.data;
};

const useUserNotification = () => {
    return useQuery<typeNotification, AxiosError<server4XXError>>({
        queryKey: ["userWebsiteNotification"],
        queryFn: userNotification,
        onError: handleErrors,
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5 // 5 minutes
    });
};


export { useUserNotification }