import { useEffect, useRef, useState } from "react";
import { download_img, download_canvas, download_canvas_pdf } from "../../../../helper/Downloader";

type PublicDashboardIframeProps = {
    srcs: { a4: string }[],
    filename?: string,
    title?: string,
    description?: string,
    a4_common?: string
};

function PublicDashboardIframe({
    srcs, filename = "somefile.pdf",
    title = "A4 size PDF.", description = "This is A4 size page. This page might not work for PVC card.",
    a4_common = "" }: PublicDashboardIframeProps) {

    const defaultFile = { a4: import.meta.env.VITE_CDN_URL + "idmaker/files/placeholder.pdf" };
    const [current, setCurrent] = useState(a4_common? {"a4": a4_common} : srcs.length > 0 ? srcs[0] : defaultFile);

    console.log(a4_common? {"a4": a4_common} : srcs.length > 0 ? srcs[0] : defaultFile)

    useEffect(() => {
        setCurrent(a4_common? {"a4": a4_common} : srcs.length > 0 ? srcs[0] : defaultFile);
    }, [srcs]);

    const handleDownload = () => {
        download_img(current.a4, filename);
    };

    const handleSetCurrent = (src: { a4: string }) => {
        setCurrent(src);
    };

    return (
        <div className="col">
            <div className="card border">
                <div className="card-header border-bottom">
                    <h5 className="card-header-title">{title}</h5>
                    <p className="mb-0 small">{description}</p>

                    <div className="d-flex gap-2">
                        {srcs.map((src, index) => (
                            <button key={index} className="btn btn-outline-primary btn-sm mt-3"
                                type="button" onClick={() => handleSetCurrent(src)}>
                                <span role="status">File {index + 1}</span>
                            </button>
                        ))}
                        {a4_common && (
                            <button className="btn btn-outline-primary btn-sm mt-3"
                                type="button" onClick={() => handleSetCurrent({ a4: a4_common })}>
                                <span role="status">Common A4</span>
                            </button>
                        )}
                    </div>
                </div>

                <div className="card-body ratio ratio-16x9">
                    <iframe src={current.a4} title={title}></iframe>
                </div>

                <div className="card-footer border-top">
                    <div className="text-center mt-3">
                        <button className="btn btn-primary fs-5 iframe-download-btn"
                            type="button" onClick={handleDownload} >
                            <span className="fa fa-download me-2" aria-hidden="true"></span>
                            <span role="status">Download</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

type publicDashboardIframeImageProps = {
    src?: string,
    filename?: string,
    title?: string,
    description?: string,
    onClickFn?: any
}

function PublicDashboardIframeImage(props: publicDashboardIframeImageProps) {
    const { src = import.meta.env.VITE_CDN_URL + "idmaker/files/card_front.png",
        filename = "somefile.png", title = "A4 size Image.",
        description = "This is A4 size Image.", onClickFn } = props;

    const download = () => {
        download_img(src, filename);
    }

    return (
        <div className="col">
            <div className="card border">
                <div className="card-header border-bottom">
                    <h5 className="card-header-title">{title}</h5>
                    <p className="mb-0 small"> {description} </p>
                </div>

                <div className="card-body">
                    <img src={src} crossOrigin="anonymous" />
                </div>


                <div className="card-footer border-top">
                    <div className="text-center mt-3">
                        <button className="btn btn-primary fs-5 iframe-download-btn" type="button" onClick={onClickFn || download}>
                            <span className="fa fa-download me-2" aria-hidden="true"></span>
                            <span role="status">Download</span>
                        </button>
                    </div>
                </div>


            </div>
        </div>
    );
}


type publicDashboardIframeHtmlProps = {
    src?: string,
    title?: string,
    description?: string
}

function PublicDashboardIframeHtml(props: publicDashboardIframeHtmlProps) {
    const { src = import.meta.env.VITE_CDN_URL + "idmaker/files/placeholder.pdf",
        title = "HTML Page Preview", description = "Easily print this page with print button." } = props;

    const refIframe = useRef() as React.MutableRefObject<HTMLIFrameElement>;

    const doPrint = () => {
        // refIframe.current.contentWindow?.print();
        refIframe.current.contentWindow?.postMessage('print', '*')
    }

    return (
        <div className="col">
            <div className="card border">
                <div className="card-header border-bottom">
                    <h5 className="card-header-title">{title}</h5>
                    <p className="mb-0 small"> {description} </p>
                </div>

                <div className="card-body ratio ratio-16x9 bg-white">

                    <iframe src={src} ref={refIframe}></iframe>

                </div>


                <div className="card-footer border-top">
                    <div className="text-center mt-3">
                        <button className="btn btn-primary fs-5 iframe-download-btn" type="button" onClick={doPrint}>
                            <span role="status">Print</span>
                        </button>
                    </div>
                </div>


            </div>
        </div>
    );
}


type publicDashboardIframeCanvasProps = {
    filename?: string,
    filename_pdf?: string,
    title?: string,
    description?: string,
    beforeDownloadFn?: any,
    innerRef?: any
}

function PublicDashboardIframeCanvas(props: publicDashboardIframeCanvasProps) {
    const { filename = "somefile.png", filename_pdf = "somefile.pdf", title = "A4 size Image.",
        description = "This is A4 size Image.", beforeDownloadFn, innerRef } = props;

    async function check(): Promise<boolean> {
        if (!beforeDownloadFn) {
            return true;
        }
        if (beforeDownloadFn.constructor.name === "AsyncFunction") {
            let result = await beforeDownloadFn();
            return result;
        } else {
            return beforeDownloadFn();
        }
    }
    async function download_img() {
        const resp = await check();
        if (resp) {
            download_canvas(innerRef, filename);
        }

    }

    async function download_pdf() {
        if (!filename_pdf) {
            return;
        }
        const resp = await check();
        if (resp) {
            download_canvas_pdf(innerRef, filename_pdf);
        }
    }

    useEffect(() => {
        // disable right click 
        innerRef.current.addEventListener("contextmenu", (e) => {
            e.preventDefault();
        })
    }, [])


    return (
        <div className="col">
            <div className="card border">
                <div className="card-header border-bottom">
                    <h5 className="card-header-title">{title}</h5>
                    <p className="mb-0 small"> {description} </p>
                </div>

                <div className="card-body">
                    <canvas ref={innerRef} style={{ background: "white", width: "100%" }}></canvas>
                </div>


                <div className="card-footer border-top">
                    <div className="text-center mt-3">
                        <button className="btn btn-primary fs-5 iframe-download-btn me-3" type="button" onClick={download_img}>
                            <span className="fa fa-download me-2" aria-hidden="true"></span>
                            <span role="status"> Image</span>
                        </button>
                        <button className="btn btn-primary fs-5 iframe-download-btn ms-3" type="button" onClick={download_pdf}>
                            <span className="fa fa-download me-2" aria-hidden="true"></span>
                            <span role="status"> pdf</span>
                        </button>
                    </div>
                </div>



            </div>
        </div>
    );
}

type publicDashboardPvcCardDisplayProps = {
    srcs: { front: string, back: string }[]
}

const PublicDashboardPvcCardDisplay = ({ srcs }: publicDashboardPvcCardDisplayProps) => {
    // Set the default card URLs
    const defaultCard = {
        front: import.meta.env.VITE_CDN_URL + "idmaker/files/card_front.png",
        back: import.meta.env.VITE_CDN_URL + "idmaker/files/card_back.png"
    };

    // Use a state to store the current card
    const [currentCard, setCurrentCard] = useState(srcs.length > 0 ? srcs[0] : defaultCard);

    useEffect(() => {
        if (srcs.length > 0) {
            setCurrentCard(srcs[0]);
        } else {
            setCurrentCard(defaultCard);
        }
    }, [srcs]); // Only trigger when srcs changes

    function betterLoad(srcs: { front: string, back: string }) {
        var front_img = new Image();
        var back_img = new Image();

        setCurrentCard({
            front: import.meta.env.VITE_CDN_URL + "idmaker/images/card_loading.gif",
            back: import.meta.env.VITE_CDN_URL + "idmaker/images/card_loading.gif"
        });

        front_img.src = srcs.front;
        back_img.src = srcs.back;

        front_img.onload = function () {
            setCurrentCard((prev) => {
                return { ...prev, front: srcs.front }
            });
        }
        back_img.onload = function () {
            setCurrentCard((prev) => {
                return { ...prev, back: srcs.back }
            });
        }
    };

    const download = () => {
        let urlElement: Element | null = document.querySelector(".carousel-item.active img");
        let filenameElement: Element | null = document.querySelector(".carousel-item.active img");

        if (urlElement && filenameElement) {
            let url: string | null = urlElement.getAttribute("src");
            let filename: string | null = filenameElement.getAttribute("filename");

            if (url && filename) {
                download_img(url, filename);
            }
        }
    }


    return (
        <div className="col-md-6">
            <div className="card border">
                <div className="card-header border-bottom">
                    <h5 className="card-header-title">Front and Back</h5>
                    <p className="mb-0 small">Please click on the &lt; and &gt;  to slide the images.</p>

                    <div className="d-flex gap-2">
                        {
                            srcs.map((src, index) => {
                                return (
                                    <button key={index} className="btn btn-outline-primary btn-sm mt-3" type="button" onClick={() => betterLoad(src)}>
                                        <span role="status">Card {index + 1}</span>
                                    </button>
                                )
                            })
                        }
                    </div>

                </div>

                <div className="card-body p-0">

                    <div id="carouselExample" className="carousel slide">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                {/* @ts-ignore */}
                                <img filename="front.png" src={currentCard.front} className="d-block w-100" alt="..." />
                            </div>
                            <div className="carousel-item">
                                {/* @ts-ignore */}
                                <img filename="back.png" src={currentCard.back} className="d-block w-100" alt="..." />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                            <span className="" aria-hidden="true" > <i className="fa-regular fa-circle-left fa-beat fa-2xl" style={{ color: "#1d3359" }}></i> </span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                            <span className="" aria-hidden="true" > <i className="fa-regular fa-circle-right fa-beat fa-2xl" style={{ color: "#1d3359" }}></i> </span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                </div>


                <div className="card-footer border-top">
                    <div className="text-center mt-3">
                        <button className="btn btn-primary fs-5 download-btn" type="button" onClick={download}>
                            <span className="fa fa-download me-2" aria-hidden="true"></span>
                            <span role="status">Download</span>
                        </button>
                    </div>
                </div>


            </div>

        </div>
    )
}

// ===========================================================================================================

type publicDashboardCardPageInputFileProps = {
    name: string,
    accept: string,
    required: boolean,
    label: string
}

function PublicDashboardCardPageInputFile(props: publicDashboardCardPageInputFileProps) {
    const { name, accept, required, label } = props;

    return (
        <div className="input-group mb-3">
            <label className="input-group-text d-none d-sm-block text-capitalize" htmlFor="inputGroupFile01">Select {label || 'File'} {required && <span className="text-danger">*</span>}</label>
            <input type="file" name={name} className="form-control" id="inputGroupFile01" accept={accept} required={required} />
        </div>
    )
}

type publicDashboardCardPageInputTextProps = {
    name: string,
    placeholder: string,
    required: boolean,
    label: string
}

function PublicDashboardCardPageInputText(props: publicDashboardCardPageInputTextProps) {
    const { name, placeholder, required, label } = props;

    return (
        <div className="mb-3">
            <label className="form-label">{label} {required && <span className="text-danger">*</span>} </label>
            <input type="text" name={name} className="form-control" placeholder={placeholder} required={required} />
        </div>
    )
}

type publicDashboardCardPageSelectProps = {
    name: string,
    required: boolean,
    label: string,
    options: { key: string, value: string }[],
    value?: string,
    extraClass?: string,
    visible?: boolean
}

function PublicDashboardCardPageSelect(props: publicDashboardCardPageSelectProps) {
    const { name, required, label, options, value, extraClass = "", visible = true } = props;

    return (
        <div className={"mb-3 " + extraClass} style={{ display: !visible ? 'None' : "" }}>
            <label className="form-label">{label} {required && <span className="text-danger">*</span>} </label>
            <select className="form-select" name={name} required={required} defaultValue={value} >
                {options.map((option, index) => {
                    // console.log(option)
                    return (
                        <option key={index} value={option.value}>{option.key}</option>
                    )
                })}
            </select>
        </div>
    )
}

export {
    PublicDashboardIframe, PublicDashboardPvcCardDisplay,
    PublicDashboardCardPageInputFile, PublicDashboardCardPageInputText,
    PublicDashboardCardPageSelect, PublicDashboardIframeImage, PublicDashboardIframeCanvas,
    PublicDashboardIframeHtml
}