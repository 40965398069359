import { QueryClient } from 'react-query'

// import { handleErrors } from "../api/handleErrors";

const queryClient = new QueryClient({
    // defaultOptions: {
    //     mutations: {
    //         onError: handleErrors,
    //     },
    //     queries: {
    //         onError: handleErrors,
    //     },
    // }
})

export default queryClient;