import { Link } from "react-router-dom";

function PublicTerms() {
    return (
        <section className="pt-4 pt-lg-5">
            <div className="container">
                {/* Title */}
                <div className="row">
                    <div className="col-12 text-center mb-4">
                        <h6>AGREEMENT</h6>
                        <h1 className="fs-2 mb-0">Terms and Conditions of Use</h1>
                        <p>Last updated: March 20, 2024</p>
                    </div>
                </div>

                {/* Content START */}
                <div className="row">
                    <div className="col-md-10 mx-auto">
                        <div className="vstack gap-3">

                            <h5>1. Disclaimer</h5>
                            <p>All materials, information, and services provided on this Website <a className="text-muted text-primary-hover" href="https://idcard.store">https://idcard.store</a> are provided "as is" without any warranties, whether expressed or implied. ID Maker by MayankFawkes makes no representations or warranties concerning the accuracy or reliability of the use of the materials on its Website or relating to such materials or any sites linked to this Website.</p>
                            <p>Some of the The identification cards ("ID cards") generated by ID Maker by MayankFawkes are unofficial and not valid for official use. Misuse of our services may result in the suspension of your account.</p>
                            <p>Please note that ID Maker by MayankFawkes is not authorized or affiliated with any government entity, and none of the ID cards generated by ID Maker by MayankFawkes are officially valid or subject to government verification.</p>
                            <p>With word "website" we mean <a className="text-muted text-primary-hover" href="https://idcard.store">https://idcard.store</a> and all its subdomains.</p>

                            <h5>2. User Responsibilities</h5>
                            <p>2.1. You agree not to misuse our services or engage in any illegal or unauthorized activity on our Website.</p>
                            <p>2.2. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer. You agree to accept responsibility for all activities that occur under your account or password.</p>
                            <p>2.3. Users must acknowledge that they are solely responsible for the accuracy of the information provided and any consequences arising from the creation or use of the card.</p>

                            <h5>3. Intellectual Property</h5>
                            <p>3.1. The content, features, and functionality of the Website, including text, graphics, logos, images, and software, are the property of ID Maker by MayankFawkes and are protected by international copyright, trademark, and other intellectual property laws.</p>
                            <p>3.2. You may not use, reproduce, distribute, or create derivative works based on our content without explicit written permission from us.</p>

                            <h5>4. Legal Consent</h5>
                            <p>By using our services, you consent to abide by all applicable laws and regulations regarding the creation and use of the card, including but not limited to laws related to forgery, misrepresentation, and intellectual property rights.</p>

                            <h5>5. Indemnification</h5>
                            <p>You agree to indemnify and hold harmless ID Maker by MayankFawkes from any claims, damages, or liabilities arising from your use of the service, including claims related to the creation or distribution of the card.</p>

                            <h5>6. Legal Disclaimers</h5>
                            <p>These terms include legal disclaimers specifying the informational nature of some card, its non-governmental status, and its intended use for personal or informational purposes only. The card does not confer any legal status or rights.</p>

                            <h5>7. Authenticity Validation</h5>
                            <p>It is the sole responsibility of the user to validate the authenticity of the ID card PDF Files being used with ID Maker. Only files containing valid digital signatures should be considered authentic.</p>

                            <h5>8. Prohibited Uses</h5>
                            <p>8.1. You agree not to use the service for any unlawful purposes or to violate the rights of others.</p>
                            <p>8.2. You agree not to upload or transmit any content that is infringing, libelous, defamatory, obscene, pornographic, abusive, or offensive.</p>

                            <h5>9. Limitation of Liability</h5>
                            <p>We shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, arising from your use of the service.</p>

                            <h5>10. Modifications to Service</h5>
                            <p>We reserve the right to modify or discontinue, temporarily or permanently, the service (or any part thereof) with or without notice. You agree that we shall not be liable to you or to any third party for any modification, suspension, or discontinuance of the service.</p>

                            <h5>11. Termination</h5>
                            <p>We reserve the right to suspend or terminate your access to the service at any time, with or without cause, and with or without notice.</p>

                            <h5>12. Links to Third-Party Websites</h5>
                            <p>12.1. The Website may contain links to third-party websites that are not owned or controlled by ID Maker by MayankFawkes. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites.</p>
                            <p>12.2. We do not endorse or make any representations about third-party websites. You acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any third-party websites.</p>

                            <h5>13. Site Terms of Use Modifications</h5>
                            <p>ID Maker by MayankFawkes may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.</p>
                            
                            <h5>14. Governing Law and Jurisdiction</h5>
                            <p>16.1. These Terms are governed by and construed in accordance with the laws of India, and you irrevocably submit to the exclusive jurisdiction of the courts in India.</p>
                            <p>16.2. Any dispute arising out of or relating to these Terms or your use of the Website shall be subject to the exclusive jurisdiction of the courts located within India.</p>

                            <h5>15. Your Privacy</h5>
                            <p>Please read our Privacy Policy at <Link to="/privacy" className="text-muted text-primary-hover">https://idcard.store/privacy</Link>.</p>

                            <h5>16. Your Return, Refund, and Cancellation Policy</h5>
                            <p>Please read our Return, Refund, and Cancellation Policy at <Link to="/refund" className="text-muted text-primary-hover">https://idcard.store/refund</Link> for information about our return, refund, and cancellation policies.</p>

                            <h5>17. How We Make Cards and Non-Government Status</h5>
                            <p>For detailed information on how we make cards and their non-government status, please visit our dedicated page: <Link to="/how-we-make-cards" className="text-muted text-primary-hover">https://idcard.store/how-we-make-cards</Link>.</p>

                            <h5>18. Contact Information</h5>
                            <p>ID Maker by MayankFawkes is managed by Mayank Gupta. If you have any questions about these Terms, please contact us at <Link to="mailto:support@idcard.store" className="text-muted text-primary-hover">support@idcard.store</Link>.</p>

                        </div>

                    </div>
                </div>
                {/* Content END */}
            </div>
        </section>
    );
}



export default PublicTerms;