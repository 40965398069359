import { useContext } from "react";
import AuthContext from "../../../../context/auth";
import { useState } from "react";
import Loader from "../../../../components/Loader/Loader";
import { FormatDate } from '../../../../helper/Time';

import { useMutationUserSessionDrop, useUserSession } from "../../../../api/session";

import { useMutationUpdatePasswordDirect } from "../../../../api/login";

import queryClient from "../../../../context/query";
import CustomAlert from '../../../../helper/Alerts';

import GoogleCaptchaV3 from "../../../../components/Captcha/google";


import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';



function PublicDashboardSettingsSecurity() {
    const [showPassword, setShowPassword] = useState(true);
    const { token } = useContext(AuthContext);


    type ChangePasswordPayload = {
        current_password: string,
        password: string,
        confirm_password: string,
    }

    const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()]).{8,}$/;

    const schema = yup.object().shape({
        current_password: yup.string().required("Current Password is required").max(32, "Password is too long").min(8, "Password is too short"),
        password: yup.string().required("Password is required").matches(passwordRules, "Password must be at least 8 characters long and contain at least one number and one special character and one uppercase letter").max(32, "Password is too long").min(8, "Password is too short"),
        confirm_password: yup.string().required("Confirm Password is required").oneOf([yup.ref('password')], 'Passwords must match')
    });

    const { register, handleSubmit, reset, formState: { errors, dirtyFields } } = useForm<ChangePasswordPayload>(
        { resolver: yupResolver(schema), mode: 'all' }
    )


    const { mutateAsync, isLoading } = useMutationUserSessionDrop();
    const userSessions = useUserSession();

    const changePassword = useMutationUpdatePasswordDirect();

    const captcha = GoogleCaptchaV3();

    if (userSessions.isLoading || !userSessions.data) {
        return <Loader middle={true} />
    }

    function dropSession(session_id: string) {
        return async () => {
            await mutateAsync({ session_id })
            queryClient.invalidateQueries("userSessions");
        }
    }

    async function onSubmit(data: ChangePasswordPayload) {
        const answer = await captcha.sureChanllenge("update_password");
        if (!answer) return CustomAlert.error("Error", "Please complete the captcha challenge.");

        await changePassword.mutateAsync({
            ...data,
            captcha: answer
        });

        reset();
    }


    return (
        <>
            <div className="card  ">
                {/* <!-- Card header --> */}
                <div className="card-header border-bottom">
                    <h4 className="card-header-title">Security settings</h4>
                </div>

                {/* <!-- Card body START --> */}
                <div className="card-body">
                    {/* <!-- Two factor --> */}
                    <form className="mb-3">
                        <h6>Your Account Authorization Key</h6>
                        <label className="form-label">Please do not share this token with anyone and if you think someone has access to your account, please change your token here and chnage your gmail account password.</label>
                        <div className="mb-3">
                            <label className="form-label">Current Auth Key</label>
                            <div className="input-group">
                                <input className="form-control fakepassword" type={showPassword ? "password" : "text"} id="psw-input" defaultValue={token} />
                                <span style={{ cursor: "pointer" }} className="input-group-text p-0 bg-transparent" onClick={() => setShowPassword(!showPassword)}>
                                    <i className={showPassword ? "fas fa-eye-slash p-2" : "fas fa-eye p-2"}></i>
                                </span>
                            </div>
                        </div>
                        {/* <button className="btn btn-primary mt-3" type="button" onClick={updateAuth}>
                            <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{ display: !isLoading ? 'None' : "" }}></span>
                            Change Account Auth Key
                        </button> */}
                    </form>


                </div>
                {/* <!-- Card body END --> */}
            </div>
            <div className="card shadow mt-4">
                {/* <!-- Card header --> */}
                <div className="card-header border-bottom">
                    <h4 className="card-header-title">Active Sessions</h4>
                </div>

                {/* <!-- Card body START --> */}
                <div className="card-body">
                    {/* <!-- Table START --> */}
                    <div className="table-responsive border-0">
                        <table className="table align-middle p-4 mb-0 table-hover">

                            {/* <!-- Table head --> */}
                            <thead className="table-dark">
                                <tr>
                                    <th scope="col" className="border-0 rounded-start">Browser</th>
                                    <th scope="col" className="border-0">IP</th>
                                    <th scope="col" className="border-0">Last Active</th>
                                    <th scope="col" className="border-0 rounded-end">Action</th>
                                </tr>
                            </thead>

                            {/* <!-- Table body START --> */}
                            <tbody>

                                {userSessions.data.map((session, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{session.useragent}</td>
                                            <td>{session.ip_address}</td>
                                            <td>{FormatDate(session.updated_at)}</td>
                                            <td>
                                                <button className="btn btn-sm btn-danger-soft me-1 mb-1 mb-md-0" type="button" onClick={dropSession(session.id)} disabled={isLoading}>
                                                    <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{ display: !isLoading ? 'None' : "" }}></span>
                                                    Sign out
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}




                            </tbody>
                            {/* <!-- Table body END --> */}
                        </table>
                    </div>
                    {/* <!-- Table END --> */}
                </div>
                {/* <!-- Card body END --> */}
            </div>
            <div className="card border mt-4">
                <div className="card-header border-bottom">
                    <h4 className="card-header-title">Update Password</h4>
                </div>
                {/* <!-- Card body START --> */}


                <Form className="card-body" onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3" controlId="current_password">
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control type="text" placeholder="Current Password" {...register("current_password")} required isInvalid={!!errors.current_password} isValid={dirtyFields.current_password && !errors.current_password} />
                        <Form.Control.Feedback type="invalid">
                            {errors.current_password?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="password">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control type="text" placeholder="New Password" {...register("password")} required isInvalid={!!errors.password} isValid={dirtyFields.password && !errors.password} />
                        <Form.Control.Feedback type="invalid">
                            {errors.password?.message}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>
                            <span className="bi bi-check-circle-fill"></span> Strong Password!
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="confirm_password">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="text" placeholder="Confirm Password" {...register("confirm_password")} required isInvalid={!!errors.confirm_password} isValid={dirtyFields.confirm_password && !errors.confirm_password} />
                        <Form.Control.Feedback type="invalid">
                            {errors.confirm_password?.message}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>
                            Passwords match!
                        </Form.Control.Feedback>
                    </Form.Group>

                    <div>
                        <button type="submit" className="btn btn-primary w-100 mb-0" disabled={changePassword.isLoading || captcha.isLoading}>
                            <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"
                                style={{ display: (changePassword.isLoading || captcha.isLoading) ? 'inline-block' : 'none' }}></span>
                            Change Password
                        </button>
                    </div>
                
                </Form>


                {/* <!-- Card body END --> */}
            </div>

        </>
    )
}


export default PublicDashboardSettingsSecurity;