import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { useContext } from "react";
import AuthContext from "../context/auth";
import { useUser } from "../api/basic";

import { useMutationSendWebhook } from "../api/webhook";
import { getRandomColor } from "./Color";

import { useMutationUpdatePasswordForced } from "../api/login";
import GoogleCaptchaV3 from "../components/Captcha/google";

import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';


function SignUpModal({ show }) {

    return (
        <>
            {/* <!-- Modal --> */}
            <Modal show={show} centered>
                {/* <!-- Header --> */}
                <Modal.Header className='border-0' />
                {/* <!-- End Header --> */}

                {/* <!-- Body --> */}
                <Modal.Body>
                    {/* <!-- Sign up --> */}
                    <div id="signupModalFormSignup">
                        {/* <!-- Heading --> */}
                        <div className="text-center mb-7">
                            <h2>Join Us</h2>
                            <p>Already have an account? Same for login
                            </p>
                        </div>
                        {/* <!-- End Heading --> */}

                        <div className="d-grid gap-3">
                            <Link className="btn btn-white btn-lg mx-4 mx-md-5" to={"/login?redirect=" + window.location.pathname}>
                                <span className="d-flex justify-content-center align-items-center">
                                    <img className="avatar avatar-xs me-2" src="/static/assets/images/element/google-icon.svg" alt="Image Description" />
                                    Join in with Google
                                </span>
                            </Link>

                            <div className="text-center">
                                <p className="small mb-0">By continuing you agree to our <Link to="/terms">Terms and Conditions</Link></p>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Sign up --> */}
                </Modal.Body>
                {/* <!-- End Body --> */}

                {/* <!-- Footer --> */}

                <Modal.Footer className="d-block text-center py-sm-5 mx-3">
                    <h6 className="mb-0">Trusted by more than 2,00,000 customers.</h6>
                    <p className="text-center text-body-tertiary text-opacity-25 mb-4 mb-md-5 ">below image referring the customer types.</p>

                    <div className="w-85 mt-4 mx-auto">
                        <div className="row justify-content-between align-items-center">
                            <div className="col">
                                <img className="img-fluid" src="/static/assets/images/element/cybercafe.png" alt="Logo" />
                            </div>
                            {/* <!-- End Col --> */}

                            <div className="col">
                                <img className="img-fluid" src="/static/assets/images/element/csc.webp" alt="Logo" />
                            </div>
                            {/* <!-- End Col --> */}

                            <div className="col">
                                <img className="img-fluid" src="/static/assets/images/element/janseva.png" alt="Logo" />
                            </div>
                            {/* <!-- End Col --> */}

                            <div className="col">
                                <img className="img-fluid" src="/static/assets/images/element/mponline.webp" alt="Logo" />
                            </div>
                            {/* <!-- End Col --> */}
                        </div>
                    </div>
                    {/* <!-- End Row --> */}
                </Modal.Footer>
                {/* <!-- End Footer --> */}
            </Modal>
            {/* <!-- End Modal --> */}

        </>
    )
}

function UpdateProfileModal({ show, onClose }) {
    return (
        <Modal show={show} onHide={onClose} centered>

            {/* <!-- Header --> */}
            <Modal.Header closeButton className='border-0' />
            {/* <!-- End Header --> */}

            {/* <!-- Body --> */}
            <Modal.Body>
                {/* <!-- Heading --> */}
                <div className="text-center mb-7">
                    <h2>Update Profile</h2>
                    <p>You can only use services after updating your profile.</p>
                </div>
                {/* <!-- End Heading --> */}

                <div className="d-grid gap-3">
                    <Link className={"btn btn-white btn-lg mx-4 mx-md-5"} to={"/u/settings"}>
                        <span className="d-flex justify-content-center align-items-center">
                            Go to profile edit <i className="ms-2 fa-solid fa-arrow-right-long fa-bounce fa-2xl"></i>
                        </span>
                    </Link>

                    <div className="text-center">
                        <p className="small mb-0">By continuing you agree to our <Link to="/terms">Terms and Conditions</Link></p>
                    </div>
                </div>
            </Modal.Body>
            {/* <!-- End Body --> */}

            {/* <!-- Footer --> */}
            <div className="modal-footer d-block text-center py-sm-5 mx-3">
                <h6 className="mb-0">Trusted by more than 2,00,000 customers.</h6>
                <p className="text-center text-body-tertiary text-opacity-25 mb-4 mb-md-5 ">below image referring the customer types.</p>

                <div className="w-85 mt-4 mx-auto">
                    <div className="row justify-content-between align-items-center">
                        <div className="col">
                            <img className="img-fluid" src="/static/assets/images/element/cybercafe.png" alt="Logo" />
                        </div>
                        {/* <!-- End Col --> */}

                        <div className="col">
                            <img className="img-fluid" src="/static/assets/images/element/csc.webp" alt="Logo" />
                        </div>
                        {/* <!-- End Col --> */}

                        <div className="col">
                            <img className="img-fluid" src="/static/assets/images/element/janseva.png" alt="Logo" />
                        </div>
                        {/* <!-- End Col --> */}

                        <div className="col">
                            <img className="img-fluid" src="/static/assets/images/element/mponline.webp" alt="Logo" />
                        </div>
                        {/* <!-- End Col --> */}
                    </div>
                </div>
                {/* <!-- End Row --> */}
            </div>
            {/* <!-- End Footer --> */}
        </Modal>
    )
}

// user agreement modal
const ConfirmModal = ({ show, onClose, onAccept, onDecline }) => {

    function _handleAccept() {
        onAccept();
        onClose();
    }

    return (
        <>


            <Modal show={show} onHide={onClose} size="lg" centered>

                {/* <!-- Header --> */}
                <Modal.Header closeButton className='border-0' />
                {/* <!-- End Header --> */}

                {/* <!-- Body --> */}
                <Modal.Body>


                    {/* <!-- Heading --> */}
                    <div className="text-center mb-3">
                        <h3>User Agreement</h3>
                        <p>Please read the following terms carefully before using our service:</p>
                    </div>
                    {/* <!-- End Heading --> */}

                    <div className="container mt-3 d-grid gap-3" style={{fontSize: '0.9rem'}}>

                        <ul>
                            <li>
                                <strong>Ownership Confirmation:</strong> Users must confirm that they are creating the card for themselves or that they
                                have explicit permission from the individual on whose behalf the card is being created.
                            </li><div className="3"></div>
                            <li>
                                <strong>Responsibility Acknowledgment:</strong> Users must acknowledge that they are solely responsible for the accuracy of the
                                information provided and any consequences arising from the creation or use of the card.
                            </li>
                            <li>
                                <strong>Legal Consent:</strong> Users must consent to abide by all applicable laws and regulations regarding the creation and
                                use of the card, including but not limited to laws related to forgery, misrepresentation, and intellectual property rights.
                            </li>
                            <li>
                                <strong>Indemnification:</strong> Users agree to indemnify and hold harmless the service provider from any claims, damages,
                                or liabilities arising from their use of the service, including but not limited to claims related to the creation or distribution of the card.
                            </li>
                            <li>
                                <strong>Legal Disclaimers:</strong> Include legal disclaimers specifying the informational nature of some of the card,
                                its non-governmental status, and its intended use for personal or informational purposes only.
                            </li>
                            <li>
                                <strong>Authenticity Validation:</strong> It is the sole responsibility of the user to validate the authenticity of
                                the ID card PDF Files being used with ID Maker. Only files containing valid digital signatures should be considered authentic.
                            </li>
                            <li>
                                <strong>Data Privacy:</strong> Users acknowledge that their personal data may be collected and processed in accordance with 
                                the service provider's privacy policy. While making the card, you authorize us to retrieve the necessary information for card 
                                creation from our pool of open data sources.
                            </li>
                        </ul>

                        <div className="text-center">
                            <p className="small mb-0">By using our service, you agree to these terms and conditions including
                                Terms - <Link to="/terms">Terms and Conditions</Link>,
                                Privacy - <Link to="/privacy">Privacy Policy</Link>,
                                Refund - <Link to="/refund">Refund Policy</Link> and
                                Disclaimer - <Link to="/how-we-make-cards">how-we-make-cards</Link>.
                            </p>
                        </div>

                    </div>

                </Modal.Body>
                {/* <!-- End Body --> */}

                {/* <!-- Footer --> */}
                <Modal.Footer className="d-block text-center py-sm-3 mx-3">

                    {/* accept btn */}
                    <div className="row justify-content-around align-items-center">

                        <div className="col">
                            <Button variant="secondary" onClick={onDecline}>No, I Do Not Agree</Button>
                        </div>

                        <div className="col">
                            <Button variant="success" onClick={_handleAccept}>Yes, I Agree</Button>
                        </div>

                    </div>

                </Modal.Footer>
                {/* <!-- End Footer --> */}

            </Modal>
        </>

    )


}

function NewCardRequestModal({ show, onClose }) {
    const { isLogin } = useContext(AuthContext);
    const { isLoading, data } = useUser(isLogin);

    const mutateWebhook = useMutationSendWebhook();

    async function onsubmit(e) {
        e.preventDefault();
        await mutateWebhook.mutateAsync({
            embeds: [
                {
                    title: "Website Feature Request | ID Maker",
                    description: "New Feature Request",
                    url: window.location.href,
                    color: getRandomColor(),
                    fields: [
                        {
                            name: "Name",
                            value: data?.full_name as string,
                            inline: true,
                        },
                        {
                            name: "Email",
                            value: data?.email as string,
                            inline: true,
                        },
                        {
                            name: "Phone",
                            value: data?.phone as string,
                            inline: false,
                        },
                        {
                            name: "Callable",
                            value: data?.acquired_by? "No" : "Yes",
                            inline: false,
                        },
                        {
                            name: "Description",
                            value: e.target.description.value,
                            inline: false,
                        },
                    ],
                    timestamp: new Date().toISOString(),
                },
            ],
        });
        onClose();
    }


    return (
        <Modal show={show} onHide={onClose} centered>

            {/* <!-- Header --> */}
            <Modal.Header closeButton className='border-0' />
            {/* <!-- End Header --> */}

            {/* <!-- Body --> */}
            <Modal.Body className="mb-4">
                {/* <!-- Heading --> */}
                <div className="text-center mb-4">
                    <h2>Feature Request</h2>
                    <p className="mb-1"><strong>English: </strong>Please let us know if there are any additional services you require to further support your business, and we will do our best to accommodate your needs.</p>
                    <p><strong>Hindi: </strong> कृपया हमें बताएं कि क्या आपके व्यवसाय को आगे बढ़ाने के लिए किसी अतिरिक्त सेवा की आवश्यकता है, और हम आपकी आवश्यकताओं को पूरा करने की पूरी कोशिश करेंगे।</p>
                </div>
                {/* <!-- End Heading --> */}

                <div className="d-grid gap-3 mx-2 mx-md-4">

                    <Form onSubmit={onsubmit}>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" defaultValue={data?.email} disabled />
                        </Form.Group>

                        {/* description text area */}
                        <Form.Group className="mb-3" controlId="formBasicDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" placeholder="Description" required name="description" />
                        </Form.Group>

                        <div className="d-grid mt-4">
                            <Button variant="primary-soft" type="submit">
                                <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{ display: (!isLoading && !mutateWebhook.isLoading) ? 'None' : "" }}></span>
                                Submit
                            </Button>
                        </div>

                    </Form>

                </div>
            </Modal.Body>
        </Modal>
    )
}


function ChangePasswordModal({ show, onClose }) {

    type PasswordPayload = {
        password: string,
        confirm_password: string
    }

    // min 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit and one special character
    const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()]).{8,}$/;

    const schema = yup.object().shape({
        password: yup.string().required("Password is required").matches(passwordRules, "Password must be at least 8 characters long and contain at least one number and one special character and one uppercase letter").max(32, "Password is too long").min(8, "Password is too short"),
        confirm_password: yup.string().required("Confirm Password is required").oneOf([yup.ref('password')], 'Passwords must match')
    });

    const { register, handleSubmit, formState: { errors, dirtyFields } } = useForm<PasswordPayload>(
        { resolver: yupResolver(schema), mode: 'all' }
    )

    const { mutateAsync, isLoading } = useMutationUpdatePasswordForced();

    const captcha = GoogleCaptchaV3();

    const onSubmit = async (data: PasswordPayload) => {
        const captcha_answer = await captcha.sureChanllenge("force_change_password");

        await mutateAsync({ ...data, captcha: captcha_answer || "" }, {
            onSuccess: () => {
                onClose();
            }
        
        });

    }

    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Header closeButton className='border-0' />
            <Modal.Body>
                <div className="text-center mb-4">

                    <img className="avatar avatar-xxl mb-4" src="https://cdn-icons-png.freepik.com/512/5781/5781297.png" alt="Image Description" />

                    <h2>Secure Account</h2>
                    <p>Your account is more secure with a strong password.</p>
                </div>

                <div className="d-grid gap-3 mx-2 mx-md-4">
                    <Form onSubmit={handleSubmit(onSubmit)}>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="text" placeholder="Password" {...register('password')} isInvalid={!!errors.password} isValid={dirtyFields.password && !errors.password} />
                            <Form.Text className="text-danger">{errors.password?.message}</Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control type="text" placeholder="Confirm Password" {...register('confirm_password')} isInvalid={!!errors.confirm_password} isValid={dirtyFields.confirm_password && !errors.confirm_password} />
                            <Form.Text className="text-danger">{errors.confirm_password?.message}</Form.Text>
                        </Form.Group>

                        <div>
                            <button type="submit" className="btn btn-success-soft w-100 mb-0" disabled={isLoading || captcha.isLoading}>
                                <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"
                                    style={{ display: (isLoading || captcha.isLoading) ? 'inline-block' : 'none' }}></span>
                                Create Account
                            </button>
                        </div>


                    </Form>
                </div>


            </Modal.Body>
        </Modal>
    )


}

export { SignUpModal, UpdateProfileModal, ConfirmModal, NewCardRequestModal, ChangePasswordModal };