import applyTheme from '../../../components/Theme/Theme';
import { Link, Navigate } from 'react-router-dom';

import Branding from '../../../Branding/Branding';
import GoogleCaptchaV3 from '../../../components/Captcha/google';

import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';
import { Form } from 'react-bootstrap';

import { useMutationVerificationUpdatePassword } from '../../../api/login';
import { useNavigate } from 'react-router-dom';


function PublicPasswordChangeMethod({ token }) {
    // const [showPassword, setShowPassword] = useState(true);

    const navigate = useNavigate();

    type PasswordPayload = {
        password: string,
        confirm_password: string
    }

    // min 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit and one special character
    const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()]).{8,}$/;
    

    const schema = yup.object().shape({
        password: yup.string().required("Password is required").matches(passwordRules, "Password must be at least 8 characters long and contain at least one number and one special character and one uppercase letter").max(32, "Password is too long").min(8, "Password is too short"),
        confirm_password: yup.string().required("Confirm Password is required").oneOf([yup.ref('password')], 'Passwords must match')
    });

    const { register, handleSubmit, formState: { errors, dirtyFields } } = useForm<PasswordPayload>(
        { resolver: yupResolver(schema), mode: 'all' }
    )

    const captcha = GoogleCaptchaV3();
    const { mutateAsync, isLoading } = useMutationVerificationUpdatePassword();

    async function onSubmit(data: PasswordPayload) {
        const answer = await captcha.sureChanllenge("update_password");
        
        await mutateAsync({
            token,
            ...data,
            captcha: answer || ""
        }, {
            onSuccess: () => {
                navigate("/login");
            },
        });

    }


    const branding = Branding();
    

    return (
        <div className="bg-mode shadow rounded-3 overflow-hidden">
            <div className="row g-0">
                {/* <!-- Vector Image --> */}
                <div className="col-lg-6 d-flex align-items-center order-2 order-lg-1">
                    <div className="p-3 p-lg-5">
                        <img src="/static/assets/images/element/signin.svg" alt="" />
                    </div>
                    <div className="vr opacity-1 d-none d-lg-block"></div>
                </div>

                {/* <!-- Information --> */}
                <div className="col order-1">

                    <div className="p-4 p-sm-7">

                        {/* <!-- Logo --> */}
                        <Link to="/">
                            <img className="h-50px mb-4" src={branding.logo} alt="logo" />
                        </Link>

                        {/* <!-- Title --> */}
                        <h1 className="mb-2 h3">Update Your Password</h1>

                        <Form className="mt-4 text-start needs-validation" onSubmit={handleSubmit(onSubmit)}>

                            <Form.Group className="mb-3" controlId="password">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control type="text" placeholder="New Password" {...register("password")} required isInvalid={!!errors.password} isValid={dirtyFields.password && !errors.password} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.password?.message}
                                </Form.Control.Feedback>
                                <Form.Control.Feedback>
                                    <span className="bi bi-check-circle-fill"></span> Strong Password!
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="confirm_password">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control type="text" placeholder="Confirm Password" {...register("confirm_password")} required isInvalid={!!errors.confirm_password} isValid={dirtyFields.confirm_password && !errors.confirm_password} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.confirm_password?.message}
                                </Form.Control.Feedback>
                                <Form.Control.Feedback>
                                    Passwords match!
                                </Form.Control.Feedback>
                            </Form.Group>

                            <div>
                                <button type="submit" className="btn btn-primary w-100 mb-0" disabled={isLoading || captcha.isLoading}>
                                    <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"
                                        style={{ display: (isLoading || captcha.isLoading) ? 'inline-block' : 'none' }}></span>
                                    Change Password
                                </button>
                            </div>

                        </Form>



                        {/* <!-- Divider --> */}
                        <div className="position-relative my-4">
                            <hr />
                        </div>

                        {/* <!-- Divider --> */}

                        {/* <!-- Copyright --> */}
                        <div className="text-primary-hover mt-3 text-center">
                            Copyrights <i className="bi bi-c-circle"></i> {new Date().getFullYear()} <Link to="https://github.com/mayankfawkes" target="_blank" rel="noopener noreferrer" className="text-muted">
                                MayankFawkes
                            </Link>
                            <br />
                            Registration No: UDYAM-MP-15-0001259
                        </div>


                        {/* <!-- Form END --> */}
                    </div>
                </div>


            </div>
        </div>
    )
}



function PublicPasswordChange() {
    applyTheme();

    // parse token query string
    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");

    // check if token is uuid
    if (!token || token.length !== 36) {
        return (
            <Navigate to="/" />
        )

    }

    return (
        <main>
            <section className="">
                <div className="container h-100 d-flex px-0 px-sm-4">
                    <div className="row justify-content-center align-items-center m-auto">
                        <div className="col-12">


                            <PublicPasswordChangeMethod token={token} />

                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}


export default PublicPasswordChange;