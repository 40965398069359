
function LalitAddMoney() {
    return (
        <>
            <div className="row g-4">
                <div className="col-md-6 mx-auto">
                    <div className="card border">
                        <div className="card-header align-items-center border-bottom p-4">

                            <h3 className="mb-0 text-center">Contact & Payment Details</h3>

                        </div>
                        {/* <!-- Card body START --> */}

                        <div className="card-body">
                            <div className="row g-4 mb-3">

                                {/* <h1 style="display: none">Contact Lalit Sardesai</h1>
                            <h2 style="display: none">Mobile: +91-9975699434</h2>
                            <h2 style="display: none">Office: +91-9975176218</h2>
                            <h2 style="display: none">Email: mediapro1008@gmail.com</h2> */}
                                <img src="https://ik.imagekit.io/MayankFawkes/idmaker/payment_qr/lalit.jpg" width="350" />

                            </div>
                        </div>

                        {/* <!-- Card body END --> */}
                    </div>
                </div>

            </div>

        </>
    )
}


function KapilAddMoney() {
    return (
        <>
            <div className="row g-4">
                <div className="col-md-6 mx-auto">
                    <div className="card border">
                        <div className="card-header align-items-center border-bottom p-4">

                            <h3 className="mb-0 text-center">Contact & Payment Details</h3>

                        </div>
                        {/* <!-- Card body START --> */}

                        <div className="card-body">
                            <div className="row g-4 mb-3">

                                <div className="mt-5 text-center">
                                    <h4>Contact Details</h4>
                                    <p className="mb-0">Mobile: +91-8962012000</p>
                                    <p className="mb-0">Whatsapp: +91-7389325851</p>
                                    <p className="mb-0">Email: kapilbadera@rediffmail.co.in</p>
                                    <p className="mb-0">UPI: kapil.badera@ybl</p>
                                </div>
                                <div className="mt-5 text-center">
                                    <h4>Bank Details</h4>
                                    <p className="mb-0">STATE BANK OF INDIA ACCOUNT NUMBER</p>
                                    <p className="mb-0">A/C NO.-   32157387388</p>
                                    <p className="mb-0">A/C NAME-  THE MULTI LINK DOT COM</p>
                                    <p className="mb-0">A/C TYPE- CURRENT</p>
                                    <p className="mb-0">BRANCH- KARAHI</p>
                                    <p className="mb-0">BRANCH CODE-30487</p>
                                    <p className="mb-0">IFSC CODE-SBIN0030487</p>
                                </div>
                                <div className="mt-5 text-center">
                                    <h4>Qr Code</h4>
                                    <img src="https://ik.imagekit.io/MayankFawkes/idmaker/payment_qr/kapil.jpeg" width="350" />
                                </div>  
                                <div className="mt-5 text-center">
                                    <h4>Price Details</h4>
                                    <p className="mb-0">300/- मे 100</p>
                                    <p className="mb-0">550/- मे 200</p>
                                    <p className="mb-0">1000/- मे 400</p>
                                    <p className="mb-0">2000/- मे 900</p>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Card body END --> */}
                    </div>
                </div>

            </div>

        </>
    )
}


function AmitAddMoney() {
    return (
        <>
            <div className="row g-4">
                <div className="col-md-6 mx-auto">
                    <div className="card border">
                        <div className="card-header align-items-center border-bottom p-4">

                            <h3 className="mb-0 text-center">Contact & Payment Details</h3>

                        </div>
                        {/* <!-- Card body START --> */}

                        <div className="card-body">
                            <div className="row g-4 mb-3">

                                <div className="mt-5 text-center">
                                    <h4>Contact Details</h4>
                                    <p className="mb-0">Name: Amit Mourya</p>
                                    <p className="mb-0">Mobile: +91-8655555571</p>
                                    <p className="mb-0">Email: amitrmourya@gmail.com</p>
                                </div>

                                <div className="mt-5 text-center">
                                    <h4>Payment Details</h4>
                                    <p className="mb-0">UPI: 8655555571@upi</p>
                                    <p className="mb-0 mt-2 fw-bold text-danger">Please share your payment screenshot on whatsapp</p>
                                    <p className="mb-0 fs-5 fw-bold text-danger">Minimum payment 100</p>

                                </div>
                                <div className="mt-5 text-center">
                                    <h4>Qr Code</h4>
                                    <img src="https://ik.imagekit.io/MayankFawkes/idmaker/payment_qr/amit_HsBBcYe5b.jpeg" width="350" />
                                </div>  
                            </div>
                        </div>

                        {/* <!-- Card body END --> */}
                    </div>
                </div>

            </div>

        </>
    )
}





export { LalitAddMoney, KapilAddMoney, AmitAddMoney}