function getRandomColor(): number {
    // Generate random values for red, green, and blue channels
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
  
    // Combine the channels to create a single integer color code
    const color = (red << 16) | (green << 8) | blue;
  
    return color;
}



export { getRandomColor }