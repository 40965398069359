import { jsPDF } from "jspdf";

async function download_img(imageSrc:string, filename:string) {
    const image = await fetch(imageSrc)
    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)
  
    const link = document.createElement('a')
    link.href = imageURL
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

function download_canvas(innerRef: React.MutableRefObject<HTMLCanvasElement>, filename: string) {
	const canvas = innerRef.current
	const image = canvas.toDataURL("image/png", 1.0).replace("image/png", "image/octet-stream");
	var link = document.createElement('a');
	link.download = filename;
	link.href = image;
	link.click();
}

function download_canvas_pdf(innerRef: React.MutableRefObject<HTMLCanvasElement>, filename: string) {
	const canvas = innerRef.current
	let image = canvas.toDataURL("image/png", 1.0);

    const orientation = canvas.width > canvas.height ? "landscape" : "portrait";

    const doc = new jsPDF({
        orientation: orientation,
        unit: "pt",
        format: [canvas.width, canvas.height]
    });

    console.log(doc.getLineWidth())

	doc.addImage(image, 'PNG', 0, 0, canvas.width, canvas.height, undefined, 'FAST');
	doc.save(filename);
}


export { download_img, download_canvas, download_canvas_pdf };