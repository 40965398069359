import Card from 'react-bootstrap/Card';
import { useMutationUserAddGiftCard } from '../../../../../api/payment';

import GoogleCaptchaV3 from '../../../../../components/Captcha/google';

import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';


function PublicDashboardPaymentOther() {
    const captcha = GoogleCaptchaV3();

    const { mutate: userAddGiftCard, isLoading: giftcardLoading } = useMutationUserAddGiftCard();
    type GiftcardPayload = {
        giftcard: string,
    }

    const uuid_regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    const schema = yup.object().shape({
        giftcard: yup.string().required("Gift Card is required").matches(uuid_regex, "Gift Card is not valid")
    });

    const { register, handleSubmit, formState: { errors, dirtyFields } } = useForm<GiftcardPayload>(
        { resolver: yupResolver(schema), mode: 'all' }
    )

    const onSubmit = async (data: GiftcardPayload) => {
        let answer = await captcha.sureChanllenge("add_giftcard");
        if (!answer) return;

        userAddGiftCard({
            giftcard: data.giftcard,
            captcha: answer,
        });
    };

    return (
        <div className="row g-4">
            <div className="col-md-6 mx-auto">
                <Card className='border shadow'>
                    <Card.Header className='border-bottom'>
                        <h5 className="card-header-title">Add Gift Card</h5>
                        <p className="mb-0 small">Add gift card to your account</p>
                    </Card.Header>
                    
                    <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                        <Card.Body>
                            <Form.Group className="mb-3" controlId="giftcard">
                                <Form.Label>Gift Card Number</Form.Label>
                                <Form.Control type="text" placeholder="Gift Card Number" {...register("giftcard")} required isInvalid={!!errors.giftcard} isValid={dirtyFields.giftcard && !errors.giftcard} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.giftcard?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Card.Body>
                        <Card.Footer className="border-top">
                            <div className="text-center">
                                <button type="submit" className="btn btn-primary fs-5 me-3" disabled={(captcha.isLoading || giftcardLoading)}>
                                    <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{ display: (captcha.isLoading || giftcardLoading) ? 'inline-block' : 'none' }}></span>
                                    <span>Submit</span>
                                </button>
                            </div>
                        </Card.Footer>
                    </Form>


                </Card>
            </div>
        </div>
    );
}

export default PublicDashboardPaymentOther;
