
import axiosClient from "../hooks/useAxios";
import { AxiosResponse, AxiosError } from "axios";

import { server4XXError } from "./types";
import { useMutation } from "react-query";
import { handleErrors } from "./handleErrors";


export type typePhonePePayPayload = {
    amount: number,
    captcha: string
}

export type typePhonePePayResponse = {
    link: string;
}

const mutationPhonePePay = async (payload: typePhonePePayPayload): Promise<typePhonePePayResponse>  => {
    const response = await axiosClient.post<typePhonePePayPayload, AxiosResponse<typePhonePePayResponse>>("/payment/phonepe/pay", payload);
    return response.data;
}

const useMutationUserPhonePePay = () => {
    return useMutation<typePhonePePayResponse, AxiosError<server4XXError>, typePhonePePayPayload>({
        mutationKey: ["userPhonePePay"],
        mutationFn: mutationPhonePePay,
        onError: handleErrors
    })
}


export { useMutationUserPhonePePay }