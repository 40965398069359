// import { useParams } from 'react-router-dom';
import PublicDashboardTitle from "../Components/Title";

import AuthContext from '../../../../context/auth';
import { useContext, useState } from 'react';
import React from "react";
import PublicDashboardIDCard from "./Helper/Card";

import AdsComponent from '../../../../components/GoogleAds/GoogleAds';


function PublicDashboardCardsByState() {
    const [search, setSearch] = useState("");
    const selectedState = window.location.pathname.split("/").slice(-1)[0];
    
    const { fn } = useContext(AuthContext);

    const cards = fn.cardsForState(selectedState);

    // Function to generate menu items
    const generateMenuItems = () => {
        const menuItems = cards
            .filter(n => n.name.toLowerCase().includes(search.toLowerCase()))
            .map((n, index) => (
                <PublicDashboardIDCard key={index} icon={n.icon} name={n.name} to={n.path} imgclass={"w-105px"}/>
            ));

        // Insert AdsComponent after every 12 menu items
        const menuItemsWithAds:JSX.Element[] = [];
        for (let i = 0; i < menuItems.length; i++) {
            menuItemsWithAds.push(menuItems[i]);
            if ((i + 1) % 12 === 0) {
                menuItemsWithAds.push(
                    <div className="col-12" key={`ad_${i}`}>
                        <AdsComponent dataAdSlot="5824067931" />
                    </div>
                );
            }
        }
        return menuItemsWithAds;
    };

    // Generate menu items
    const menuItems = generateMenuItems();

    return (
        <>
            <PublicDashboardTitle title={"All " + fn.fullStateName(selectedState) + " Cards"} icon={"fi fi-in fa-fw me-1"} addcardbtn={true} />

            <section className="pt-0">
                <div className="container">

                    <div className="row g-4">

                        <div className="col-xl-10 mx-auto">
                            <div className="card shadow h-100 p-4">
                                <form className="row g-3 justify-content-center align-items-center">
                                    <div className="col-lg-5">
                                        {/* <!-- Input --> */}
                                        <div className="form-input-dropdown position-relative">
                                            <input className="form-control form-control-lg me-1 ps-5"
                                                type="text" placeholder="Search Card by Name"
                                                aria-expanded="false" onChange={(e) => setSearch(e.target.value)} />

                                            {/* <!-- Icon --> */}
                                            <span className="position-absolute top-50 start-0 translate-middle ps-5"><i className="bi bi-search fs-5"></i></span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>


                        {menuItems.map((menuItem, index) => (
                            <React.Fragment key={index}>
                                {menuItem}
                            </React.Fragment>
                        ))}


                        <div className="col-xl-10 mx-auto">
                            <AdsComponent />
                        </div>
                        
                    </div>


                </div>
            </section>

        </>
    )
}


export default PublicDashboardCardsByState;