import { useContext, useEffect } from "react";
import AuthContext from "../../../../context/auth";
import { useUser } from "../../../../api/basic";
import Loader from "../../../../components/Loader/Loader";
import { useMutationUserProfileUpdate } from "../../../../api/update";
import queryClient from "../../../../context/query";

import GoogleCaptchaV3 from "../../../../components/Captcha/google";

import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';


function PublicDashboardSettingsProfile() {
    const { states, isLogin, setUser } = useContext(AuthContext);
    const { isLoading, data } = useUser(isLogin);
    const updateProfile = useMutationUserProfileUpdate();
    const captcha = GoogleCaptchaV3();

    type ProfileUpdatePayload = {
        full_name: string,
        phone: number,
        state: string,
        dob: string,
        gender: string,
        account_type: string,
        pincode: string,
        full_address: string
    }

    const schema = yup.object().shape({
        full_name: yup.string().required("Full name is required"),
        phone: yup.number().required("Phone number is required").min(1000000000, "Phone number is not valid").max(9999999999, "Phone number is not valid"),
        state: yup.string().required("State is required").notOneOf(["DEFAULT"], "State is required"),
        dob: yup.string().required("Date of birth is required"),
        gender: yup.string().required("Gender is required"),
        account_type: yup.string().required("Account type is required"),
        pincode: yup.string().required("Pincode is required"),
        full_address: yup.string().required("Full address is required")
    });

    const { register, handleSubmit, formState: { errors, dirtyFields } } = useForm<ProfileUpdatePayload>(
        { resolver: yupResolver(schema), mode: 'all' }
    );


    useEffect(() => {
        if (data) {
            setUser(data);
        }
    }, [data])

    if (isLoading || !data) {
        return <Loader middle={true} />
    }

    async function onSubmit(data: ProfileUpdatePayload) {
        let answer = await captcha.sureChanllenge("update_profile");

        await updateProfile.mutateAsync({
            ...data,
            captcha: answer || ""

        });
        queryClient.invalidateQueries("user");
    }



    return (
        <div className="vstack gap-4">
            {/* <!-- Verified message --> */}
            <div className="bg-light rounded p-3">
                {/* <!-- Progress bar --> */}
                <div className="overflow-hidden">
                    <h6>Complete Your Profile</h6>
                    <div className="progress progress-sm bg-success bg-opacity-10">
                        <div className="progress-bar bg-success aos" role="progressbar" data-aos="slide-right" data-aos-delay="200" data-aos-duration="1000" data-aos-easing="ease-in-out" style={{ width: (data.phone && data.profile && data.profile.full_address) ? "100%" : (data.phone ? "75%" : "45%") }}>
                            <span className="progress-percent-simple h6 fw-light ms-auto">
                                {
                                    (data.phone && data.profile && data.profile.full_address) ? "100%" : (data.phone ? "75%" : "45%")
                                }
                            </span>
                        </div>
                    </div>
                    <p className="mb-0">Get the best out of booking by adding the remaining details!</p>
                </div>
                {/* <!-- Content --> */}
                <div className="bg-body rounded p-3 mt-3">
                    <ul className="list-inline hstack flex-wrap gap-2 justify-content-between mb-0">
                        <li className="list-inline-item h6 fw-normal mb-0"><a><i className="bi bi-check-circle-fill text-success me-2"></i>Verified Email</a></li>
                        <li className="list-inline-item h6 fw-normal mb-0"><a className={
                            (data.phone ? "" : "text-primary")
                        } ><i className={data.phone ? "bi bi-check-circle-fill text-success me-2" : "bi bi-plus-circle-fill me-2"}></i>Add Mobile Number</a></li>
                        <li className="list-inline-item h6 fw-normal mb-0"><a className={(data.phone && data.profile && data.profile.full_address) ? "" : "text-primary"}><i className={(data.phone && data.profile && data.profile.full_address) ? "bi bi-check-circle-fill text-success me-2" : "bi bi-plus-circle-fill me-2"}></i>Complete Basic Info</a></li>
                    </ul>
                </div>
            </div>

            {/* <!-- Personal info START --> */}
            <div className="card border">
                {/* <!-- Card header --> */}
                <div className="card-header border-bottom">
                    <h4 className="card-header-title">Personal Information</h4>
                </div>

                {/* <!-- Card body START --> */}

                {/* <!-- Form START --> */}
                {/* <form onSubmit={onSubmit}>
                        <div className="card-body row g-3">

                            <div className="col-md-6">
                                <label className="form-label">Full Name<span className="text-danger">*</span></label>
                                <input type="text" name="full_name" className="form-control" defaultValue={data.profile? data.profile.full_name : ""} placeholder="Enter your full name" required/>
                            </div>


                            <div className="col-md-6">
                                <label className="form-label">Mobile number<span className="text-danger">*</span></label>
                                <input type="text" name="phone" className="form-control" defaultValue={data.phone} placeholder="Enter your mobile number" required/>
                            </div>


                            <div className="col-md-6">
                                <label className="form-label">State<span className="text-danger">*</span></label>
                                <select className="form-select js-choice" name="state" defaultValue={data.profile? data.profile.state : "DEFAULT"} required>
                                    <option value="DEFAULT">Select your state</option>
                                    {
                                        states.map((state, index) => {
                                            return <option value={state.shortName()} key={index}>{state.name}</option>
                                        })
                                    }
                                </select>
                            </div>

                
                            <div className="col-md-6">
                                <label className="form-label">Date of Birth<span className="text-danger">*</span></label>
                                <input type="date" name="dob" className="form-control" placeholder="Enter date of birth" data-date-format="d M Y" defaultValue={data.profile? data.profile.dob: ""} required/>
                            </div>

                    
                            <div className="col-md-4">
                                <label className="form-label">Select Gender <span className="text-danger">*</span></label>
                                <select className="form-select" name="gender" defaultValue={data.profile? data.profile.gender: "m"} required>
                                    <option value="m">Male</option>
                                    <option value="f">Female</option>
                                    <option value="o">Other</option>
                                </select>
                            </div>

                    
                            <div className="col-md-4">
                                <label className="form-label">Select Account Type <span className="text-danger">*</span></label>
                                <select className="form-select" name="account_type" defaultValue={data.profile? data.profile.account_type: "business"} required>
                                    <option value="business">Business (Dukan)</option>
                                    <option value="individual">Individual</option>
                                </select>
                            </div>

                        
                            <div className="col-md-4">
                                <label className="form-label">Pincode <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name="pincode" defaultValue={data.profile? data.profile.pincode: ""} placeholder="Enter your pincode" required/>
                            </div>

                      
                            <div className="col-12">
                                <label className="form-label">Billing Address <span className="text-danger">*</span></label>
                                <textarea className="form-control" name="full_address" rows={3} spellCheck="false" defaultValue={data.profile? data.profile.full_address : ""} required/>
                            </div>

                            <div className="text-end mt-3">
                                <button className="btn btn-primary fs-5" disabled={updateProfile.isLoading || captcha.isLoading} >
                                    <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{ display: (updateProfile.isLoading || captcha.isLoading) ? 'inline-block' : 'none' }}></span>
                                    <span role="status">Update</span>
                                </button>
                            </div>

                        </div>
                    </form> */}

                <Form className="card-body row g-3" onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="full_name">
                            <Form.Label>Full Name<span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" placeholder="Enter your full name" {...register("full_name")} defaultValue={data.profile ? data.profile.full_name : ""} required isInvalid={!!errors.full_name} isValid={dirtyFields.full_name && !errors.full_name} />
                            <Form.Control.Feedback type="invalid">
                                {errors.full_name?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>

                    <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="phone">
                            <Form.Label>Mobile number<span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" placeholder="Enter your mobile number" {...register("phone")} defaultValue={data.phone} required isInvalid={!!errors.phone} isValid={dirtyFields.phone && !errors.phone} />
                            <Form.Control.Feedback type="invalid">
                                {errors.phone?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>

                    <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="state">
                            <Form.Label>State<span className="text-danger">*</span></Form.Label>
                            <Form.Select {...register("state")} defaultValue={data.profile ? data.profile.state : "DEFAULT"} required isInvalid={!!errors.state} isValid={dirtyFields.state && !errors.state}>
                                <option value="DEFAULT">Select your state</option>
                                {
                                    states.map((state, index) => {
                                        return <option value={state.shortName()} key={index}>{state.name}</option>
                                    })
                                }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {errors.state?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>

                    <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="dob">
                            <Form.Label>Date of Birth<span className="text-danger">*</span></Form.Label>
                            <Form.Control type="date" placeholder="Enter date of birth" {...register("dob")} defaultValue={data.profile ? data.profile.dob : ""} required isInvalid={!!errors.dob}
                                isValid={dirtyFields.dob && !errors.dob} />

                            <Form.Control.Feedback type="invalid">
                                {errors.dob?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group className="mb-3" controlId="gender">
                            <Form.Label>Select Gender <span className="text-danger">*</span></Form.Label>
                            <Form.Select {...register("gender")} required>
                                <option value="m">Male</option>
                                <option value="f">Female</option>
                                <option value="o">Other</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    
                    <div className="col-md-4">
                        <Form.Group className="mb-3" controlId="account_type">
                            <Form.Label>Select Account Type <span className="text-danger">*</span></Form.Label>
                            <Form.Select {...register("account_type")} required>
                                <option value="business">Business (Dukan)</option>
                                <option value="individual">Individual</option>
                            </Form.Select>
                        </Form.Group>
                    </div>

                    <div className="col-md-4">
                        <Form.Group className="mb-3" controlId="pincode">
                            <Form.Label>Pincode <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" placeholder="Enter your pincode" {...register("pincode")} defaultValue={data.profile ? data.profile.pincode : ""} required isInvalid={!!errors.pincode} isValid={dirtyFields.pincode && !errors.pincode} />
                            <Form.Control.Feedback type="invalid">
                                {errors.pincode?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    
                    </div>

                    <div className="col-12">
                        <Form.Group className="mb-3" controlId="full_address">
                            <Form.Label>Billing Address <span className="text-danger">*</span></Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter your full address" {...register("full_address")} defaultValue={data.profile ? data.profile.full_address : ""} required isInvalid={!!errors.full_address} isValid={dirtyFields.full_address && !errors.full_address} />
                            <Form.Control.Feedback type="invalid">
                                {errors.full_address?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>

                    <div className="text-end mt-3">
                        <button className="btn btn-primary fs-5" disabled={updateProfile.isLoading || captcha.isLoading} >
                            <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{ display: (updateProfile.isLoading || captcha.isLoading) ? 'inline-block' : 'none' }}></span>
                            <span role="status">Update</span>
                        </button>
                    </div>

                </Form>


                    {/* <!-- Form END --> */}

                    {/* <!-- Card body END --> */}
            </div>
            {/* <!-- Personal info END --> */}

        </div>
    );

}


export default PublicDashboardSettingsProfile;