import { useMutationLoginGoogle } from '../../../../api/login';
import AuthContext from '../../../../context/auth';
import { useContext, useRef } from 'react';
import Branding from '../../../../Branding/Branding';


function PublicGoogleLogin() {

    const { setToken } = useContext(AuthContext);
    const { mutateAsync } = useMutationLoginGoogle();


    const googleRenderBtn = useRef() as React.MutableRefObject<HTMLDivElement>;

    const branding = Branding();

    async function onSignIn(googleUser) {
        const loginPayload = {
            ...googleUser,
            ...(branding.login_payload || {})
        }
        const response = await mutateAsync(loginPayload)
        setToken(response.token);
    }

    function loginPopup() {

        console.log("loginPopup")
        google.accounts.id.initialize({
            client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
            use_fedcm_for_prompt: true,
            callback: data => onSignIn(data)
        });

        // google.accounts.id.prompt((notification) => {
        //     console.log("notification", notification);

        //     // Check if the prompt is not dismissed
        //     if (!notification.isDismissedMoment()) {
        //         // Render a fallback button if the prompt is not displayed
        //         // @ts-ignore
        //         google.accounts.id.renderButton(
        //             googleRenderBtn.current, { theme: "outline", size: "large"}  // customization attributes
        //         );
        //     }
        // });

        google.accounts.id.renderButton(
            googleRenderBtn.current, // Ensure the element exist and it is a div to display correcctly
            {
                type: "standard",
                theme: "filled_blue",
                size: "large",
            }  // customization attributes
        );
        google.accounts.id.prompt(); // Display the One Tap dialog

    }

    return (
        <>
            <div className="vstack gap-3 mb-3">
                <button onClick={loginPopup} className="btn btn-light mb-0">
                    <i className="fab fa-fw fa-google text-google-icon me-2"></i> 
                    Sign in with Google
                </button>
            </div>

            <div ref={googleRenderBtn}
                className="d-flex justify-content-center"
                data-cancel_on_tap_outside="false">
            </div>
        </>
    )

}


export default PublicGoogleLogin;