import applyTheme from '../../../components/Theme/Theme';
import { Link } from 'react-router-dom';

import { useMutationSignupDirect } from '../../../api/login';

import AuthContext from '../../../context/auth';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import Branding from '../../../Branding/Branding';

import PublicGoogleLogin from './components/GoogleSignin';
import GoogleCaptchaV3 from '../../../components/Captcha/google';

import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';




function PublicSignup() {
    applyTheme();

    type SignupPayload = {
        email: string,
        full_name: string,
        phone: number
    }

    const emailRules = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const schema = yup.object().shape({
        email: yup.string().required("Email is required").matches(emailRules, "Email is not valid").lowercase(),
        full_name: yup.string().required("Full Name is required"),
        phone: yup.number().required("Phone number is required").min(1000000000, "Phone number is not valid").max(9999999999, "Phone number is not valid")
    });

    const { register, handleSubmit, formState: { errors, dirtyFields } } = useForm<SignupPayload>(
        { resolver: yupResolver(schema), mode: 'all' }
    );


    const { isLogin } = useContext(AuthContext);
    const { mutateAsync, isLoading } = useMutationSignupDirect();

    const captcha = GoogleCaptchaV3();

    const branding = Branding();


    async function onSubmit(data: SignupPayload) {

        const captcha_answer = await captcha.sureChanllenge("signup");

        const response = await mutateAsync({
            ...data,
            ...(branding.login_payload || {}),
            captcha: captcha_answer || "",
        })

        console.log(response);
    }


    const searchParams = new URLSearchParams(document.location.search)
    const redirect = searchParams.get("redirect")

    if (isLogin) {
        if (redirect) {
            return <Navigate to={redirect} />
        }
        return <Navigate to="/u/index" />
    }


    return (
        <main>
            <section className="vh-100">
                <div className="container h-100 d-flex px-0 px-sm-4">
                    <div className="row justify-content-center align-items-center m-auto">
                        <div className="col-12">
                            <div className="bg-mode shadow rounded-3 overflow-hidden">
                                <div className="row g-0">
                                    {/* <!-- Vector Image --> */}
                                    <div className="col-lg-6 d-flex align-items-center order-2 order-lg-1">
                                        <div className="p-3 p-lg-5">
                                            <img src="/static/assets/images/element/signin.svg" alt="" />
                                        </div>
                                        <div className="vr opacity-1 d-none d-lg-block"></div>
                                    </div>

                                    {/* <!-- Information --> */}
                                    <div className="col order-1">

                                        <div className="p-4 p-sm-7">
                                            {/* <!-- Logo --> */}

                                            <Link to="/">
                                                <img className="h-50px mb-4" src={branding.logo} alt="logo" />
                                            </Link>

                                            {/* <!-- Title --> */}
                                            <h1 className="mb-2 h3">Create new account</h1>
                                            <p className="mb-0">
                                                Already a member?
                                                <Link to="/login" className="mx-2">Log in</Link>
                                            </p>

                                            {/* <form className="mt-4 text-start" onSubmit={onSubmit}>
                                             
                                                <div className="mb-3">
                                                    <label className="form-label">Enter Email ID</label>
                                                    <input name="email" type="email" className="form-control" required />
                                                </div>

        
                                                <div className="mb-3">
                                                    <label className="form-label">Enter Your Full Name</label>
                                                    <input name="full_name" type="text" className="form-control" required />
                                                </div>

                                             
                                                <div className="mb-3">
                                                    <label className="form-label">Enter Phone Number</label>
                                                    <input name="phone" type="tel" className="form-control" required />
                                                </div>

                                                <div className="mb-3">
                                                    {
                                                        cloudflareTurnstile.TurnstileDOM
                                                    }
                                                </div>

                                          
                                                <div className="mb-3 d-sm-flex justify-content-between">
                                                    <div>
                                                        <input type="checkbox" className="form-check-input me-2" id="rememberCheck" required />

                                                        I Agree to these terms and conditions including
                                                        Terms - <Link to="/terms">Terms and Conditions</Link>,
                                                        Privacy - <Link to="/privacy">Privacy Policy</Link>,
                                                        Refund - <Link to="/refund">Refund Policy</Link> and
                                                        Disclaimer - <Link to="/how-we-make-cards">how-we-make-cards</Link>.


                                                    </div>
                                                </div>
                                       
                                                <div>
                                                    <button type="submit" className="btn btn-primary w-100 mb-0" disabled={isLoading || cloudflareTurnstile.isLoading}>
                                                        <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"
                                                            style={{ display: (isLoading || cloudflareTurnstile.isLoading) ? 'inline-block' : 'none' }}></span>
                                                        Create Account
                                                    </button>
                                                </div>

                                  


                                            </form> */}

                                            <Form className="mt-4 text-start needs-validation" onSubmit={handleSubmit(onSubmit)}>
                                                <Form.Group className="mb-3" controlId="email">
                                                    <Form.Label>Email ID</Form.Label>
                                                    <Form.Control type="email" placeholder="Email ID" {...register("email")} required isInvalid={!!errors.email} isValid={dirtyFields.email && !errors.email} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.email?.message}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="full_name">
                                                    <Form.Label>Full Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Full Name" {...register("full_name")} required isInvalid={!!errors.full_name} isValid={dirtyFields.full_name && !errors.full_name} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.full_name?.message}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="phone">
                                                    <Form.Label>Phone Number</Form.Label>
                                                    <Form.Control type="tel" placeholder="Phone Number" {...register("phone")} required isInvalid={!!errors.phone} isValid={dirtyFields.phone && !errors.phone} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.phone?.message}
                                                    </Form.Control.Feedback>
                                                </Form.Group>


                                                <div className="mb-3 d-sm-flex justify-content-between">
                                                    <div>
                                                        <input type="checkbox" className="form-check-input me-2" id="rememberCheck" required />

                                                        I Agree to these terms and conditions including
                                                        Terms - <Link to="/terms">Terms and Conditions</Link>,
                                                        Privacy - <Link to="/privacy">Privacy Policy</Link>,
                                                        Refund - <Link to="/refund">Refund Policy</Link> and
                                                        Disclaimer - <Link to="/how-we-make-cards">how-we-make-cards</Link>.
                                                    </div>
                                                </div>
                                                
                                                <div>
                                                    <button type="submit" className="btn btn-primary w-100 mb-0" disabled={isLoading || captcha.isLoading}>
                                                        <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"
                                                            style={{ display: (isLoading || captcha.isLoading) ? 'inline-block' : 'none' }}></span>
                                                        Create Account
                                                    </button>
                                                </div>

                                            </Form>

                                            <div className="position-relative my-4">
                                                <hr />
                                                <p className="small bg-mode position-absolute top-50 start-50 translate-middle px-2">Or sign in with</p>
                                            </div>


                                            {/* <!-- Google and facebook button --> */}

                                            <PublicGoogleLogin />

                                            {/* <!-- Divider --> */}

                                            {/* <!-- Copyright --> */}
                                            <div className="text-primary-hover mt-3 text-center">
                                                Copyrights <i className="bi bi-c-circle"></i> {new Date().getFullYear()} <Link to="https://github.com/mayankfawkes" target="_blank" rel="noopener noreferrer" className="text-muted">
                                                    MayankFawkes
                                                </Link>
                                                <br />
                                                Registration No: UDYAM-MP-15-0001259
                                            </div>


                                            {/* <!-- Form END --> */}
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}


export default PublicSignup;