function FormatDate(timestamp) {
    const date = new Date(timestamp);
  
    // Get the day, month, year, hours, and minutes
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = date.getFullYear();
    const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = date.getHours() < 12 ? 'AM' : 'PM'; // Uppercase AM/PM
  
    // Format the date and time
    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = `${hours}:${minutes} ${ampm}`;
  
    return `${formattedDate} ${formattedTime}`;
}


function getTimeAgo(timestamp: string) {
    if (!timestamp) return "unknown";
    
    const currentTime = new Date();
    const targetTime = new Date(timestamp);

    const timeDifference: number = currentTime.getTime() - targetTime.getTime();

    const second = 1000;
    const minute = 60 * second;
    const hour = 60 * minute;
    const day = 24 * hour;
    const month = 30 * day;
    const year = 365 * day;

    if (timeDifference < minute) {
        const secondsAgo = Math.floor(timeDifference / second);
        return `${secondsAgo} ${secondsAgo === 1 ? "second" : "seconds"} ago`;
    } else if (timeDifference < hour) {
        const minutesAgo = Math.floor(timeDifference / minute);
        return `${minutesAgo} ${minutesAgo === 1 ? "minute" : "minutes"} ago`;
    } else if (timeDifference < day) {
        const hoursAgo = Math.floor(timeDifference / hour);
        return `${hoursAgo} ${hoursAgo === 1 ? "hour" : "hours"} ago`;
    } else if (timeDifference < month) {
        const daysAgo = Math.floor(timeDifference / day);
        return `${daysAgo} ${daysAgo === 1 ? "day" : "days"} ago`;
    } else if (timeDifference < year) {
        const monthsAgo = Math.floor(timeDifference / month);
        return `${monthsAgo} ${monthsAgo === 1 ? "month" : "months"} ago`;
    } else {
        const yearsAgo = Math.floor(timeDifference / year);
        return `${yearsAgo} ${yearsAgo === 1 ? "year" : "years"} ago`;
    }
}



export { FormatDate, getTimeAgo }