
import { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function GoogleCaptchaV3() {
    const [isLoading, setIsLoading] = useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    async function sureChanllenge(action) {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        setIsLoading(true);

        const _token = await executeRecaptcha(action);

        setIsLoading(false);

        return _token;
        // Do whatever you want with the token
    }


    return {
        sureChanllenge,
        isLoading
    }

}

export default GoogleCaptchaV3;