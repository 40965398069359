
import Header from "../../../components/Header/Header";
import { Routes, Route } from "react-router-dom";

import Footer from "../../../components/Footer/Footer";

import PublicIndex from "./Index";
import PublicContactPage from "./Contact";
import PublicAbout from "./About";
import PublicFAQPage from "./other/Faq";
import PublicPrivacyPage from "./other/Privacy";
import PublicTermsPage from "./other/Terms";
import PublicRefund from "./other/Refund";
import PublicPaymentSlip from "./PaymentSlip";
import PublicDirectLogin from "./DirectLogin";
import PublicAPI from "./other/API";

import PublicPasswordChange from "./UpdatePassword";
import PublicTestPage from "./test";


import PublicHowWeMakeCards from "./other/cards";


import { Navigate } from "react-router-dom";

function PublicStaticPages() {
    return (
      <>
          <Header to="/" />
          <main>
            <Routes>
    
                <Route path='/'	element={<PublicIndex />} />

                <Route path='/contact'	element={<PublicContactPage />} />
                <Route path='/about'	element={<PublicAbout />} />

                <Route path='/faq'	element={<PublicFAQPage />} />
                <Route path='/privacy'	element={<PublicPrivacyPage />} />
                <Route path='/terms'	element={<PublicTermsPage />} />
                <Route path='/refund'	element={<PublicRefund />} />
                <Route path='/how-we-make-cards'	element={<PublicHowWeMakeCards />} />
                <Route path='/api'	element={<PublicAPI />} />

                {
                  import.meta.env.DEV && <Route path='/test'	element={<PublicTestPage />} />
                }
                
                <Route path='/payment_slip/*'	element={<PublicPaymentSlip />} />
                <Route path='/auth_login/*'	element={<PublicDirectLogin />} />
                <Route path='/update_password/*'	element={<PublicPasswordChange />} />

                <Route path='*' element={<Navigate to="/" />} />
            </Routes>
          </main>

          <Footer />

      </>
    )
  }
  
  export default PublicStaticPages;