import React from 'react';
import ReactDOM from 'react-dom/client';

import './styles/css/index.css';
import './styles/css/style.css';
import './styles/vendor/bootstrap-icons/bootstrap-icons.css';
import './styles/vendor/font-awesome/css/all.min.css';
import './styles/vendor/overlay-scrollbar/css/overlayscrollbars.min.css';
import './styles/vendor/apexcharts/css/apexcharts.css';
import './styles/vendor/tiny-slider/tiny-slider.css';
import 'animate.css'

import './styles/vendor/bootstrap/dist/js/bootstrap.bundle.min.js'
import './styles/vendor/overlay-scrollbar/js/overlayscrollbars.min.js'
import './styles/vendor/tiny-slider/tiny-slider.js'

// import './styles/js/functions.js'


import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);