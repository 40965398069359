const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512): Blob => {
    const byteCharacters = atob(b64Data);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {
        type: contentType,
    });

    return blob;
};

import { TypeOutput, TypeOutputCard } from "../api/types";


const createObjectUrlIfLong = (data: string | undefined, mimeType: string) => {
    if (data && data.length > 70) {
        return URL.createObjectURL(b64toBlob(data, mimeType));
    }
    return import.meta.env.VITE_CDN_URL + data; // Return the original data if it's too short
};

const convertBase64ToURL = (data: TypeOutput) => {
    // if data if string else skip
    let cards = data.cards.map((card) => {
        // Conditionally create object URLs only if the property exists
        let result: TypeOutputCard = {};

        // Use the helper function to set values
        result.front = createObjectUrlIfLong(card.front, "image/png");
        result.back = createObjectUrlIfLong(card.back, "image/png");
        result.a4 = createObjectUrlIfLong(card.a4, "application/pdf");
        result.a4_img = createObjectUrlIfLong(card.a4_img, "image/png");
        result.html = createObjectUrlIfLong(card.html, "text/html");

        return result;
    });

    const output: TypeOutput = {
        cards: cards,
        a4_common: data.a4_common ? createObjectUrlIfLong(data.a4_common, "application/pdf") : undefined,
        sample: data.sample,
    };


    return output;
}


export { b64toBlob, convertBase64ToURL }
