
import axiosClient from "../hooks/useAxios";
import { AxiosResponse, AxiosError } from "axios";

import { server4XXError } from "./types";
import { useMutation } from "react-query";
import { handleErrors } from "./handleErrors";
import { alertPaymentSuccess } from "../helper/Alerts";

export type typeEasebuzzPayPayload = {
    amount: number,
    captcha: string
}

export type typeEasebuzzPayResponse = {
    link: string;
    data: string;
}

const mutationEasebuzzPay = async (payload: typeEasebuzzPayPayload): Promise<typeEasebuzzPayResponse>  => {
    const response = await axiosClient.post<typeEasebuzzPayPayload, AxiosResponse<typeEasebuzzPayResponse>>("/payment/easebuzz/pay", payload);
    return response.data;
}

const useMutationUserEasebuzzPay = () => {
    return useMutation<typeEasebuzzPayResponse, AxiosError<server4XXError>, typeEasebuzzPayPayload>({
        mutationKey: ["userEasebuzzPay"],
        mutationFn: mutationEasebuzzPay,
        onError: handleErrors
    })
}

export type TypeEasebuzzPaymentProcessPayload = {
    order_id: string;
    captcha: string | null;
}

export type typeEasebuzzPaymentProcessResponse = {
    message: string;
}

const mutationEasebuzzProcess = async (payload: TypeEasebuzzPaymentProcessPayload): Promise<typeEasebuzzPaymentProcessResponse>  => {
    const response = await axiosClient.post<TypeEasebuzzPaymentProcessPayload, AxiosResponse<typeEasebuzzPaymentProcessResponse>>("/payment/easebuzz/process", payload);
    return response.data;
}

const useMutationUserEasebuzzProcess = () => {
    return useMutation<typeEasebuzzPaymentProcessResponse, AxiosError<server4XXError>, TypeEasebuzzPaymentProcessPayload>({
        mutationKey: ["userEasebuzzProcess"],
        mutationFn: mutationEasebuzzProcess,
        onError: handleErrors,
        onSuccess: (data) => {
            if (data.message) {
                alertPaymentSuccess("Payment Successful", data.message);
            }
        }
    })
}

export { useMutationUserEasebuzzPay, useMutationUserEasebuzzProcess }