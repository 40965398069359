

import PublicDashboardTitle from "../../Public/Dashboard/Components/Title";
import AdminAccountStatus from "./Components/AccountStatus";
import AdminPaymentStatus from "./Components/PaymentStatus";
import AdminProcessPayment from "./Components/ProcessPayment";
import AdminPendingPayment from "./Components/PendingPayment";
import AdminSendBalance from "./Components/SendBalance";

import AdminCreateGiftcard from "./Components/Giftcard";

function AdminMasterPlugin() {
    return (
        <>
            <PublicDashboardTitle title={"Admin Master Plugin"} icon={"bi bi-house-door fa-fw me-1"} addcardbtn={false} />

            <ul className="nav nav-tabs nav-bottom-line mb-3 d-flex justify-content-center">
                <li className="nav-item"> <a className="nav-link active" data-bs-toggle="tab" href="#tab-account-status">Account Status</a> </li>
                <li className="nav-item"> <a className="nav-link" data-bs-toggle="tab" href="#tab-process-payment"> Process Payment </a> </li>
                <li className="nav-item"> <a className="nav-link" data-bs-toggle="tab" href="#tab-pending-payment"> Pending Payment </a> </li>
                <li className="nav-item"> <a className="nav-link" data-bs-toggle="tab" href="#tab-payment-status"> Payment Status </a> </li>
                <li className="nav-item"> <a className="nav-link" data-bs-toggle="tab" href="#tab-send-balance"> Send Balance </a> </li>
                <li className="nav-item"> <a className="nav-link" data-bs-toggle="tab" href="#tab-create-giftcard"> Create Gift Card </a> </li>
            </ul>
            <div className="tab-content mt-3">
                <div className="tab-pane show active" id="tab-account-status">
                    <AdminAccountStatus />
                </div>
                <div className="tab-pane" id="tab-process-payment">
                    <AdminProcessPayment />
                </div>
                <div className="tab-pane" id="tab-pending-payment">
                    <AdminPendingPayment />
                </div>
                <div className="tab-pane" id="tab-payment-status">
                    <AdminPaymentStatus />
                </div>
                <div className="tab-pane" id="tab-send-balance">
                    <AdminSendBalance />
                </div>

                <div className="tab-pane" id="tab-create-giftcard">
                    <AdminCreateGiftcard />
                </div>

            </div>
        </>
    )
}



export default AdminMasterPlugin;