import AuthContext from "../../context/auth";
import useAuth from "../../hooks/useAuth";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { QueryClientProvider } from 'react-query'
import queryClient from "../../context/query";
import { HelmetProvider } from 'react-helmet-async';
// import { useQuery } from 'react-query';


function AppProviders({children}) {
    const a = useAuth();


    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LcULKQpAAAAAMT5im1HeSOUC8fcGAYsOeSvcTBh">
            <HelmetProvider>
                <AuthContext.Provider value={a}>
                    <QueryClientProvider client={queryClient}>
                        {children}
                    </QueryClientProvider>
                </AuthContext.Provider>
            </HelmetProvider>
        </GoogleReCaptchaProvider>
    )

}


export default AppProviders;



