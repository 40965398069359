import { useMutationAdminProcessPayment } from '../../../../api/admin';

function AdminProcessPayment() {
    const { isLoading, mutate } = useMutationAdminProcessPayment();

    function onSubmit(e: any) {
        e.preventDefault();

        mutate({
            order_id: e.target.order_id.value,
            email: e.target.email.value
        })
    }

    return (
        <div className="row">
            <div className="col-md-6 mx-auto">
                <div className="card border">
                    <div className="card-header border-bottom">
                        <h5 className="card-header-title">Process Users Payment</h5>
                        <p className="mb-0 small">Process Users payment by order id and email manually.</p>
                    </div>
                    {/* <!-- Card body START --> */}
                    <form onSubmit={onSubmit}>
                        <div className="card-body">

                            <div className="mb-3">
                                <label className="form-label">Order ID <span className="text-danger">*</span></label>
                                <input type="text" name="order_id" className="form-control" placeholder="Order ID" />
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Email <span className="text-danger">*</span></label>
                                <input type="text" name="email" className="form-control" placeholder="Email" />
                            </div>

                        </div>


                        <div className="card-footer border-top">
                            <div className="text-center">
                                <button className="btn btn-primary fs-5">
                                    <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{display: !isLoading? 'None': ""}}></span>
                                    <span role="status">Submit</span>
                                </button>
                            </div>
                        </div>

                    </form>
                    {/* <!-- Card body END --> */}
                </div>
            </div>
        </div>
    )
}


export default AdminProcessPayment;