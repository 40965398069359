
import { FormatDate } from "../../../helper/Time";

function CustomTable({ data, columns, title }: { data: any[], columns: string[], title: string }) {

    if (columns.length === 0) {
        columns = Object.keys(data[0])
    }

    return (
        <div className="card shadow mb-5">
            <div className="card-header border-bottom">
                <h5 className="card-title mb-0">{title}</h5>
            </div>
            <div className="card-body">
                <div className="bg-light rounded p-3 d-none d-sm-block">
                    <div className="row row-cols-7 g-4">
                        {
                            columns.map((col, index) => (
                                <div className="col" key={index}>
                                    <h6 className="mb-0">{col}</h6>
                                </div>
                            ))
                        }
                    </div>
                </div>


                {
                    data.map((row, index) => (
                        <div className="row row-cols-xl-7 g-4 align-items-sm-center border-bottom px-2 py-4" key={index}>
                            {
                                columns.map((col, index) => {
                                    if ("created_at" === col || "updated_at" === col) {
                                        return (
                                            <div className="col" key={index}>
                                                <small className="d-block d-sm-none">{col}:</small>
                                                <h6 className="fw-light mb-0">{FormatDate(row[col])}</h6>
                                            </div>
                                        )
                                    }
                                    return (
                                        <div className="col" key={index}>
                                            <small className="d-block d-sm-none">User ID:</small>
                                            <h6 className="fw-light mb-0">{row[col]}</h6>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    ))
                }


                <div className="pt-0 mt-4">
                    {/* <!-- Pagination and content --> */}
                    <div className="">
                        {/* <!-- Content --> */}
                        <p className="text-start">Showing {data.length} entries</p>
                        {/* <!-- Pagination --> */}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CustomTable;