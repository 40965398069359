import {useMutationAdminGetUserInit} from "../../../../api/admin";

import { FormatDate } from "../../../../helper/Time";


import { TransactionTable, PaymentsTable, CardLogsTable }  from "../../../../components/Table/table";




function AdminUserInfo() {

    const { data, isLoading, mutate} = useMutationAdminGetUserInit();


    function onSubmit(e) {
        e.preventDefault();

        if (e.target.email && e.target.email.value !== "") {
            mutate({
                email: e.target.email.value,
            });
            return;
        }
        if (e.target.user_id && e.target.user_id.value !== "") {
            mutate({
                user_id: e.target.user_id.value,
            });
            return;
        }
    }

    return (
        <>
        <div className="row">
            <div className="col-md-6 mb-4 mx-auto">
                <div className="card border">
                    <div className="card-header border-bottom">
                        <h5 className="card-header-title">User Info</h5>
                        <p className="mb-0 small">Get Users info by ID/Email</p>
                    </div>
                    {/* <!-- Card body START --> */}
                    <form onSubmit={onSubmit}>
                        <div className="card-body">

                            <div className="mb-3">
                                <label className="form-label">Email</label>
                                <input type="text" name="email" className="form-control" placeholder="Email"/>
                            </div>

                            <div className="mb-3">
                                <label className="form-label">ID</label>
                                <input type="text" name="user_id" className="form-control" placeholder="User ID"/>
                            </div>

                        </div>


                        <div className="card-footer border-top">
                            <div className="text-center d-flex justify-content-around">
                                <button className="btn btn-primary fs-5" >
                                    <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{display: !isLoading? 'None': ""}}></span>
                                    <span role="status">Search</span>
                                </button>
                                {/* <button className="btn btn-primary fs-5" type="button" onClick={() => {refetch()}}>
                                    <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{display: !isRefetching? 'None': ""}}></span>
                                    <span role="status">Refresh</span>
                                </button> */}
                            </div>
                        </div>
                        
                    </form>
                    {/* <!-- Card body END --> */}
                </div>
            </div>


        {
            data && (
            <div className="col-md-6 mb-4">
                <div className="card border">
                    <div className="card-header border-bottom d-flex justify-content-center align-items-center">
                        <div className="avatar avatar-xxl">
                            <img className="fw-light mb-0 avatar-img rounded-circle " src={data.user.avatar} referrerPolicy="no-referrer" alt="avatar"></img>
                        </div>
                    </div>
                    {/* <!-- Card body START --> */}

                    <div className="card-body">
                        <div className="row row-cols-xl-7 g-4 align-items-sm-center px-2 py-3">
                            <div className="col col-md-4">
                                <h6 className="mb-0 fw-normal">Name:</h6>
                            </div>
                            <div className="col">
                                <h6 className="mb-0 fw-normal">{data.user.full_name}</h6>
                            </div>
                        </div>
                        <div className="row row-cols-xl-7 g-4 align-items-sm-center px-2 py-3">
                            <div className="col col-md-4">
                                <h6 className="mb-0 fw-normal">ID:</h6>
                            </div>
                            <div className="col">
                                <h6 className="mb-0 fw-normal">{data.user.id}</h6>
                            </div>
                        </div>
                        <div className="row row-cols-xl-7 g-4 align-items-sm-center px-2 py-3">
                            <div className="col col-md-4">
                                <h6 className="mb-0 fw-normal">Email:</h6>
                            </div>
                            <div className="col">
                                <h6 className="mb-0 fw-normal">{data.user.email}</h6>
                            </div>
                        </div>
                        <div className="row row-cols-xl-7 g-4 align-items-sm-center px-2 py-3">
                            <div className="col col-md-4">
                                <h6 className="mb-0 fw-normal">Balance:</h6>
                            </div>
                            <div className="col">
                                <h6 className="mb-0 fw-normal">{data.user.is_subadmin? `A-${data.user.balance}`: `B-${data.user.balance}`}</h6>
                            </div>
                        </div>
                        <div className="row row-cols-xl-7 g-4 align-items-sm-center px-2 py-3">
                            <div className="col col-md-4">
                                <h6 className="mb-0 fw-normal">Status:</h6>
                            </div>
                            <div className="col">
                                <h6 className="mb-0 fw-normal">{data.user.is_active? "Active": "Deactivated"}</h6>
                            </div>
                        </div>
                        <div className="row row-cols-xl-7 g-4 align-items-sm-center px-2 py-3">
                            <div className="col col-md-4">
                                <h6 className="mb-0 fw-normal">Time:</h6>
                            </div>
                            <div className="col">
                                <h6 className="mb-0 fw-normal">{FormatDate(data.user.created_at)}</h6>
                            </div>
                        </div>
                    </div>
                        

                    {/* <!-- Card body END --> */}
                </div>
            </div>
            )
        }


            {
                data && <TransactionTable data={data.transactions} />
            }

            {
                data && <PaymentsTable data={data.payments} />
            }

            {
                data && <CardLogsTable data={data.cardlogs} />
            }


        </div>

        </>
    )
}

export default AdminUserInfo;