import MainRouter from "./MainRouter";
import AppProviders from "./components/AppProviders/AppProviders";



function App() {
  return (
    <AppProviders>
        <MainRouter />
    </AppProviders>
  );
}

export default App;
