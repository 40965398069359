import { Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import DashboardHeader from "../../../components/Header/DashboardHeader";
import Sidebar from "./Components/Sidebar";
import { TopAlert1 } from "../../../components/TopAlert/TopAlert";


import PublicDashboard from "./Dashboard/Dashboard";
import PublicDashboardPassporSizePhoto from "./PassportSizePhoto/PassportSizePhoto";
import PublicDashboardAllStates from "./Card/AllStates";
import PublicDashboardAllPaidCards from "./Card/ShowAllCards";
import PublicDashboardCardsByState from "./Card/ByState";

import PublicDashboardAllFreeCards from "./Card/FreeCards";

import PublicDashboardPageMaker from "./PageMaker/PageMaker";
import PublicDashboardAddMoney from "./AddMoney/AddMoney";
import PublicDashboardResumeMaker from "./ResumeMaker/ResumeMaker";
import PublicDashboardImageEditor from "./ImageEditor/ImageEditor";
import PublicDashboardSchoolID from "./SchoolID/SchoolID";
import PublicDashboardKundli from "./Kundli/Kundli";

import AuthContext from '../../../context/auth';
import { useContext, useEffect, useState } from 'react';
import Branding from '../../../Branding/Branding';

import { SignUpModal, UpdateProfileModal, ChangePasswordModal } from '../../../helper/Modal';

function PublicDashboardIndex() {
	const { cards, states, isLogin, user } = useContext(AuthContext);
	const [profileModal , setProfileModal] = useState(false);
	const [passwordChangeModal, setPasswordChangeModal] = useState(false);

	const refadmin = Branding();

	let cards_paths: string[] = [];
	let states_paths: string[] = [];
	let count = 0;

	for (let [_, value] of Object.entries(cards)) {
		if (!value.disabled) {
			cards_paths.push(value.path.slice(2, value.path.length));
		}
	}

	for (let key of states) {
		states_paths.push(key.shortName());
	}

	useEffect(() => {
		if (isLogin && (!user.phone || !user.profile )) {
			setProfileModal(true);
		} else {
			setProfileModal(false);
		}

		if (isLogin && !user.password) {
			setPasswordChangeModal(true);
		} else {
			setPasswordChangeModal(false);
		}

	}, [isLogin, user]);


	return (
		<>
			<Sidebar />
			<div className="page-content">

				{(!isLogin && refadmin.refadmin)? <Navigate to="/login" /> : (<SignUpModal show={!isLogin} />)}

				<ChangePasswordModal show={passwordChangeModal} onClose={() => setPasswordChangeModal(false)} />
					
				{
					!passwordChangeModal && <UpdateProfileModal show={profileModal} onClose={() => setProfileModal(false)} />
				}

				<TopAlert1 />
				<DashboardHeader />

				<div className="page-content-wrapper p-xxl-4">
					<Routes>
						{/* <Route	path='/'	element={<Navigate to="/u/index" />} /> */}
						<Route path='/' element={<Navigate replace to="/u/index" />} />
						<Route path='/index' element={<PublicDashboard />} />
						<Route path='/passport_size_photo' element={<PublicDashboardPassporSizePhoto />} />
						<Route path='/all_cards' element={<PublicDashboardAllStates />} />
						<Route path='/all_free' element={<PublicDashboardAllFreeCards />} />
						<Route path='/page_maker' element={<PublicDashboardPageMaker />} />
						{/* <Route path='/all_cards/:selectedState'	element={<PublicDashboardCardsByState />} /> */}
						<Route path='/add_money' element={refadmin.add_money ? refadmin.add_money : <PublicDashboardAddMoney />} />
						<Route path='/resume_maker' element={<PublicDashboardResumeMaker />} />
						<Route path='/editor' element={<PublicDashboardImageEditor />} />
						<Route path='/school_id' element={<PublicDashboardSchoolID />} />
						<Route path='/kundli' element={<PublicDashboardKundli />} />

						{
							states_paths.map((path) => {
								count++;
								return <Route key={count} path={`/all_cards/${path}`} element={<PublicDashboardCardsByState />} />
							})
						}


						{
							cards_paths.map((path) => {
								count++;
								return <Route key={count} path={`/${path}`} element={<PublicDashboardAllPaidCards />} />
							})
						}

						<Route path='*' element={<Navigate replace to="/u/index" />} />
					</Routes>

				</div>

			</div>
		</>
	);
}



export default PublicDashboardIndex;