import { useEffect } from 'react';


declare global {
    interface Window {
        adsbygoogle: {
            push: Function;
            loaded: boolean;
        };
    }
}


const AdsComponent = (props) => {
    const { dataAdSlot } = props;

    useEffect(() => {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
        catch (e) {
            console.log(e)
        }

    }, []);



    return (
        <>
            <ins className="adsbygoogle text-center"
                style={{ display: "block" }}
                data-ad-client="ca-pub-1094110746806525"
                data-ad-slot={dataAdSlot}
                data-ad-format="auto"
                data-adtest={import.meta.env.DEV? "on": "off"}
                data-full-width-responsive="true">
            </ins>
        </>
    );
};

export default AdsComponent;