import { Link } from "react-router-dom";

import Branding from "../../Branding/Branding";

function LogoImage() {
	const branding = Branding();
	return (
		<>
			<img className="light-mode-item navbar-brand-item" src={branding.logo} alt="logo" style={{ width: "100px", height: "auto" }} />
			<img className="dark-mode-item navbar-brand-item" src={branding.logo_light} alt="logo" style={{ width: "100px", height: "auto" }} />
		</>
	)
}


function BrandingLogo({ to }) {
	const branding = Branding();

	return (
		<Link className="navbar-brand d-flex align-items-center" to={to}>

			<div className="avatar avatar-lg me-2">
				<LogoImage />
			</div>

			<div className="d-none d-sm-block">
				<h4 className="navbar-brand-item mt-2">{branding.site_name}</h4>
			</div>

		</Link>
	);
}

export default BrandingLogo;

export { LogoImage }