import { NavLink } from "react-router-dom";
import AuthContext from "../../../../context/auth";
import { useContext } from "react";

import { useUser, useUserStats } from "../../../../api/basic";

function PublicDashboardSettingsSidebar() {
    const { user, logout, isLogin} = useContext(AuthContext);

    const userInfo = useUser(isLogin)

	const userstats = useUserStats(isLogin)


    
	function get_avatar_url() {
		if (userInfo.isLoading) {
			return ""
		}
		if (userInfo.data?.avatar) {
			return userInfo.data.avatar
		}
		if (userInfo.data?.profile) {
			return `https://cdn.statically.io/avatar/${userInfo.data.profile.full_name}`
		}
		return `https://cdn.statically.io/avatar/${user.full_name}`
	}


    return (
        <>
        {/* <!-- Sidebar START --> */}
        <div className="col-lg-4 col-xl-3">
            {/* <!-- Responsive offcanvas body START --> */}
            <div className="offcanvas-lg offcanvas-end" tabIndex={-1} id="offcanvasSidebar" >
                {/* <!-- Offcanvas header --> */}
                <div className="offcanvas-header justify-content-end pb-2">
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close"></button>
                </div>

                {/* <!-- Offcanvas body --> */}
                <div className="offcanvas-body p-3 p-lg-0">
                    <div className="card bg-light w-100">

                        {/* <!-- Edit profile button --> */}
                        <div className="position-absolute top-0 end-0 p-3">
                            <a href="account-profile.html#" className="text-primary-hover" data-bs-toggle="tooltip" data-bs-title="Edit profile">
                                <i className="bi bi-pencil-square"></i>
                            </a>
                        </div>

                        {/* <!-- Card body START --> */}
                        <div className="card-body p-3">
                            {/* <!-- Avatar and content --> */}
                            <div className="text-center mb-3">
                                {/* <!-- Avatar --> */}
                                <div className="avatar avatar-xl mb-2">
                                    <img className="avatar-img rounded-circle border border-2" src={userInfo.isLoading? "" : get_avatar_url() }  alt="profile" referrerPolicy="no-referrer"/>
                                </div>
                                <h6 className="mb-2">{ userInfo.isLoading? "-" : (userInfo.data?.profile? userInfo.data?.profile.full_name: userInfo.data?.full_name) }</h6>
                                <p className="small m-0">{user.email}</p>
							    <p className="small m-0">Balance: {userstats.isLoading? "": userstats.data?.user_balance} , User ID: {user.id}</p>
                                <hr/>
                            </div>

                            {/* <!-- Sidebar menu item START --> */}
                            <ul className="nav nav-pills-primary-soft flex-column">
                                <li className="nav-item active">
                                    <NavLink className="nav-link" end to=""><i className="bi bi-person fa-fw me-2"></i>My Profile</NavLink>
                                </li>
                                <li className="nav-item">
                                   <NavLink className="nav-link" end to="payments"><i className="bi bi-currency-rupee fa-fw me-2"></i>Payments</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" end to="transactions"><i className="bi bi-arrow-left-right fa-fw me-2"></i>Transactions</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" end to="security"><i className="bi bi-shield-check fa-fw me-2"></i>Security</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" end to="/u"><i className="bi bi-house-door fa-fw me-1"></i>Dashboard</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link text-danger bg-danger-soft-hover" to="/login" onClick={logout}><i className="fas fa-sign-out-alt fa-fw me-2"></i>Sign Out</NavLink>
                                </li>
                            </ul>
                            {/* <!-- Sidebar menu item END --> */}
                        </div>
                        {/* <!-- Card body END --> */}
                    </div>
                </div>
            </div>	
            {/* <!-- Responsive offcanvas body END -->	 */}
        </div>
        {/* <!-- Sidebar END --> */}
        </>
    )
}


export default PublicDashboardSettingsSidebar;