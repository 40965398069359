
import { useCallback, useRef } from 'react';


function useDebounce(callback, delay) {
    // create timer reference for setTimeout
    const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const debouncedCallback = useCallback((...args) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            callback(...args);
        }, delay);
    }, [callback, delay]);

    return debouncedCallback;
}

export default useDebounce;