

import PublicDashboardTitle from "../Components/Title";
import PublicDashboardPaymentGateway from "./Components/pg";
import PublicDashboardPaymentManually from "./Components/manually";
import PublicDashboardPaymentOther from "./Components/other";

function AdminMasterPlugin() {
    return (
        <>
            <PublicDashboardTitle title={"Add Money"} icon={"bi bi-bank fa-fw me-1"} addcardbtn={false} />

            <ul className="nav nav-tabs nav-bottom-line mb-3 d-flex justify-content-center">
                <li className="nav-item"> <a className="nav-link active" data-bs-toggle="tab" href="#tab-payment-gateway">Payment Gateway</a> </li>
                <li className="nav-item"> <a className="nav-link" data-bs-toggle="tab" href="#tab-qr-payment"> QR UPI </a> </li>
                <li className="nav-item"> <a className="nav-link" data-bs-toggle="tab" href="#tab-other"> Other </a> </li>
            </ul>
            <div className="tab-content mt-3">
                <div className="tab-pane show active" id="tab-payment-gateway">
                    <PublicDashboardPaymentGateway />
                </div>
                <div className="tab-pane" id="tab-qr-payment">
                    <PublicDashboardPaymentManually />
                </div>
                <div className="tab-pane" id="tab-other">
                    <PublicDashboardPaymentOther />
                </div>
            </div>
        </>
    )
}



export default AdminMasterPlugin;