import { useMutationAdminMakeGiftCard } from '../../../../api/admin';

function AdminCreateGiftcard() {
    const { isLoading, mutate, data } = useMutationAdminMakeGiftCard();

    function onSubmit(e: any) {
        e.preventDefault();

        mutate({
            amount: e.target.amount.value,
            description: e.target.description.value
        })
    }

    return (
        <div className="row">
            <div className="col-md-6 mx-auto">
                <div className="card border">
                    <div className="card-header border-bottom">
                        <h5 className="card-header-title"> Create Gift Card</h5>
                        <p className="mb-0 small">Create Gift Card for user manually.</p>
                    </div>
                    {/* <!-- Card body START --> */}
                    <form onSubmit={onSubmit}>
                        <div className="card-body">

                            <div className="mb-3">
                                <label className="form-label">Amount <span className="text-danger">*</span></label>
                                <input type="text" name="amount" className="form-control" placeholder="Amount" />
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Description <span className="text-danger">*</span></label>
                                <input type="text" name="description" className="form-control" placeholder="Description" />
                            </div>

                            {
                                data && (
                                    <div className="bg-light border border border-secondary border-dashed rounded-2 px-3 py-2 mt-4">
                                        <h6 className="mb-0 fw-normal user-select-all">{data.card_code}</h6>
                                    </div>
                                )
                            }

                        </div>


                        <div className="card-footer border-top">
                            <div className="text-center">
                                <button className="btn btn-primary fs-5">
                                    <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{ display: !isLoading ? 'None' : "" }}></span>
                                    <span role="status">Submit</span>
                                </button>
                            </div>
                        </div>



                    </form>
                    {/* <!-- Card body END --> */}
                </div>



            </div>
        </div>
    )
}


export default AdminCreateGiftcard;