import PublicDashboardTitle from "../Components/Title";

import {PublicDashboardIframeImage} from "../Components/PageElements";
import AdsComponent from "../../../../components/GoogleAds/GoogleAds";
import { useState } from "react";
import { useEffect } from "react";

const initFilter = {
    brightness: {
        selected: "brightness",
        value: 100,
        max: 200
    },
    saturation: {
        selected: "saturation",
        value: 100,
        max: 200
    },
    inversion: {
        selected: "inversion",
        value: 0,
        max: 100
    },
    grayscale: {
        selected: "grayscale",
        value: 0,
        max: 100
    },
    quality: {
        selected: "quality",
        value: 100,
        max: 100
    }
}

const initImgSettings = {
    rotate: 0,
    flipHorizontal: 1,
    flipVertical: 1,
    height: 0,
    width: 0
}

function PublicDashboardImageEditor() {
    const [img, setImg] = useState("");
    const [filters, setFilters] = useState(initFilter);
    const [currentFilter , setCurrentFilter] = useState("brightness");
    const [imgSettings, setImgSettings] = useState(initImgSettings)
    

    function onFilterBtnClick(e) {
        if (currentFilter === e.target.id) return;
        setCurrentFilter(e.target.id);
    }

    function flipRotate(e) {
        if (e.target.tagName == "I") {
            e = e.target.parentElement;
        }else {
            e = e.target;
        }
        
        if (e.id === "left") {
            setImgSettings({...imgSettings, rotate: imgSettings.rotate - 90});
        }
        else if (e.id === "right") {
            setImgSettings({...imgSettings, rotate: imgSettings.rotate + 90});
        }
        else if (e.id === "horizontal") {
            setImgSettings({...imgSettings, flipHorizontal: imgSettings.flipHorizontal === 1 ? -1 : 1});
        }
        else if (e.id === "vertical") {
            setImgSettings({...imgSettings, flipVertical: imgSettings.flipVertical === 1 ? -1 : 1});
        }
    }

    function reset() {
        setFilters(initFilter);
        setImgSettings(initImgSettings);
    }

    function chooseImage(e) {
        console.log(e.target.parentElement.children[0].click())
    }

    function loadImage(e) {
        reset();
        setImg(URL.createObjectURL(e.target.files[0]));
    }

    const saveImage = () => {
	    const canvas = document.createElement("canvas");
	    const ctx = canvas.getContext("2d");
        if (!ctx) return;

	    const rotate_pos = (imgSettings.rotate/90) % 2;

	    // new_width = 100
	    // new_height = 150

		const new_width = Number(imgSettings.width);
		const new_height = Number(imgSettings.height);

        const previewImg = document.querySelector<HTMLImageElement>(".card-body img");
        if (!previewImg) return;

	    if (!rotate_pos) {
	        canvas.width = new_width || previewImg.naturalWidth;
	        canvas.height = new_height || previewImg.naturalHeight;
	    } else {
	        canvas.height = new_width || previewImg.naturalWidth;
	        canvas.width = new_height || previewImg.naturalHeight;
	    }

	    console.log(canvas.width, canvas.height, filters)

	    ctx.filter = `brightness(${filters.brightness.value}%) saturate(${filters.saturation.value}%) invert(${filters.inversion.value}%) grayscale(${filters.grayscale.value}%)`;
	    ctx.translate(canvas.width / 2, canvas.height / 2);

	    ctx.rotate(imgSettings.rotate * Math.PI / 180); 

	    ctx.scale(imgSettings.flipHorizontal, imgSettings.flipVertical);

	    if (!rotate_pos) {
	        ctx.drawImage(previewImg, -canvas.width / 2, -canvas.height / 2, canvas.width, canvas.height);
	    } else {
	        ctx.drawImage(previewImg, -canvas.height / 2, -canvas.width / 2, canvas.height, canvas.width);
	    }

	    const link = document.createElement("a");
	    link.download = "edited.jpg";
	    link.href = canvas.toDataURL("image/jpeg", Number(filters.quality.value)/100);
	    link.click();
	}

    useEffect(() => {
        const previewImg = document.querySelector<HTMLImageElement>(".card-body img");
        if (previewImg) {
            previewImg.style.transform = `rotate(${imgSettings.rotate}deg) scale(${imgSettings.flipHorizontal}, ${imgSettings.flipVertical})`;
	        previewImg.style.filter = `brightness(${filters['brightness'].value}%) saturate(${filters["saturation"].value}%) invert(${filters["inversion"].value}%) grayscale(${filters["grayscale"].value}%)`;
        }

    }, [imgSettings, filters])


    return (
        <>
            <PublicDashboardTitle title={"Image Editor"} icon={"fa-solid fa-pen fa-fw me-1"} addcardbtn={false} />

            <div className="row g-4">


                <div className="col-md-6">
                    <div className="card border">
                        <div className="card-header align-items-center border-bottom p-4">

                            <h3 className="mb-0 text-center">Free Editor</h3>

                        </div>
                        {/* <!-- Card body START --> */}

                        <div className="card-body">
                            <div className="row g-4 text-center mb-3">
                                <div className="col d-flex">
                                    <button className="btn btn-primary" id="brightness" style={{flexGrow: 1}} onClick={onFilterBtnClick}>Brightness</button>
                                </div>
                                <div className="col d-flex">
                                    <button className="btn btn-primary" id="saturation" style={{flexGrow: 1}} onClick={onFilterBtnClick}>Saturation</button>
                                </div>
                                <div className="col d-flex">
                                    <button className="btn btn-primary" id="inversion" style={{flexGrow: 1}} onClick={onFilterBtnClick}>Inversion</button>
                                </div>
                                <div className="col d-flex">
                                    <button className="btn btn-primary" id="grayscale" style={{flexGrow: 1}} onClick={onFilterBtnClick}>Grayscale</button>
                                </div>
                                <div className="col d-flex">
                                    <button className="btn btn-primary" id="quality" style={{flexGrow: 1}} onClick={onFilterBtnClick}>Quality</button>
                                </div>
                            </div>

                            <div className="row g-4 mb-3"> 
                                <div className="col-12">
                                    <div className="d-flex justify-content-between">
                                        <p className="text-capitalize">{filters[currentFilter].selected}</p>
                                        <p>{filters[currentFilter].value}%</p>
                                    </div>
                                    <input type="range" className="form-range" value={filters[currentFilter].value} onChange={e => setFilters({...filters, [currentFilter]: {...filters[currentFilter], value: Number(e.target.value)}})} min={0} max={filters[currentFilter].max}/>
                                </div>
                            </div>

                            <div className="row g-4 mb-3">
                                <div className="col-3 d-flex">
                                <button id="left" className="btn btn-primary" style={{flexGrow: 1}} onClick={flipRotate}><i className="fa-solid fa-rotate-left"></i></button>
                                </div>
                                <div className="col-3 d-flex">
                                <button id="right" className="btn btn-primary" style={{flexGrow: 1}} onClick={flipRotate}><i className="fa-solid fa-rotate-right"></i></button>
                                </div>
                                <div className="col-3 d-flex">  
                                <button id="horizontal" className="btn btn-primary" style={{flexGrow: 1}} onClick={flipRotate}><i className="bi bi-symmetry-vertical"></i></button>
                                </div>
                                <div className="col-3 d-flex">
                                <button id="vertical" className="btn btn-primary" style={{flexGrow: 1}} onClick={flipRotate}><i className="bi bi-symmetry-horizontal"></i></button>
                                </div>
                            </div>

                            <div className="row g-4 mb-3">
                                <div className="col mb-3">
                                    <label className="form-label">Width</label>
                                    <input type="number"  placeholder="Width" onChange={e => setImgSettings({...imgSettings, width: Number(e.target.value)})} className="form-control" required/>
                                </div>
                                <div className="col mb-3">
                                    <label className="form-label">Height</label>
                                    <input type="number"  placeholder="Height" onChange={e => setImgSettings({...imgSettings, height: Number(e.target.value)})} className="form-control" required/>
                                </div>
                            </div>
                        </div>

                        <div className="card-footer border-top">
                            <div className="row g-4 mb-3">
                                <div className="col d-flex">
                                    <button className="btn btn-primary" id="quality" style={{flexGrow: 1}} onClick={reset}>Reset</button>
                                </div>
                                <div className="col d-flex">
                                    <input type="file" accept="image/*" className="form-control" onChange={loadImage} hidden/>
                                    <button className="btn btn-primary" id="quality" style={{flexGrow: 1}} onClick={chooseImage}>Choose Image</button>
                                </div>
                            </div>
                        </div>



                        {/* <!-- Card body END --> */}
                    </div>
                </div>

                <PublicDashboardIframeImage title="Image" description="Processed image after edit will be visible here." src={img || undefined} onClickFn={saveImage}/>


                <div className="col-12">
                    <AdsComponent dataAdSlot="6845492561" />
                </div>

            </div>

        </>
    )
}

export default PublicDashboardImageEditor;