function PageMakerFunc(canvas: HTMLCanvasElement, form: HTMLFormElement) {
	const ctx = canvas.getContext("2d");

    if (!ctx) return console.log("CTX is null");

	const border = 'true' == form.border.value;

    const layout = {
        width: form.width.value,
        height: form.height.value,
        topMargin: form.topMargin.value,
        cardXSpacing: form.cardXSpacing.value,
        cardYSpacing: form.cardYSpacing.value,
        pagesize: form.pagesize.value
    }

    console.log(layout);

    const pageSizes = {
        "A4": { height: 3508, width: 2480, cardOnY: 5, cardOnX: 2 },
        "A6": { height: 1748, width: 1240, cardOnY: 2, cardOnX: 1 },
    }

    console.log(pageSizes[layout.pagesize]);

    canvas.height = pageSizes[layout.pagesize].height;
	canvas.width = pageSizes[layout.pagesize].width;

    const cardOnY = pageSizes[layout.pagesize].cardOnY;
    const cardOnX = pageSizes[layout.pagesize].cardOnX;


    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // increase card width and height by percentage
    const CARD_WIDTH = 1016 + (1016 * layout.width/100);
    const CARD_HEIGHT = 638 + (638 * layout.height/100);

    var a = Array<Element | HTMLInputElement>(...form.querySelectorAll('input[type="file"]'));
	var files: [Element, Element][] = []
	while (a.length > 0){
        files.push([a.shift() as Element, a.shift() as Element]);
	}

    const BETWEEN_CARD_X = 10 + (10 * layout.cardXSpacing);
    const BETWEEN_CARD_Y = 15 + (15 * layout.cardYSpacing);

    let TOP_PADDING = ((canvas.height - (CARD_HEIGHT*cardOnY + BETWEEN_CARD_Y*(cardOnY-1)))/2); // sub space between cards - half
    const SIDE_PADDING = ((canvas.width - (CARD_WIDTH*cardOnX + BETWEEN_CARD_X*(cardOnX-1)))/2); // sub space between cards - half

    TOP_PADDING = Math.min(TOP_PADDING, TOP_PADDING-(layout.topMargin * 15 ) );

    console.log("TOP_PADDING", TOP_PADDING);
    console.log("SIDE_PADDING", SIDE_PADDING);

    var pos = 0;

    for (const [front, back] of files) {
        if (front instanceof HTMLInputElement && front.files?.length) {
            const start_from = TOP_PADDING + (pos * (CARD_HEIGHT + BETWEEN_CARD_Y));

            let img_img = new Image();
            img_img.src = URL.createObjectURL(front.files[0]);
            img_img.onload = function() {
                ctx.drawImage(img_img, SIDE_PADDING, start_from, CARD_WIDTH, CARD_HEIGHT);
                if (border) {
                    ctx.strokeRect(SIDE_PADDING, start_from, CARD_WIDTH, CARD_HEIGHT);
                }
            }
        }
        if (cardOnX == 2) {
            if (back instanceof HTMLInputElement && back.files?.length) {
                const start_from = TOP_PADDING + (pos * (CARD_HEIGHT + BETWEEN_CARD_Y));
                let img_img = new Image();
                img_img.src = URL.createObjectURL(back.files[0]);
                img_img.onload = function() {
                    ctx.drawImage(img_img, SIDE_PADDING + CARD_WIDTH + BETWEEN_CARD_X, start_from, CARD_WIDTH, CARD_HEIGHT);
                    if (border) {
                        ctx.strokeRect(SIDE_PADDING + CARD_WIDTH + BETWEEN_CARD_X, start_from, CARD_WIDTH, CARD_HEIGHT);
                    }
                }
            }
        }

        if (cardOnX == 1) {
            if (back instanceof HTMLInputElement && back.files?.length) {
                pos += 1;
                const start_from = TOP_PADDING + (pos * (CARD_HEIGHT + BETWEEN_CARD_Y));

                let img_img = new Image();
                img_img.src = URL.createObjectURL(back.files[0]);
                img_img.onload = function() {
                    ctx.drawImage(img_img, SIDE_PADDING, start_from, CARD_WIDTH, CARD_HEIGHT);
                    if (border) {
                        ctx.strokeRect(SIDE_PADDING, start_from, CARD_WIDTH, CARD_HEIGHT);
                    }
                }
            }
        }

        pos += 1;
    }

    return true;

}


export default PageMakerFunc;