
import React, { useRef } from 'react';
import { useMutationAdminUserStatus } from '../../../../api/admin';

function AdminAccountStatus() {
    const formRef = useRef() as React.MutableRefObject<HTMLFormElement>;

    const { mutate, isLoading } = useMutationAdminUserStatus();

    function trigger_mutate() {
        if (formRef.current["user_id"] && formRef.current["user_id"].value.length > 0 &&
        formRef.current["status"] && formRef.current["status"].value.length > 0) {
                console.log("trigger_mutate", formRef.current);
                mutate({
                    user_id: formRef.current["user_id"].value,
                    status: formRef.current["status"].value,
                })
        }
    }


    return (
        <div className="row">
            <div className="col-md-6 mx-auto">
                <div className="card border">
                    <div className="card-header border-bottom">
                        <h5 className="card-header-title">Account Status</h5>
                        <p className="mb-0 small">Suspend/Unsuspend any account.</p>
                    </div>
                    {/* <!-- Card body START --> */}
                    <form ref={formRef}>
                        <div className="card-body">

                            <div className="mb-3">
                                <label className="form-label">User ID <span className="text-danger">*</span></label>
                                <input type="text" name="user_id" className="form-control" placeholder="User ID"/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Suspend Account <span className="text-danger">*</span></label>
                                <select className="form-select js-choice" name="status" required>
                                    <option value="false">Suspend</option>
                                    <option value="true">Unsuspend</option>
                                </select>
                            </div>

                        </div>


                        <div className="card-footer border-top">
                            <div className="text-center">
                                <button className="btn btn-primary fs-5" type="button" onClick={trigger_mutate}>
                                    <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{display: !isLoading? 'None': ""}}></span>
                                    <span role="status">Update</span>
                                </button>
                            </div>
                        </div>
                        
                    </form>
                    {/* <!-- Card body END --> */}
                </div>
            </div>
        </div>
    )
}

export default AdminAccountStatus;