import PublicDashboardTitle from "../Components/Title";
import { useEffect, useRef, useState } from "react";
import {PublicDashboardIframeCanvas} from "../Components/PageElements";

import schoolIdUpdate, {typeInputData} from "../../../../helper/SchoolID";

import {useMutationUserCustomTxn} from "../../../../api/customtxn";
import CustomAlert from "../../../../helper/Alerts";
import AdsComponent from "../../../../components/GoogleAds/GoogleAds";

const CDN_URL = import.meta.env.VITE_CDN_URL +  "idmaker/student_id/template";



const defaultCardFields = [
    ["Key 1", "Value 1"],
    ["Key 2", "Value 2"],
    ["Key 3", "Value 3"],
    ["Key 4", "Value 4"],
    ["Key 5", "Value 5"],
    ["Key 6", "Value 6"],
]

const schoolCardFields = {
    "id": [
        ["Photo", "Logo", "Sign"],
        ["School Name", "School Sub Title"],
        ["Name", "Father", "Mother", "Class", "DOB", "Roll No"], 
        ["Address"], 
        ["Phone", "Session", "Addmission No"],
        ["School Contact"], 
        ...defaultCardFields
    ],
    "li": [
        ["Photo"],
        ["Name", "Division", "Branch", "Code Number", "DOB", "Branch Manager"], 
        ["Branch Address"], 
        ["Phone", "Session"],
        ...defaultCardFields
    ],
    "be": [
        ["Photo", "Logo", "Sign", "Auth Sign"],
        ["Name", "Father"], 
        ["Address"], 
        ["Phone", "Session", "Addmission No"],
        ...defaultCardFields
    ]
}

function getNameForInput(name: string): string {
    return name.toLocaleLowerCase().split(" ").join("_");
}

function classForLength(l: number): string {
    switch(l) {
        case 1:
        return "mb-3 col"
        case 2:
        return 'mb-3 col-md-6 col-6';
        default:
        return 'mb-3 col-md-4 col-sm-6 col-6';
    }
}

const INIT_SETTINGS = {
    template: import.meta.env.VITE_CDN_URL +  "idmaker/student_id/template/id002-a.png",
    template_id: "id002-a",
    header_color: "#6F6F6F",
    data_color: "#6F6F6F",
    footer_color: "#6F6F6F",
    principal_sign_placeholder: "Principal Sign",
}

function PublicDashboardSchoolID() {
    const [currentTemplateSettings , setCurrentTemplateSettings] = useState(schoolCardFields["id"]);
    const [settings, setSettings] = useState<typeInputData>(INIT_SETTINGS as typeInputData);
    const [imagePurchased, setImagePurchased] = useState(false);
    const canvasRef = useRef() as React.MutableRefObject<HTMLCanvasElement>;

    const { mutateAsync } = useMutationUserCustomTxn();


    async function downloadImgFn() {
        if (!imagePurchased){
            const toastId = CustomAlert.newLoading("Processing please wait.", "Do not close the browser or refresh the page.")
            const { success } = await mutateAsync({
                service_name: "school_id",
                card_id: "",
                card_holder_name: settings.name || ""
            })
            if (success) {
                setImagePurchased(() => true);
                CustomAlert.success("School ID", "School ID Downloaded", {id: toastId})
            } else {
                CustomAlert.error("School ID", "Something went wrong", {id: toastId})
            }
            return success
        } else {
            return true;
        }
    }

    function onTemplateChange(e) {
        const template = e.target.value;
        const template_id = template.split("/").pop().split(".")[0];

        if (template_id === settings.template_id) {
            setSettings({...settings, template: template});
        } else {
            setSettings({...INIT_SETTINGS as typeInputData, template: template, template_id: template_id});
        }
        setCurrentTemplateSettings(schoolCardFields[template_id.slice(0,2)]);
    }

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        setImagePurchased(() => false);
        if (e.target.name === "template") return;
        if (e.target.type === "file" && e.target.files) {
            const name = e.target.name;
            const file = e.target.files[0];
            setSettings({...settings, [name]: file});
            return
        }
        const name = e.target.name;
        const value = e.target.value;
        setSettings({...settings, [name]: value});
    }

    useEffect(() => {
        schoolIdUpdate(canvasRef.current, settings as typeInputData);
    }, [settings])

    return (
        <>
            <PublicDashboardTitle title={"Identity Cards"} icon={"bi bi-person-badge fa-fw me-1"} addcardbtn={false} />

            <div className="row g-4">
                <div className="col-md-6">
                    <div className="card border">
                        <div className="card-header align-items-center border-bottom p-4">

                            <h4 className="mb-0 text-center">Designer</h4>

                        </div>
                        {/* <!-- Card body START --> */}
                        <div className="card-body" onChange={onChange}>

                            <div className="row mb-3">
                                <div className="col-12">
                                    <label className="form-label">Templates</label>
                                    <select className="form-select" id="template" name="template" onChange={onTemplateChange} defaultValue={settings.template}>
                                        <option value={`${CDN_URL}/id002-a.png`}>School 1 - Blue</option>
                                        <option value={`${CDN_URL}/id002-b.png`}>School 1 - Red</option>
                                        <option value={`${CDN_URL}/id002-c.png`}>School 1 - Green</option>
                                        <option value={`${CDN_URL}/id002-d.png`}>School 1 - Orange</option>
                                        <option value={`${CDN_URL}/id002-e.png`}>School 1 - Yellow</option>

                                        <option value={`${CDN_URL}/id003-a.png`}>School 2 - Blue</option>
                                        <option value={`${CDN_URL}/id003-b.png`}>School 2 - Red</option>
                                        <option value={`${CDN_URL}/id003-c.png`}>School 2 - Green</option>
                                        <option value={`${CDN_URL}/id003-d.png`}>School 2 - Orange</option>
                                        <option value={`${CDN_URL}/id003-e.png`}>School 2 - Yellow</option>

                                        <option value={`${CDN_URL}/id004-a.png`}>School 3 - Blue</option>
                                        <option value={`${CDN_URL}/id004-b.png`}>School 3 - Red</option>
                                        <option value={`${CDN_URL}/id004-c.png`}>School 3 - Green</option>
                                        <option value={`${CDN_URL}/id004-d.png`}>School 3 - Orange</option>
                                        <option value={`${CDN_URL}/id004-e.png`}>School 3 - Yellow</option>

                                        <option value={`${CDN_URL}/id005-a.png`}>School 4 - Blue</option>
                                        <option value={`${CDN_URL}/id005-b.png`}>School 4 - Red</option>
                                        <option value={`${CDN_URL}/id005-c.png`}>School 4 - Green</option>
                                        <option value={`${CDN_URL}/id005-d.png`}>School 4 - Orange</option>
                                        <option value={`${CDN_URL}/id005-e.png`}>School 4 - Yellow</option>

                                        <option value={`${CDN_URL}/id007-a.png`}>School 5 - Blue</option>
                                        <option value={`${CDN_URL}/id007-b.png`}>School 5 - Red</option>
                                        <option value={`${CDN_URL}/id007-c.png`}>School 5 - Green</option>
                                        <option value={`${CDN_URL}/id007-d.png`}>School 5 - Orange</option>
                                        <option value={`${CDN_URL}/id007-e.png`}>School 5 - Yellow</option>

                                        <option value={`${CDN_URL}/id008-a.png`}>School 6 - Blue</option>
                                        <option value={`${CDN_URL}/id008-b.png`}>School 6 - Red</option>
                                        <option value={`${CDN_URL}/id008-c.png`}>School 6 - Green</option>
                                        <option value={`${CDN_URL}/id008-d.png`}>School 6 - Orange</option>
                                        <option value={`${CDN_URL}/id008-e.png`}>School 6 - Yellow</option>

                                        <option value={`${CDN_URL}/id009-a.png`}>School 7 - Blue</option>
                                        <option value={`${CDN_URL}/id009-b.png`}>School 7 - Red</option>
                                        <option value={`${CDN_URL}/id009-c.png`}>School 7 - Green</option>
                                        <option value={`${CDN_URL}/id009-d.png`}>School 7 - Orange</option>
                                        <option value={`${CDN_URL}/id009-e.png`}>School 7 - Yellow</option>

                                        {/* <option value={`${CDN_URL}/id010-a.png`}>Pension Card - Blue</option>
                                        <option value={`${CDN_URL}/id010-b.png`}>Pension Card - Red</option>
                                        <option value={`${CDN_URL}/id010-c.png`}>Pension Card - Green</option>
                                        <option value={`${CDN_URL}/id010-d.png`}>Pension Card - Orange</option>
                                        <option value={`${CDN_URL}/id010-e.png`}>Pension Card - Yellow</option>

                                        <option value={`${CDN_URL}/be001-a.png`}>Bengal Nigam</option> */}

                                        {/* <option value={`${CDN_URL}/li001.png`}>LIC 1</option> */}


                                    </select>
                                </div>
                            </div>

                            <div className="row mb-3 border-bottom">

                                <div className="mb-3 col-4">
                                    <label className="form-label">Header</label>
                                    <input type="color" name="header_color" defaultValue="#6F6F6F" className="form-control" style={{height: "45px"}} />
                                </div>
                                <div className="mb-3 col-4">
                                    <label className="form-label">Data</label>
                                    <input type="color" name="data_color" defaultValue="#6F6F6F" className="form-control" style={{height: "45px"}}/>
                                </div>
                                <div className="mb-3 col-4">
                                    <label className="form-label">Footer</label>
                                    <input type="color" name="footer_color" defaultValue="#6F6F6F" className="form-control" style={{height: "45px"}}/>
                                </div>
                            </div>

                            <div className="row mb-3 border-bottom">
                                {/* input type file */}
                                {
                                    currentTemplateSettings[0].map((item, index) => {
                                        return (
                                            <div className={currentTemplateSettings[0].length == 1? "mb-3 col" : "mb-3 col-md-6"} key={index}>
                                                <label className="form-label">{item}</label>
                                                <input type="file" name={getNameForInput(item)} className="form-control" required/>
                                            </div>
                                        )
                                    })
                                }
                                <div className="mb-3 col-md-6">
                                    <label className="form-label">Principal Signature Name</label>
                                    <input type="text" name="principal_sign_placeholder" 
                                    className="form-control" placeholder="Principal Signature Name" defaultValue={settings.principal_sign_placeholder || ""}/>
                                </div>
                            </div>


                            {
                                currentTemplateSettings.slice(1, currentTemplateSettings.length).map((item, index) => {
                                    return (
                                        <div className="row" key={index}>
                                            {
                                                item.map((subitem, subIndex, arr) => {
                                                    return (
                                                        <div className={classForLength(arr.length)} key={subIndex}>
                                                            <label className="form-label">{subitem}</label>
                                                            <input type="text" name={getNameForInput(subitem)} placeholder={subitem} className="form-control"/>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }


                        </div>
                        {/* <!-- Card body END --> */}
                    </div>
                </div>

                <PublicDashboardIframeCanvas title="Card Preview" description="" innerRef={canvasRef} beforeDownloadFn={downloadImgFn}/>

                <div className="col-12">
                    <AdsComponent dataAdSlot="5824067931" />
                </div>

            </div>

        </>
    )
}

export default PublicDashboardSchoolID;