import { useMutation, useQuery } from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import axiosClient from "../hooks/useAxios";
import { handleErrors } from "./handleErrors";
import { server4XXError } from "./types";
import CustomAlert from "../helper/Alerts";

type typeUserSessionPayload = {
    session_id: string,
}

type typeSuccessResponse = {
    success: boolean,
}

const mutationUserSessionDrop = async (payload: typeUserSessionPayload): Promise<typeSuccessResponse> => {
    const response = await axiosClient.post<typeUserSessionPayload, AxiosResponse<typeSuccessResponse>>("/drop_session", payload);
    return response.data;
}

const useMutationUserSessionDrop = () => {
    return useMutation<typeSuccessResponse, AxiosError<server4XXError>, typeUserSessionPayload>({
        mutationKey: ["userSessionsDrop"],
        mutationFn: mutationUserSessionDrop,
        onError: handleErrors,
        onSuccess: (data) => {
            if (data.success) {
                CustomAlert.success("success", "Session has been dropped successfully");
            }
        }
    })
}


type typeUserSessionResponse = {
    id: string,
    ip_address: string,
    useragent: string,
    updated_at: string,
}


const getUsersAllSession = async (): Promise<typeUserSessionResponse[]> => {
    const response = await axiosClient.post<typeUserSessionResponse[], AxiosResponse<typeUserSessionResponse[]>>("/sessions", null);
    return response.data;
};

const useUserSession = () => {
    return useQuery<typeUserSessionResponse[], AxiosError<server4XXError>>({
        queryKey: ["userSessions"],
        queryFn: getUsersAllSession,
        onError: handleErrors,
        refetchOnWindowFocus: false,
    });
};



export { useMutationUserSessionDrop, useUserSession}