
import { useMutationAdminGetUsers } from '../../../../api/admin';
import UsersTable from '../../Components/UsersTable';



function AdminPhoneSearch() {

    const { data, isLoading, mutate } = useMutationAdminGetUsers();

    function onSubmit(e) {
        e.preventDefault();
        
        console.log(e.target.phone ,"target")

        if (e.target.phone && e.target.phone.value !== "") {
            mutate({
                phone: e.target.phone.value,
            });
            return;
        }
        if (e.target.ip && e.target.ip.value !== "") {
            mutate({
                ip: e.target.ip.value,
            });
            return;
        }
    }

    return (
    <>
        <div className="row mb-4">
            <div className="col-md-6 mx-auto">
                <div className="card border">
                    <div className="card-header border-bottom">
                        <h5 className="card-header-title">Search user by phone or ip.</h5>
                        <p className="mb-0 small">Get all accounts with phone number or ip.</p>
                    </div>
                    {/* <!-- Card body START --> */}
                    <form onSubmit={onSubmit}>
                        <div className="card-body">

                            <div className="mb-3">
                                <label className="form-label">Phone Number <span className="text-danger">*</span></label>
                                <input type="text" name="phone" className="form-control" placeholder="Phone Number"/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">IP Address <span className="text-danger">*</span></label>
                                <input type="text" name="ip" className="form-control" placeholder="IP Address"/>
                            </div>

                        </div>
                        <div className="card-footer border-top">
                            <div className="text-center d-flex justify-content-around">
                                <button className="btn btn-primary fs-5">
                                    <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{display: !isLoading? 'None': ""}}></span>
                                    <span role="status">Check</span>
                                </button>
                                {/* <button className="btn btn-primary fs-5" type="button" onClick={()=>{refetch()}}>
                                    <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{display: !isRefetching? 'None': ""}}></span>
                                    <span role="status">Refresh</span>
                                </button> */}
                            </div>
                        </div>
                        
                    </form>
                    {/* <!-- Card body END --> */}
                </div>
            </div>
        </div>

        {
            data && <UsersTable data={data} title="Users Found"/>
        }
    </ >
    )
}


export default AdminPhoneSearch;