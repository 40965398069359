import { Link } from "react-router-dom";

function PublicRefund() {
    return (
        <section className="pt-4 pt-lg-5">
            <div className="container">
                {/* Title */}
                <div className="row">
                    <div className="col-12 text-center mb-4">
                        <h6>REFUND AND CANCELLATION POLICY</h6>
                        <h1 className="fs-2 mb-0">Refund and Cancellation Policy</h1>
                        <p>Last updated: June 22, 2023</p>
                    </div>
                </div>

                {/* Content START */}
                <div className="row">
                    <div className="col-md-10 mx-auto">
                        <div className="vstack gap-3">

                            <p>Our priority is your complete satisfaction. If for any reason our services fail to meet your expectations, we offer a 100% refund without any questions asked.</p>

                            <h5>Cancellation Policy</h5>
                            <p>If you wish to cancel a service, please contact us via email at: <Link className="text-muted text-primary-hover" to="mailto:support@idcard.store">support@idcard.store</Link>. We will endeavor to address your concerns within 48 hours.</p>

                            <h5>Refund Policy</h5>
                            <p>We stand by the quality of our services. In the rare event of service failure, we guarantee a 100% refund with no questions asked.</p>
                            <p>For payments made by credit card, refunds will be credited back to the original card used for the purchase. Refunds for payments made through a payment gateway will be issued to the same account.</p>
                            <p><strong>Please Note:</strong> Refunds are subject to verification of account and service usage. Refunds will not be provided for policy violations or if attempts of deceit or scamming are detected.</p>
                            <p><strong>Refund Timeline:</strong> Refunds will be processed within 24 to 48 hours of the request. The amount will reflect in same mode of payment within 5 to 7 working days sometimes even earlier depending on the bank.</p>

                            <h5>Customer Support Availability</h5>
                            <p>We are committed to addressing your inquiries promptly. Our customer support team is available via email at <Link to="mailto:support@idcard.store" className="text-muted text-primary-hover">support@idcard.store</Link>, and we aim to respond to all queries within 24 working hours.</p>

                            <h5>Your Privacy</h5>
                            <p>Learn more about our commitment to your privacy in our <Link to="/privacy" className="text-muted text-primary-hover">Privacy Policy</Link>.</p>

                            <h5>Your Terms of Service</h5>
                            <p>Review our <Link to="/terms" className="text-muted text-primary-hover">Terms of Service</Link> for a detailed understanding of our service agreements.</p>

                        </div>

                    </div>
                </div>
                {/* Content END */}
            </div>
        </section>
    );
}


export default PublicRefund;