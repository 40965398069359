
import { useEffect, useState } from 'react';
import { FormatDate } from '../../helper/Time';
import { typeUserTransaction, typeUserPayment, typeRefUserPayment, typeUserCardLog} from '../../api/types';




function TransactionTable({ data }: { data: typeUserTransaction[] }) {
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({
        perPage: 5,
        maxPage: 0,
        startIndex: 0,
        endIndex: 5,
        total: 0
    });

    useEffect(() => {
        if (data) {
            setPagination({
                ...pagination,
                maxPage: Math.ceil(data.length / pagination.perPage),
                total: data.length,
                startIndex: (page - 1) * pagination.perPage,
                endIndex: page * pagination.perPage
            })
        }
    }, [data, page])

    return (
        <div className="card shadow mb-5">
            {/* <!-- Card header --> */}
            <div className="card-header border-bottom">
                <h5 className="card-title mb-0">Transaction History</h5>
            </div>

            {/* <!-- Card body START --> */}
            <div className="card-body">
                {/* <!-- Table head --> */}
                <div className="bg-light rounded p-3 d-none d-sm-block">
                    <div className="row row-cols-7 g-4">
                        {/* <div className="col"><h6 className="mb-0">Card Type</h6></div> */}
                        <div className="col"><h6 className="mb-0">Ref ID</h6></div>
                        <div className="col"><h6 className="mb-0">User ID</h6></div>
                        <div className="col-3"><h6 className="mb-0">Description</h6></div>
                        <div className="col"><h6 className="mb-0">Credit</h6></div>
                        <div className="col"><h6 className="mb-0">Debit</h6></div>
                        <div className="col"><h6 className="mb-0">Amount</h6></div>
                        <div className="col"><h6 className="mb-0">Date</h6></div>
                        {/* <div className="col"><h6 className="mb-0">Files</h6></div> */}
                    </div>
                </div>

                {/* <!-- Table data --> */}
                {
                    data?.length ?
                        (
                            data.slice(pagination.startIndex, pagination.endIndex).map((item, index) => {
                                return (
                                    <div key={index} className="row row-cols-xl-7 g-4 align-items-sm-center border-bottom px-2 py-3">
                                        {/* <!-- Data item --> */}
                                        {/* <div className="col">
                                            <small className="d-block d-sm-none">Card:</small>
                                            <h6 className="fw-light mb-0 text-capitalize"></h6>
                                        </div> */}

                                        {/* <!-- Data item --> */}
                                        <div className="col d-none d-sm-block">
                                            <small className="d-block d-sm-none">Ref ID:</small>
                                            <h6 className="mb-0 fw-normal">#{item.id}</h6>
                                        </div>
                                        <div className="col d-none d-sm-block">
                                            <small className="d-block d-sm-none">User ID:</small>
                                            <h6 className="mb-0 fw-normal">{item.user_id}</h6>
                                        </div>
                                        {/* <!-- Data item --> */}
                                        <div className="col-3">
                                            <small className="d-block d-sm-none">Description:</small>
                                            <h6 className="fw-light mb-0 text-capitalize">{
                                                item.transaction_type === "credit" ? ((item.participant_id)? `Credited By User ${item.participant_id}`: 
                                                "Credited By PG"): ((item.participant_id)? `Debited To User ${item.participant_id}`: 
                                                `${item.transaction_type} of ${item.amount}`)
                                            }</h6>
                                        </div>

                                        {/* <!-- Data item --> */}
                                        <div className="col d-none d-sm-block">
                                            <small className="d-block d-sm-none">Credit:</small>
                                            <h6 className="fw-light mb-0">{
                                                item.transaction_type !== "debit" ? `+${item.amount}`: ``
                                            }</h6>
                                        </div>

                                        {/* <!-- Data item --> */}
                                        <div className="col d-none d-sm-block">
                                            <small className="d-block d-sm-none">Debit:</small>
                                            <h6 className="fw-light mb-0 text-capitalize">{
                                                item.transaction_type === "debit" ? `-${item.amount}`: ``
                                            }</h6>
                                        </div>

                                        <div className="col">
                                            <small className="d-block d-sm-none">Amount:</small>
                                            <h6 className="fw-light mb-0 text-capitalize">{
                                                item.total_amount? item.total_amount: "NA"
                                            }</h6>
                                        </div>

                                        {/* <!-- Data item --> */}
                                        <div className="col">
                                            <small className="d-block d-sm-none">Date:</small>
                                            <h6 className="fw-light mb-0">{FormatDate(item.created_at)}</h6>
                                        </div>

                                        {/* <!-- Data item --> */}
                                        {/* <div className="col">
                                            <small className="d-block d-sm-none">File:</small>
                                            <h6 className="fw-light mb-0">
                                                <div className="row g-2">
                                                    
                                                    {
                                                        Object.entries(item.files).map(([key, value], index) => {
                                                            return (
                                                                <div key={index} className="col-auto">
                                                                    <a href={value} target="_blank" rel="noreferrer" className="badge text-bg-primary">{key}</a>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    
                                                </div>
                                            </h6>
                                        </div> */}
                                    </div>
                                )
                            })
                        ) :

                        (
                            <div className="row align-items-center px-2 pt-4">
                                <p className="text-center">No Data Found</p>
                            </div>
                        )
                }


            </div>
            {/* <!-- Card body END --> */}


            <div className="card-footer pt-0">
                {/* <!-- Pagination and content --> */}
                <div className="d-sm-flex justify-content-sm-between align-items-sm-center">
                    {/* <!-- Content --> */}
                    <p className="mb-sm-0 text-center text-sm-start">Showing {pagination.startIndex + 1} to {pagination.endIndex} of {pagination.total} entries</p>
                    {/* <!-- Pagination --> */}
                    <nav className="mb-sm-0 d-flex justify-content-center" aria-label="navigation">
                        <ul className="pagination pagination-sm pagination-primary-soft mb-0">

                            <li className="page-item">
                                <button className="btn btn-outline-primary btn-sm" onClick={() => setPage((e) => e - 1)} disabled={page === 1}><i className="fas fa-angle-left ms-2" /> Prev</button>
                            </li>
                            <li className="page-item">
                                <button className="btn btn-outline-primary btn-sm" onClick={() => setPage((e) => e + 1)} disabled={page === pagination.maxPage}>Next <i className="fas fa-angle-right ms-2" /></button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

        </div>

    );

}

function PaymentsTable({data}: {data: typeUserPayment[]}) {
    const [page, setPage] = useState(1);
    const [pagination , setPagination] = useState({
        perPage: 5,
        maxPage: 0,
        startIndex: 0,
        endIndex: 5,
        total: 0
    });

    useEffect(() => {
        if (data) {
            setPagination({
                ...pagination,
                maxPage: Math.ceil(data.length / pagination.perPage),
                total: data.length,
                startIndex: (page - 1) * pagination.perPage,
                endIndex: page * pagination.perPage
            })
        }
    }, [data, page])

    return (
        <div className="card shadow mb-5">
            {/* <!-- Card header --> */}
            <div className="card-header border-bottom">
                <h5 className="card-title mb-0">Payment History</h5>
            </div>

            {/* <!-- Card body START --> */}
            <div className="card-body">
                {/* <!-- Table head --> */}
                <div className="bg-light rounded p-3 d-none d-sm-block">
                    <div className="row row-cols-7 g-4">
                        <div className="col col-md-4"><h6 className="mb-0">Order ID</h6></div>
                        <div className="col"><h6 className="mb-0">Txn ID</h6></div>
                        <div className="col col-md-2"><h6 className="mb-0">Amount</h6></div>
                        <div className="col"><h6 className="mb-0">Date</h6></div>
                        <div className="col"><h6 className="mb-0">Payment Slip</h6></div>
                    </div>
                </div>

                {/* <!-- Table data --> */}
                {
                    data?.length? 
                    (
                        data.slice(pagination.startIndex, pagination.endIndex).map((item, index) => {
                            return (
                                <div key={index} className="row row-cols-xl-7 g-4 align-items-sm-center border-bottom px-2 py-3">
                                    {/* <!-- Data item --> */}
                                    <div className="col col-md-4">
                                        <small className="d-block d-sm-none">Order:</small>
                                        <h6 className="fw-light mb-0">{item.order_id}</h6>
                                    </div>	

                                    {/* <!-- Data item --> */}
                                    <div className="col d-none d-sm-block">
                                        <small className="d-block d-sm-none">ID:</small>
                                        <h6 className="mb-0 fw-normal">#{item.bank_txn_id}</h6>
                                    </div>

                                    {/* <!-- Data item --> */}
                                    <div className="col col-md-2">
                                        <small className="d-block d-sm-none">Amount:</small>
                                        <h6 className="fw-light mb-0">{item.amount}</h6>
                                    </div>

                                    {/* <!-- Data item --> */}
                                    <div className="col">
                                        <small className="d-block d-sm-none">Date:</small>
                                        <h6 className="fw-light mb-0">{FormatDate(item.created_at)}</h6>
                                    </div>

                                    <div className="col">
                                        <small className="d-block d-sm-none">Pay Slip:</small>
                                        <h6 className="text-center fw-light mb-0">
                                            <a className="btn btn-sm btn-outline-primary" href={"/payment_slip/"+item.unique_id} target="_blank" rel="noreferrer">
                                                View
                                            </a>
                                        </h6>
                                    </div>
                                </div>
                            )
                        })
                    ):
                    (
                        <div className="row align-items-center px-2 pt-4">
                            <p className="text-center">No Data Found</p>
                        </div>
                    )
                }


            </div>
            {/* <!-- Card body END --> */}


            <div className="card-footer pt-0">
                {/* <!-- Pagination and content --> */}
                <div className="d-sm-flex justify-content-sm-between align-items-sm-center">
                    {/* <!-- Content --> */}
                    <p className="mb-sm-0 text-center text-sm-start">Showing {pagination.startIndex + 1 } to {pagination.endIndex} of {pagination.total} entries</p>
                    {/* <!-- Pagination --> */}
                    <nav className="mb-sm-0 d-flex justify-content-center" aria-label="navigation">
                        <ul className="pagination pagination-sm pagination-primary-soft mb-0">
                           
                            <li className="page-item">
                                <button className="btn btn-outline-primary btn-sm" onClick={() => setPage( (e) => e-1 ) } disabled={page === 1}><i className="fas fa-angle-left ms-2"/> Prev</button>
                            </li>
                            <li className="page-item">
                                <button className="btn btn-outline-primary btn-sm" onClick={() => setPage( (e) => e+1 ) } disabled={page === pagination.maxPage}>Next <i className="fas fa-angle-right ms-2"/></button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

        </div>

    );
}

function RefPaymentsTable({data}: {data: typeRefUserPayment[]}) {
    const [page, setPage] = useState(1);
    const [pagination , setPagination] = useState({
        perPage: 5,
        maxPage: 0,
        startIndex: 0,
        endIndex: 5,
        total: 0
    });

    useEffect(() => {
        if (data) {
            setPagination({
                ...pagination,
                maxPage: Math.ceil(data.length / pagination.perPage),
                total: data.length,
                startIndex: (page - 1) * pagination.perPage,
                endIndex: page * pagination.perPage
            })
        }
    }, [data, page])


    return (
        <div className="card shadow mb-5">
            {/* <!-- Card header --> */}
            <div className="card-header border-bottom">
                <h5 className="card-title mb-0">Payment History</h5>
            </div>

            {/* <!-- Card body START --> */}
            <div className="card-body">
                {/* <!-- Table head --> */}
                <div className="bg-light rounded p-3 d-none d-sm-block">
                    <div className="row row-cols-7 g-4">
                        <div className="col col-md-1"><h6 className="mb-0">From</h6></div>
                        <div className="col col-md-1"><h6 className="mb-0">To</h6></div>
                        <div className="col"><h6 className="mb-0">Send ID</h6></div>
                        <div className="col col-md-2"><h6 className="mb-0">Cards</h6></div>
                        <div className="col"><h6 className="mb-0">Time</h6></div>
                    </div>
                </div>

                {/* <!-- Table data --> */}
                {
                    data?.length? 
                    (
                        data.slice(pagination.startIndex, pagination.endIndex).map((item, index) => {
                            return (
                                <div key={index} className="row row-cols-xl-7 g-4 align-items-sm-center border-bottom px-2 py-3">
                                    {/* <!-- Data item --> */}
                                    <div className="col col-md-1">
                                        <small className="d-block d-sm-none">From:</small>
                                        <h6 className="fw-light mb-0">{item.from_user}</h6>
                                    </div>	

                                    {/* <!-- Data item --> */}
                                    <div className="col col-md-1">
                                        <small className="d-block d-sm-none">ID:</small>
                                        <h6 className="mb-0 fw-normal">{item.to_user}</h6>
                                    </div>

                                    {/* <!-- Data item --> */}
                                    <div className="col d-none d-sm-block">
                                        <small className="d-block d-sm-none">Send ID:</small>
                                        <h6 className="fw-light mb-0">#{item.send_id}</h6>
                                    </div>

                                    {/* <!-- Data item --> */}
                                    <div className="col col-md-2">
                                        <small className="d-block d-sm-none">Cards:</small>
                                        <h6 className="fw-light mb-0">{item.cards}</h6>
                                    </div>

                                    {/* <!-- Data item --> */}
                                    <div className="col">
                                        <small className="d-block d-sm-none">Date:</small>
                                        <h6 className="fw-light mb-0">{FormatDate(item.time)}</h6>
                                    </div>
                                </div>
                            )
                        })
                    ):
                    (
                        <div className="row align-items-center px-2 pt-4">
                            <p className="text-center">No Data Found</p>
                        </div>
                    )
                }


            </div>
            {/* <!-- Card body END --> */}


            <div className="card-footer pt-0">
                {/* <!-- Pagination and content --> */}
                <div className="d-sm-flex justify-content-sm-between align-items-sm-center">
                    {/* <!-- Content --> */}
                    <p className="mb-sm-0 text-center text-sm-start">Showing {pagination.startIndex + 1 } to {pagination.endIndex} of {pagination.total} entries</p>
                    {/* <!-- Pagination --> */}
                    <nav className="mb-sm-0 d-flex justify-content-center" aria-label="navigation">
                        <ul className="pagination pagination-sm pagination-primary-soft mb-0">
                           
                            <li className="page-item">
                                <button className="btn btn-outline-primary btn-sm" onClick={() => setPage( (e) => e-1 ) } disabled={page === 1}><i className="fas fa-angle-left ms-2"/> Prev</button>
                            </li>
                            <li className="page-item">
                                <button className="btn btn-outline-primary btn-sm" onClick={() => setPage( (e) => e+1 ) } disabled={page === pagination.maxPage}>Next <i className="fas fa-angle-right ms-2"/></button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

        </div>

    );
}

function CardLogsTable({data}: {data: typeUserCardLog[]}) {
    const [page, setPage] = useState(1);
    const [pagination , setPagination] = useState({
        perPage: 5,
        maxPage: 0,
        startIndex: 0,
        endIndex: 5,
        total: 0
    });

    useEffect(() => {
        if (data) {
            setPagination({
                ...pagination,
                maxPage: Math.ceil(data.length / pagination.perPage),
                total: data.length,
                startIndex: (page - 1) * pagination.perPage,
                endIndex: page * pagination.perPage
            })
        }
    }, [data, page])


    return (
        <div className="card shadow mb-5">
            {/* <!-- Card header --> */}
            <div className="card-header border-bottom">
                <h5 className="card-title mb-0">Cards History</h5>
            </div>

            {/* <!-- Card body START --> */}
            <div className="card-body">
                {/* <!-- Table head --> */}
                <div className="bg-light rounded p-3 d-none d-sm-block">
                    <div className="row row-cols-7 g-4">
                        <div className="col col-md-1 "><h6 className="mb-0">Ref ID</h6></div>
                        <div className="col col-md-1 "><h6 className="mb-0">Txn ID</h6></div>
                        <div className="col col-md-3"><h6 className="mb-0">Card Type</h6></div>
                        <div className="col"><h6 className="mb-0">Card Holder Name</h6></div>
                        <div className="col"><h6 className="mb-0">Charges</h6></div>
                        <div className="col"><h6 className="mb-0">Date</h6></div>
                        <div className="col"><h6 className="mb-0">Files</h6></div>
                    </div>
                </div>

                {/* <!-- Table data --> */}
                {
                    data?.length? 
                    (
                        data.slice(pagination.startIndex, pagination.endIndex).map((item, index) => {
                            return (
                                <div key={index} className="row row-cols-xl-7 g-4 align-items-sm-center border-bottom px-2 py-3">
                                    {/* <!-- Data item --> */}
                                    <div className="col col-md-1">
                                        <small className="d-block d-sm-none">Ref ID:</small>
                                        <h6 className="fw-light mb-0">#{item.id}</h6>
                                    </div>	
                                    <div className="col col-md-1">
                                        <small className="d-block d-sm-none">Txn ID:</small>
                                        <h6 className="fw-light mb-0">#{item.transaction_id}</h6>
                                    </div>	

                                    {/* <!-- Data item --> */}
                                    <div className="col col-md-3">
                                        <small className="d-block d-sm-none">Card Type:</small>
                                        <h6 className="mb-0 fw-normal">{item.card_type}</h6>
                                    </div>

                                    {/* <!-- Data item --> */}
                                    <div className="col d-none d-sm-block">
                                        <small className="d-block d-sm-none">Card Holder Name:</small>
                                        <h6 className="fw-light mb-0">{item.card_holder_name}</h6>
                                    </div>

                                    {/* <!-- Data item --> */}
                                    <div className="col">
                                        <small className="d-block d-sm-none">Charges:</small>
                                        <h6 className="fw-light mb-0">{item.service_charges}</h6>
                                    </div>

                                    {/* <!-- Data item --> */}
                                    <div className="col">
                                        <small className="d-block d-sm-none">Date:</small>
                                        <h6 className="fw-light mb-0">{FormatDate(item.created_at)}</h6>
                                    </div>
                                        
                                    {/* <!-- Data item --> */}
                                    <div className="col">
                                        <small className="d-block d-sm-none">File:</small>
                                        <h6 className="fw-light mb-0">
                                            <div className="row g-2">
                                                
                                                {
                                                    item.files && Object.entries(item.files).map(([key, value], index) => {
                                                        return (
                                                            <div key={index} className="col-auto">
                                                                <a href={import.meta.env.VITE_CDN_URL + value} target="_blank" rel="noreferrer" className="badge text-bg-primary">{key}</a>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                
                                            </div>
                                        </h6>
                                    </div>
                                </div>
                            )
                        })
                    ):
                    (
                        <div className="row align-items-center px-2 pt-4">
                            <p className="text-center">No Data Found</p>
                        </div>
                    )
                }


            </div>
            {/* <!-- Card body END --> */}


            <div className="card-footer pt-0">
                {/* <!-- Pagination and content --> */}
                <div className="d-sm-flex justify-content-sm-between align-items-sm-center">
                    {/* <!-- Content --> */}
                    <p className="mb-sm-0 text-center text-sm-start">Showing {pagination.startIndex + 1 } to {pagination.endIndex} of {pagination.total} entries</p>
                    {/* <!-- Pagination --> */}
                    <nav className="mb-sm-0 d-flex justify-content-center" aria-label="navigation">
                        <ul className="pagination pagination-sm pagination-primary-soft mb-0">
                           
                            <li className="page-item">
                                <button className="btn btn-outline-primary btn-sm" onClick={() => setPage( (e) => e-1 ) } disabled={page === 1}><i className="fas fa-angle-left ms-2"/> Prev</button>
                            </li>
                            <li className="page-item">
                                <button className="btn btn-outline-primary btn-sm" onClick={() => setPage( (e) => e+1 ) } disabled={page === pagination.maxPage}>Next <i className="fas fa-angle-right ms-2"/></button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

        </div>

    );
}



export { TransactionTable, PaymentsTable, RefPaymentsTable, CardLogsTable };