
import { Link } from 'react-router-dom'
import { useEffect } from 'react';
import TinySlider from '../../../helper/TinySlider';
import AdsComponent from '../../../components/GoogleAds/GoogleAds';

import { motion, useTransform, useMotionValue } from "framer-motion"


function AnimatedCard1() {
	const cardX = useMotionValue(0);
	const cardY = useMotionValue(0);
	const rotateX = useTransform(cardY, [-300, 300], [10, -10]); // Reversed values
	const rotateY = useTransform(cardX, [-300, 300], [-10, 10]); // Reversed values
	const cardRotateX = useTransform(cardY, [-300, 300], [25, -25]); // Adjusted rotation values
	const cardRotateY = useTransform(cardX, [-300, 300], [-25, 25]); // Adjusted rotation values

	function moveX(pos:[number, number], value:number): {pos:[number, number], txn:Array<[number, number]>} {
		const currentX = pos[0];
		let data: number[] = [];

		if (value - currentX < 0) {
			data = Array.from({ length: currentX - value -1 }, (_, i) => currentX - i);
		} else {
			data = Array.from({ length: value - currentX +1 }, (_, i) => currentX + i);
		}

		const txn:Array<[number, number]> = data.map(n => [n, pos[1]]);

		return {pos:txn[txn.length - 1], txn}
	}

	function moveY(pos:[number, number], value:number): {pos:[number, number], txn:Array<[number, number]>} {
		const currentY = pos[1];
		let data: number[] = [];

		if (value - currentY < 0) {
			data = Array.from({ length: currentY - value -1 }, (_, i) => currentY - i);
		} else {
			data = Array.from({ length: value - currentY +1 }, (_, i) => currentY + i);
		}

		const txn:Array<[number, number]> = data.map(n => [pos[0], n]);
		
		return {pos:txn[txn.length - 1], txn}
	}


	async function StartAni(duration: number, state: any) {
		let ani: Array<[number, number]> = [];
		let pos: [number, number] = [0, 60];

		// let rtn = moveY(pos ,60);
		// pos = rtn.pos;
		// ani.push(...rtn.txn);
		
		let rtn = moveX(pos ,140);
		pos = rtn.pos;
		ani.push(...rtn.txn);
		
		rtn = moveY(pos ,-60);
		pos = rtn.pos;
		ani.push(...rtn.txn);
		
		rtn = moveX(pos ,-140);
		pos = rtn.pos;
		ani.push(...rtn.txn);
		
		rtn = moveY(pos ,60);
		pos = rtn.pos;
		ani.push(...rtn.txn);
		
		rtn = moveX(pos ,0);
		pos = rtn.pos;
		ani.push(...rtn.txn);

		const sleep_time = duration / ani.length;



		for (let i = 0; i < ani.length; i++) {
			if (state.stop) {
				break;
			}
			
			await new Promise(resolve => setTimeout(resolve, sleep_time));
			cardX.set(ani[i][0]);
			cardY.set(ani[i][1]);
		}
	}

	useEffect(() => {
		let state = { stop: false };

		(async (state) => {
			while (!state.stop) {
				await StartAni(2000, state);
			}
		})(state)

		return () => {
			state.stop = true;
		}
	}, [])

	return (
		<motion.div
			className="col-12 col-md-5 rounded-3 mx-auto"
			style={{
				backgroundImage: "linear-gradient(to top, #a3bded 0%, #6991c7 100%)",
				perspective: 800,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}

		>
			{/* this div can be used as the 'dotted grid' */}
			<motion.div
				className='py-5 d-flex justify-content-center'
				style={{
					margin: "auto",
					width: "100%",
					height: "100%",
					transformStyle: "preserve-3d",
					perspective: 800,
					rotateX,
					rotateY
				}}
				transition={{ velocity: 0 }}
			>
				<motion.div
					key="card"
					style={{
						width: 400,
						perspective: 800, // Set perspective on the card
						rotateX: cardRotateX,
						rotateY: cardRotateY
					}}
					whileHover={{ scale: 1.2 }}
					transition={{ velocity: 0 }}
				>
					<img src="/static/assets/images/img.png" />
				</motion.div>
			</motion.div>
		</motion.div>
	);
}



function PublicIndex() {
	useEffect(() => {
		const resp = TinySlider();

		return () => {
			resp?.forEach((slider: any) => {
				slider.destroy();
			})
		}
	})

	return (
		<>
			{/* Info */}
			<section className="pt-3 pt-lg-5">
				<div className="container">

					<div className="row g-5">

						{/* <!-- Content --> */}
						<div className="col-lg-6 position-relative mb-4">
							{/* <!-- Title --> */}

							<h1 className="mb-4 mt-md-5 display-5">Documents Cropping and Parsing
								<span className="position-relative z-index-9"> Tool
									{/* <!-- SVG START --> */}
									<span className="position-absolute top-50 start-50 translate-middle z-index-n1 d-none d-md-block mt-4">
										<svg width="390.5px" height="21.5px" viewBox="0 0 445.5 21.5">
											<path className="fill-primary opacity-7" d="M409.9,2.6c-9.7-0.6-19.5-1-29.2-1.5c-3.2-0.2-6.4-0.2-9.7-0.3c-7-0.2-14-0.4-20.9-0.5 c-3.9-0.1-7.8-0.2-11.7-0.3c-1.1,0-2.3,0-3.4,0c-2.5,0-5.1,0-7.6,0c-11.5,0-23,0-34.5,0c-2.7,0-5.5,0.1-8.2,0.1 c-6.8,0.1-13.6,0.2-20.3,0.3c-7.7,0.1-15.3,0.1-23,0.3c-12.4,0.3-24.8,0.6-37.1,0.9c-7.2,0.2-14.3,0.3-21.5,0.6 c-12.3,0.5-24.7,1-37,1.5c-6.7,0.3-13.5,0.5-20.2,0.9C112.7,5.3,99.9,6,87.1,6.7C80.3,7.1,73.5,7.4,66.7,8 C54,9.1,41.3,10.1,28.5,11.2c-2.7,0.2-5.5,0.5-8.2,0.7c-5.5,0.5-11,1.2-16.4,1.8c-0.3,0-0.7,0.1-1,0.1c-0.7,0.2-1.2,0.5-1.7,1 C0.4,15.6,0,16.6,0,17.6c0,1,0.4,2,1.1,2.7c0.7,0.7,1.8,1.2,2.7,1.1c6.6-0.7,13.2-1.5,19.8-2.1c6.1-0.5,12.3-1,18.4-1.6 c6.7-0.6,13.4-1.1,20.1-1.7c2.7-0.2,5.4-0.5,8.1-0.7c10.4-0.6,20.9-1.1,31.3-1.7c6.5-0.4,13-0.7,19.5-1.1c2.7-0.1,5.4-0.3,8.1-0.4 c10.3-0.4,20.7-0.8,31-1.2c6.3-0.2,12.5-0.5,18.8-0.7c2.1-0.1,4.2-0.2,6.3-0.2c11.2-0.3,22.3-0.5,33.5-0.8 c6.2-0.1,12.5-0.3,18.7-0.4c2.2-0.1,4.4-0.1,6.7-0.1c11.5-0.1,23-0.2,34.6-0.4c7.2-0.1,14.4-0.1,21.6-0.1c12.2,0,24.5,0.1,36.7,0.1 c2.4,0,4.8,0.1,7.2,0.2c6.8,0.2,13.5,0.4,20.3,0.6c5.1,0.2,10.1,0.3,15.2,0.4c3.6,0.1,7.2,0.4,10.8,0.6c10.6,0.6,21.1,1.2,31.7,1.8 c2.7,0.2,5.4,0.4,8,0.6c2.9,0.2,5.8,0.4,8.6,0.7c0.4,0.1,0.9,0.2,1.3,0.3c1.1,0.2,2.2,0.2,3.2-0.4c0.9-0.5,1.6-1.5,1.9-2.5 c0.6-2.2-0.7-4.5-2.9-5.2c-1.9-0.5-3.9-0.7-5.9-0.9c-1.4-0.1-2.7-0.3-4.1-0.4c-2.6-0.3-5.2-0.4-7.9-0.6 C419.7,3.1,414.8,2.9,409.9,2.6z" />
										</svg>
									</span>
									<span className="position-absolute top-50 start-50 translate-middle z-index-n1 d-none d-md-block mt-4">
										<svg width="390.5px" height="21.5px" viewBox="0 0 445.5 21.5">
											<path className="fill-primary opacity-7" d="M409.9,2.6c-9.7-0.6-19.5-1-29.2-1.5c-3.2-0.2-6.4-0.2-9.7-0.3c-7-0.2-14-0.4-20.9-0.5 c-3.9-0.1-7.8-0.2-11.7-0.3c-1.1,0-2.3,0-3.4,0c-2.5,0-5.1,0-7.6,0c-11.5,0-23,0-34.5,0c-2.7,0-5.5,0.1-8.2,0.1 c-6.8,0.1-13.6,0.2-20.3,0.3c-7.7,0.1-15.3,0.1-23,0.3c-12.4,0.3-24.8,0.6-37.1,0.9c-7.2,0.2-14.3,0.3-21.5,0.6 c-12.3,0.5-24.7,1-37,1.5c-6.7,0.3-13.5,0.5-20.2,0.9C112.7,5.3,99.9,6,87.1,6.7C80.3,7.1,73.5,7.4,66.7,8 C54,9.1,41.3,10.1,28.5,11.2c-2.7,0.2-5.5,0.5-8.2,0.7c-5.5,0.5-11,1.2-16.4,1.8c-0.3,0-0.7,0.1-1,0.1c-0.7,0.2-1.2,0.5-1.7,1 C0.4,15.6,0,16.6,0,17.6c0,1,0.4,2,1.1,2.7c0.7,0.7,1.8,1.2,2.7,1.1c6.6-0.7,13.2-1.5,19.8-2.1c6.1-0.5,12.3-1,18.4-1.6 c6.7-0.6,13.4-1.1,20.1-1.7c2.7-0.2,5.4-0.5,8.1-0.7c10.4-0.6,20.9-1.1,31.3-1.7c6.5-0.4,13-0.7,19.5-1.1c2.7-0.1,5.4-0.3,8.1-0.4 c10.3-0.4,20.7-0.8,31-1.2c6.3-0.2,12.5-0.5,18.8-0.7c2.1-0.1,4.2-0.2,6.3-0.2c11.2-0.3,22.3-0.5,33.5-0.8 c6.2-0.1,12.5-0.3,18.7-0.4c2.2-0.1,4.4-0.1,6.7-0.1c11.5-0.1,23-0.2,34.6-0.4c7.2-0.1,14.4-0.1,21.6-0.1c12.2,0,24.5,0.1,36.7,0.1 c2.4,0,4.8,0.1,7.2,0.2c6.8,0.2,13.5,0.4,20.3,0.6c5.1,0.2,10.1,0.3,15.2,0.4c3.6,0.1,7.2,0.4,10.8,0.6c10.6,0.6,21.1,1.2,31.7,1.8 c2.7,0.2,5.4,0.4,8,0.6c2.9,0.2,5.8,0.4,8.6,0.7c0.4,0.1,0.9,0.2,1.3,0.3c1.1,0.2,2.2,0.2,3.2-0.4c0.9-0.5,1.6-1.5,1.9-2.5 c0.6-2.2-0.7-4.5-2.9-5.2c-1.9-0.5-3.9-0.7-5.9-0.9c-1.4-0.1-2.7-0.3-4.1-0.4c-2.6-0.3-5.2-0.4-7.9-0.6 C419.7,3.1,414.8,2.9,409.9,2.6z" />
										</svg>
									</span>
									{/* <!-- SVG END --> */}
								</span>
							</h1>
							{/* <!-- Info --> */}
							<p className="mb-4">
								Professional ID printing solutions for small and medium-sized businesses. We offer a wide range of ID card solutions, 
								Our goal is to convert documents into cards.
							</p>
							<p className="mb-4">
								This site is not affiliated with any government entity. Some ID cards created/cropped by ID Maker are unofficial and 
								intended for informational purposes only. They do not confer any legal status or rights. Please note that certain institutions, 
								such as banks and other, may not accept them as valid IDs.
							</p>

							{/* <!-- Buttons --> */}
							<div className="hstack gap-4 flex-wrap align-items-center">
								{/* <!-- Button --> */}
								<Link to="/about" className="btn btn-primary-soft mb-0">About us</Link>
								{/* <!-- Story button --> */}
							</div>
						</div>

						<div className="col-lg-6 position-relative my-auto">

							<img src="/static/assets/images/element/laptops-new.png" className="rounded" alt="" />

						</div>


					</div>
				</div>
			</section>

			<section className="pt-4 pt-md-5">
				<div className="container">
					<AdsComponent dataAdSlot="5714913101" />
				</div>
			</section>


			{/* How We Work */}
			<section>
				<div className="container">
					<div className="row mb-4">
						<div className="col-12 text-center">
							<h1>How We Work</h1>
						</div>
					</div>

					<div className="row g-4 g-md-5 d-flex align-items-center text-center">
						<div className="col-12 col-md-6">
							<p className="mb-4">
								<img className="rounded-3" src="/static/assets/images/gif/search.gif" alt="" style={{ width: "450px" }} />
							</p>
						</div>
						<div className="col-12 col-md-6">
							<p className="mb-4 fs-4 fw-bold">
								We examine the documents you uploaded, just like how a detective carefully looks for clues at a crime scene.
							</p>
						</div>
						<div className="col-12 col-md-6">
							<p className="mb-4 fs-4 fw-bold">
								After we've gathered all the important details, we work our magic to transform them into a special card format.
								Think of it as if we're turning plain ingredients into a delicious recipe.
							</p>
						</div>
						{/* <AnimatedCard /> */}
						<AnimatedCard1 />
					</div>

				</div>
			</section>


			{/* Slider */}
			<section>
				<div className="container">
					<div className="row">
						{/* <!-- Client logo START --> */}
						<div className="col-12">
							<div className="bg-light rounded-3 p-5">
								{/* <!-- Title --> */}
								<h5 className="text-center mb-0 mb-md-0">Trusted by more than 2,00,000 customers.</h5>
								<p className="text-center text-body-tertiary text-opacity-25 mb-4 mb-md-5 ">below image referring the customer types.</p>

								{/* <!-- Slider START --> */}
								<div className="tiny-slider arrow-round">
									<div className="tiny-slider-inner d-flex align-items-center" data-arrow="false" data-autoplay="true" data-edge="5" data-dots="false" data-gutter="80" data-items-xl="4" data-items-lg="4" data-items-md="3" data-items-sm="2" data-items-xs="1">
										{/* <!-- Slide item START --> */}
										<div className="item"><img src="/static/assets/images/element/mponline.webp" alt="" /> </div>
										<div className="item"><img src="/static/assets/images/element/csc.webp" alt="" /> </div>
										<div className="item"><img src="/static/assets/images/element/janseva.png" alt="" /> </div>
										<div className="item"><img src="/static/assets/images/element/emitra.png" alt="" /> </div>
										<div className="item"><img src="/static/assets/images/element/cybercafe.png" alt="" /> </div>
										<div className="item"><img src="/static/assets/images/element/computershops.png" alt="" /> </div>

										{/* <!-- Slide item END --> */}
									</div>
								</div>
								{/* <!-- Slider END --> */}
							</div>
						</div>
						{/* <!-- Client logo END --> */}
					</div>
				</div>
			</section>

			{/* Services */}
			<section className="pt-0 pt-xl-5">
				<div className="container">
					{/* <!-- Title --> */}
					<div className="row mb-3 mb-sm-4">
						<div className="col-12 text-center">
							<h2>Services</h2>
						</div>
					</div>

					<div className="row g-4">
						{/* <!-- Category item --> */}
						<div className="col-sm-6 col-lg-4">
							{/* <!-- Card START --> */}
							<div className="card card-body shadow p-4 h-100">
								{/* <!-- Icon --> */}
								<div className="icon-lg bg-primary bg-opacity-10 text-primary rounded-circle mb-4"><i className="bi bi-person-square fs-4"></i></div>
								<h5>Passport size photo</h5>
								<p className="mb-0">Creating passport-size photos is a breeze. Simply choose your image, enter a name, opt for background removal if needed, and upload. </p>
							</div>
							{/* <!-- Card END --> */}
						</div>

						{/* <!-- Category item --> */}
						<div className="col-sm-6 col-lg-4">
							{/* <!-- Card START --> */}
							<div className="card card-body shadow p-4 h-100">
								{/* <!-- Icon --> */}
								<div className="icon-lg bg-success bg-opacity-10 text-success rounded-circle mb-4"><i className="fa-solid fa-meteor fs-4" style={{ marginTop: "15px" }}></i></div>
								<h5>Free Kundli Downloader</h5>
								<p className="mb-0">Generate your Kundli in just a few clicks, and it won't cost you a thing. We offer support for multiple languages, including Hindi and English.</p>
							</div>
							{/* <!-- Card END --> */}
						</div>

						{/* <!-- Category item --> */}
						<div className="col-sm-6 col-lg-4">
							{/* <!-- Card START --> */}
							<div className="card card-body shadow p-4 h-100">
								{/* <!-- Icon --> */}
								<div className="icon-lg bg-warning bg-opacity-15 text-warning rounded-circle mb-4"><i className="bi bi-person-badge fs-4"></i></div>
								<h5>School IDs</h5>
								<p className="mb-0">Create your school ID with ease. Simply provide your details, choose a design, and you're good to go!</p>
							</div>
							{/* <!-- Card END --> */}
						</div>

						{/* <!-- Category item --> */}
						<div className="col-sm-6 col-lg-4">
							{/* <!-- Card START --> */}
							<div className="card card-body shadow p-4 h-100">
								{/* <!-- Icon --> */}
								<div className="icon-lg bg-danger bg-opacity-10 text-danger rounded-circle mb-4"><i className="bi bi-pencil-square fs-4"></i></div>
								<h5>Editor and Resizer</h5>
								<p className="mb-0">Edit and resize your photos effortlessly with our powerful Editor and Resizer tools. Achieve the perfect look and size for your images with just a few simple clicks. </p>
							</div>
							{/* <!-- Card END --> */}
						</div>

						{/* <!-- Category item --> */}
						<div className="col-sm-6 col-lg-4">
							{/* <!-- Card START --> */}
							<div className="card card-body shadow p-4 h-100">
								{/* <!-- Icon --> */}
								<div className="icon-lg bg-orange bg-opacity-10 text-orange rounded-circle mb-4"><i className="bi bi-person-vcard-fill fs-4"></i></div>
								<h5>ID Cards (Cropping and Parsing AI)</h5>
								<p className="mb-0">Generate a variety of over 30 ID cards, such as Aadhaar, Abha, Ayushman, PAN, Vaccine, and many more, in just a matter of seconds. You'll receive high-quality front and back images as well as an A4-sized page.</p>
							</div>
							{/* <!-- Card END --> */}
						</div>

						{/* <!-- Category item --> */}
						<div className="col-sm-6 col-lg-4">
							{/* <!-- Card START --> */}
							<div className="card card-body shadow p-4 h-100">
								{/* <!-- Icon --> */}
								<div className="icon-lg bg-info bg-opacity-10 text-info rounded-circle mb-4"><i className="bi bi-file-earmark-fill fs-4"></i></div>
								<h5>Page Maker</h5>
								<p className="mb-0">Using Page Maker, you can effortlessly arrange the front and back of your ID on an A4-sized paper, and then conveniently download it as a PDF or image. Say goodbye to wasting time in Photoshop after scanning your documents.</p>
							</div>
							{/* <!-- Card END --> */}
						</div>

					</div>

				</div>
			</section>

			{/* Testimonials */}
			<section className="pt-4 pt-md-5">
				<div className="container">
					<AdsComponent dataAdSlot="5714913101" />
				</div>
			</section>

			{/* Pricing */}
			<section className="price-wrap pt-4 pt-sm-0 my-5">
				<div className="container">
					<div className="row">
						{/* <!-- Title --> */}
						<div className="col-xl-10 mx-auto text-center">
							<h1 className="mb-2">Simple and affordable
								<span className="position-relative z-index-9"> Pricing
									{/* <!-- SVG START --> */}
									<span className="position-absolute top-50 start-50 translate-middle z-index-n1 d-none d-sm-block">
										<svg width="203.94px" height="54.13px" viewBox="0 0 203.94 54.13">
											<path className="fill-primary opacity-7" d="M126.49,49.51c19.28,0.32,33.97,0.06,48.55-2.07c5.25-0.77,10.43-2.14,15.54-3.6c2.26-0.64,4.43-1.95,6.35-3.36 c3.16-2.32,3.37-4.88,0.61-7.62c-1.84-1.82-3.96-3.42-6.16-4.8c-5.74-3.6-12.04-6.02-18.4-8.28c-15.69-5.58-31.86-9.17-48.29-11.7 c-18.65-2.87-37.4-4.07-56.26-3.51C54.54,5,40.71,6.16,26.99,8.39C19.25,9.65,11.5,10.9,3.76,12.15c-0.1,0.02-0.2,0.04-0.3,0.05 c-2.03,0.27-3.23-0.26-3.44-1.51c-0.2-1.22,0.72-2.05,2.84-2.45c13.24-2.51,26.48-5.11,39.9-6.43c14.43-1.42,28.9-2.13,43.42-1.67 c24.63,0.78,48.89,4.14,72.7,10.58c9.42,2.55,18.73,5.54,27.54,9.77c4.06,1.95,7.9,4.39,11.66,6.88c1.63,1.08,3.02,2.69,4.15,4.32 c2.53,3.63,2.27,7.63-0.89,10.71c-1.84,1.79-4.08,3.29-6.38,4.45c-5.44,2.75-11.4,3.91-17.38,4.77c-19.2,2.75-38.5,2.96-57.84,1.98 c-7.84-0.4-15.68-0.74-23.5-1.34c-10.13-0.77-20.26-1.58-30.36-2.72c-9.49-1.07-18.96-2.38-28.37-4.01 C32.2,44.6,26.97,43,21.81,41.34c-2.44-0.78-4.81-2.13-6.86-3.69c-4.56-3.49-4.8-8.3-0.9-12.54c2.3-2.5,5.23-4.07,8.3-5.36 c6.9-2.91,14.13-4.64,21.46-5.97c10.14-1.84,20.37-2.47,30.66-1.92c2.57,0.14,3.72,0.84,3.6,2.22c-0.11,1.26-1.19,1.84-3.6,1.92 c-6.13,0.21-12.28,0.2-18.4,0.67c-10.47,0.82-20.72,2.87-30.56,6.62c-2.23,0.85-4.45,1.95-6.4,3.31c-4.53,3.16-4.36,6.27,0.46,8.87 c2.28,1.23,4.75,2.19,7.24,2.97c7.8,2.44,15.84,3.88,23.93,4.79c15.4,1.73,30.8,3.38,46.24,4.67 C108.32,48.84,119.7,49.15,126.49,49.51z"></path>
										</svg>
									</span>
									{/* <!-- SVG END --> */}
								</span>
							</h1>
							<p className="lead mb-0">Clear and Cost-Effective Pricing Packages to Match Any Budget</p>

							{/* <!-- Switch START --> */}

							{/* <!-- Switch END --> */}
						</div>
					</div>

					{/* <!-- Pricing START --> */}
					<div className="row g-4 justify-content-center align-items-center mt-5">
						{/* <!-- Price item START --> */}
						<div className="col-md-6 col-lg-4">
							<div className="card shadow">
								{/* <!-- Card header --> */}
								<div className="card-header d-flex justify-content-between align-items-center border-bottom p-4">
									<div>
										<h6 className="mb-0">Free Forever</h6>
										<div className="hstack gap-2">
											<span className="h3 plan-price mb-0" data-monthly-price="$20" data-annual-price="$570">₹0</span>
										</div>
									</div>
									<span className="icon-lg bg-light rounded-circle" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Most Popular" data-bs-original-title="Most Popular">
										<i className="bi bi-lightning-charge-fill h5 lh-1"></i>
									</span>
								</div>

								{/* <!-- Card body --> */}
								<div className="card-body p-4">
									{/* <!-- List --> */}
									<ul className="list-unstyled mb-0">
										<li className="mb-2"><i className="bi bi-check-circle text-success me-2"></i>Image Editor</li>
										<li className="mb-2"><i className="bi bi-check-circle text-success me-2"></i>Kundli</li>
										<li className="mb-2"><i className="bi bi-check-circle text-success me-2"></i>Page Maker</li>
										<li className="mb-2"><i className="bi bi-check-circle text-success me-2"></i>Cards</li>
									</ul>
								</div>

								{/* <!-- Card footer --> */}
								<div className="card-footer p-4 pt-0">
									<Link to="/login?redirect=/u/index" className="btn btn-dark w-100 mb-0">Get Started</Link>
								</div>
							</div>
						</div>
						{/* <!-- Price item END --> */}

						{/* <!-- Price item START --> */}
						<div className="col-md-6 col-lg-4">
							<div className="card border border-primary">
								{/* <!-- Card header --> */}
								<div className="card-header d-flex justify-content-between align-items-center border-bottom p-4">
									<div>
										<h6 className="mb-0 text-primary">Professional Plan</h6>
										<div className="hstack gap-2">
											<span className="h3 plan-price mb-0" data-monthly-price="$20" data-annual-price="$570">₹2.2</span>
											<span className="h6 fw-light mb-0">/ Per card</span>
										</div>
									</div>
									<span className="icon-lg bg-primary bg-opacity-10 rounded-circle text-primary" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Recommended" data-bs-original-title="Recommended">
										<i className="fa-solid fa-rocket"></i>
									</span>
								</div>

								{/* <!-- Card body --> */}
								<div className="card-body p-4">
									{/* <!-- List --> */}
									<ul className="list-unstyled mb-0">
										<li className="mb-2"><i className="bi bi-check-circle text-success me-2"></i>All paid cards</li>
										<li className="mb-2"><i className="bi bi-check-circle text-success me-2"></i>All cards slip</li>
										<li className="mb-2"><i className="bi bi-check-circle text-success me-2"></i>Premium support</li>
										<li className="mb-2"><i className="bi bi-check-circle text-success me-2"></i>Custom service request</li>
										<li className="mb-2"><i className="bi bi-check-circle text-success me-2"></i>Passport size photo</li>
										<li className="mb-2"><i className="bi bi-check-circle text-success me-2"></i>Unlimited updates</li>
										<li><i className="bi bi-check-circle text-success me-2"></i>All from free plan</li>
									</ul>
								</div>

								{/* <!-- Card footer --> */}
								<div className="card-footer p-4 pt-0">
									<Link to="/login?redirect=/u/add_money" className="btn btn-primary w-100 mb-0">Get Started</Link>
								</div>
							</div>
						</div>
						{/* <!-- Price item END --> */}

						{/* <!-- Price item START --> */}

						{/* <!-- Price item END --> */}
					</div>
					{/* <!-- Pricing END --> */}
				</div>
			</section>


		</>
	);
}

export default PublicIndex;