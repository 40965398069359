function Loader({middle}: {middle?: boolean}) {

    const CustomeStyle = middle ? {height: "70vh"} : {}

    return (
        <div className="d-flex justify-content-center align-items-center mb-5" style={CustomeStyle}>
            <img className="light-mode-item" src="https://raw.githubusercontent.com/n3r4zzurr0/svg-spinners/main/svg-smil/pulse-rings-3.svg" alt="loading" style={{width: "200px"}}/>
            <img className="dark-mode-item" src="https://raw.githubusercontent.com/n3r4zzurr0/svg-spinners/main/preview/pulse-rings-3-white-36.svg" alt="loading" style={{width: "200px"}}/>
        </div>
    );
}

export default Loader;