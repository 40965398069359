

import PublicDashboardTitle from "../../Public/Dashboard/Components/Title";
import AdminAllUsers from "./Components/AllUsers";
import AdminTransactionInfo from "./Components/TransactionInfo";
import AdminSendCards from "./Components/SendCards";
import AdminAllTransactionsInfo from "./Components/AllTransactions";
import AdminUserInfo from "./Components/UserInfo";
import AdminPhoneSearch from "./Components/PhoneSearch";
import AdminGetUserDetails from "./Components/UserDetails";

function AdminPlugin() {
    return (
        <>
            <PublicDashboardTitle title={"Admin Plugin"} icon={"bi bi-house-door fa-fw me-1"} addcardbtn={false} />
            
            <ul className="nav nav-tabs nav-bottom-line mb-3 d-flex justify-content-center">
                <li className="nav-item"> <a className="nav-link active" data-bs-toggle="tab" href="#tab-user-info">User Info</a> </li>
                <li className="nav-item"> <a className="nav-link" data-bs-toggle="tab" href="#tab-transaction-info"> Transaction Info</a> </li>
                <li className="nav-item"> <a className="nav-link" data-bs-toggle="tab" href="#tab-all-users"> All Users </a> </li>
                <li className="nav-item"> <a className="nav-link" data-bs-toggle="tab" href="#tab-send-cards"> Transfer Balance </a> </li>
                <li className="nav-item"> <a className="nav-link" data-bs-toggle="tab" href="#tab-ref-payment-info"> All Transactions Info </a> </li>
                <li className="nav-item"> <a className="nav-link" data-bs-toggle="tab" href="#tab-phone-search"> Phone Search </a> </li>
                <li className="nav-item"> <a className="nav-link" data-bs-toggle="tab" href="#tab-user-details"> User Details </a> </li>
            </ul>
            <div className="tab-content mt-3">
                <div className="tab-pane show active" id="tab-user-info">
                    <AdminUserInfo />
                </div>
                <div className="tab-pane" id="tab-transaction-info">
                    <AdminTransactionInfo />
                </div>
                <div className="tab-pane" id="tab-all-users">
                    <AdminAllUsers />
                </div>
                <div className="tab-pane" id="tab-send-cards">
                    <AdminSendCards />
                </div>
                <div className="tab-pane" id="tab-ref-payment-info">
                    <AdminAllTransactionsInfo />
                </div>

                <div className="tab-pane" id="tab-phone-search">
                    <AdminPhoneSearch />
                </div>
                <div className="tab-pane" id="tab-user-details">
                    <AdminGetUserDetails />
                </div>
            </div>
        </>
    )
}




export default AdminPlugin;