
import { Fragment } from 'react';
import {typeKeyValue} from '../../../interfaces/Interface';

function KeyValue({data}: {data: typeKeyValue}) {
    return (
        <div className="col-md-8 mb-4 mx-auto">
            <div className="card border">
                <div className="card-body">
                    <dl className="row">
                        
                        {
                            Object.entries(data).map(([key, value]) => {
                                return (
                                    <Fragment key={key}>
                                        <dt className="col-sm-3 text-capitalize">{key.replace("_", " ")}</dt>
                                        <dd className="col-sm-9">
                                            {typeof value === "boolean" ? (value ? "Active / Yes" : "Deactivated / No") : value}
                                        </dd>
                                    </Fragment>
                                )
                            })
                        }

                    </dl>
                </div>
            </div>
        </div>
    )
}

export default KeyValue;