import applyTheme from '../../../components/Theme/Theme';
import { Link } from 'react-router-dom';

import AuthContext from '../../../context/auth';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import Branding from '../../../Branding/Branding';

import PublicGoogleLogin from './components/GoogleSignin';
import { useMutationForgotPassword } from '../../../api/login';
import GoogleCaptchaV3 from '../../../components/Captcha/google';


import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';



function PublicForgotPassword() {
    applyTheme();


    type ForgotPasswordPayload = {
        email: string
    }

    const emailRules = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const schema = yup.object().shape({
        email: yup.string().required("Email is required").matches(emailRules, "Email is not valid").lowercase()
    });

    const { register, handleSubmit, formState: { errors, dirtyFields } } = useForm<ForgotPasswordPayload>(
        { resolver: yupResolver(schema), mode: 'all' }
    );

    const { isLogin } = useContext(AuthContext);
    const { mutateAsync, isLoading } = useMutationForgotPassword();

    const captcha = GoogleCaptchaV3();

    const branding = Branding();

    async function onSubmit(data: ForgotPasswordPayload) {
        const captcha_answer = await captcha.sureChanllenge("forgot_password");

        const response = await mutateAsync({
            ...data,
            captcha: captcha_answer || "",
        })

        console.log(response);
    }


    const searchParams = new URLSearchParams(document.location.search)
    const redirect = searchParams.get("redirect")

    if (isLogin) {
        if (redirect) {
            return <Navigate to={redirect} />
        }
        return <Navigate to="/u/index" />
    }


    return (
        <main>
            <section className="vh-100">
                <div className="container h-100 d-flex px-0 px-sm-4">
                    <div className="row justify-content-center align-items-center m-auto">
                        <div className="col-12">
                            <div className="bg-mode shadow rounded-3 overflow-hidden">
                                <div className="row g-0">
                                    {/* <!-- Vector Image --> */}
                                    <div className="col-lg-6 d-flex align-items-center order-2 order-lg-1">
                                        <div className="p-3 p-lg-5">
                                            <img src="/static/assets/images/element/signin.svg" alt="" />
                                        </div>
                                        <div className="vr opacity-1 d-none d-lg-block"></div>
                                    </div>

                                    {/* <!-- Information --> */}
                                    <div className="col order-1">

                                        <div className="p-4 p-sm-7">
                                            {/* <!-- Logo --> */}

                                            <Link to="/">
                                                <img className="h-50px mb-4" src={branding.logo} alt="logo" />
                                            </Link>
                                            {/* <!-- Title --> */}
                                            <h1 className="mb-2 h3">Forgot password?</h1>
                                            <p className="mb-0">
                                                Enter the email address associated with an account.
                                            </p>

                                            <Form className="mt-4 text-start needs-validation" onSubmit={handleSubmit(onSubmit)}>
                                                <Form.Group className="mb-3" controlId="email">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="email" placeholder="Email" {...register("email")} required isInvalid={!!errors.email} isValid={dirtyFields.email && !errors.email} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.email?.message}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <div className="mb-3 text-center">
                                                    <p>Back to <Link to="/login">Sign in</Link></p>
                                                </div>

                                                <div>
                                                    <button type="submit" className="btn btn-primary w-100 mb-0" disabled={isLoading || captcha.isLoading}>
                                                        <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"
                                                            style={{ display: (isLoading || captcha.isLoading) ? 'inline-block' : 'none' }}></span>
                                                        Reset Password</button>
                                                </div>
                                            </Form>

                                            {/* <!-- Divider --> */}
                                            <div className="position-relative my-4">
                                                <hr />
                                                <p className="small bg-mode position-absolute top-50 start-50 translate-middle px-2">Or sign in with</p>
                                            </div>
                                            {/* <!-- Google and facebook button --> */}

                                            <PublicGoogleLogin />

                                            {/* <!-- Divider --> */}

                                            {/* <!-- Copyright --> */}
                                            <div className="text-primary-hover mt-3 text-center">
                                                Copyrights <i className="bi bi-c-circle"></i> {new Date().getFullYear()} <Link to="https://github.com/mayankfawkes" target="_blank" rel="noopener noreferrer" className="text-muted">
                                                    MayankFawkes
                                                </Link>
                                                <br />
                                                Registration No: UDYAM-MP-15-0001259
                                            </div>


                                            {/* <!-- Form END --> */}
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}


export default PublicForgotPassword;