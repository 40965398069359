import { useMutationAdminGetTransaction } from "../../../../api/admin";
import KeyValue from "../../Components/KeyValue";

function AdminTransactionInfo() {
    const {data, isLoading, mutate } = useMutationAdminGetTransaction();

    function onSubmit(e) {
        e.preventDefault();

        const payload = {
            transaction_id: e.target.transaction_id.value,
        }

        mutate(payload);
    }

    return (
        <>
        <div className="row mb-4">
            <div className="col-md-8 mx-auto">
                <div className="card border">
                    <div className="card-header border-bottom">
                        <h5 className="card-header-title">Transaction Info</h5>
                        <p className="mb-0 small">Get Transaction Info by Transaction id.</p>
                    </div>
                    {/* <!-- Card body START --> */}
                    <form onSubmit={onSubmit}>
                        <div className="card-body">

                            <div className="mb-3">
                                <label className="form-label">Transaction ID</label>
                                <input type="text" name="transaction_id" className="form-control" placeholder="Transaction ID" required/>
                            </div>

                        </div>


                        <div className="card-footer border-top">
                            <div className="text-center d-flex justify-content-around">
                                <button className="btn btn-primary fs-5">
                                    <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{display: !isLoading? 'None': ""}}></span>
                                    <span role="status">Search</span>
                                </button>
                                {/* <button className="btn btn-primary fs-5" type="button" onClick={() => {refetch()}}>
                                    <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{display: !isRefetching? 'None': ""}}></span>
                                    <span role="status">Refresh</span>
                                </button> */}
                            </div>
                        </div>
                        
                    </form>
                    {/* <!-- Card body END --> */}
                </div>
            </div>
        </div>


        {
            data && <KeyValue data={data} />
        }



        </>
    )
}

export default AdminTransactionInfo;