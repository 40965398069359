import { Link } from "react-router-dom";

function PublicHowWeMakeCards() {
    return (
        <section className="pt-4 pt-lg-5">
            <div className="container">
                {/* Title */}
                <div className="row">
                    <div className="col-12 text-center mb-4">
                        <h6>Inside ID Maker</h6>
                        <h1 className="fs-2 mb-0">Know! How we make cards</h1>
                        <p>Last updated: March 20, 2024</p>
                    </div>
                </div>

                {/* Content START */}
                <div className="row">
                    <div className="col-md-10 mx-auto">
                        <div className="vstack gap-3">

                            <blockquote className="blockquote text-center">
                                <p className="fs-4 fw-bold">Cards Holding Non-Government Status.</p>
                                <p className="fs-6 mb-0">Non-Government Cards Definition - Cards are not an official government document The card does not confer any legal status or rights. 
                                            It is intended for personal or informational purposes only, during that we are not using any central or state government logo/images</p>
                                <p className="fs-6">While making the card, you authorize us to retrieve the necessary information for card creation from our pool of open data sources. </p>
                            </blockquote>


                            <h5>1. Andhra Pradesh Ration Card</h5>
                            <p>This card is based on information available on the official Andhra Pradesh government website.</p>
                            <p><strong>Disclaimer:</strong> The card we are making is based on the information available on the official Andhra Pradesh government website. We do not claim any official affiliation with the government authorities.</p>
                            <p>Source: <Link to="https://aepos.ap.gov.in/SRC_Trans_Int.jsp" className="text-muted text-primary-hover">Andhra Pradesh Government Ration Website</Link></p>

                            <h5>2. J&K Ration Card</h5>
                            <p>This card is based on information available on the official Jammu & Kashmir government website.</p>
                            <p><strong>Disclaimer:</strong> The card we are making is based on the information available on the official Jammu & Kashmir government website. We do not claim any official affiliation with the government authorities.</p>
                            <p>Source: <Link to="https://epos.jk.gov.in/SRC_Trans_Int.jsp" className="text-muted text-primary-hover">Jammu & Kashmir Government Ration Website</Link></p>

                            <h5>3. OneRation Card / UP Ration</h5>
                            <p>This card is based on information available on the official impds.nic.in 1 national ration card app.</p>
                            <p><strong>Disclaimer:</strong> The card we are making is based on the information available on the official impds.nic.in 1 national ration card app. We do not claim any official affiliation with the government authorities.</p>
                            <p>Source: <Link to="https://play.google.com/store/apps/details?id=com.nic.onenationonecard&hl=en_GB" className="text-muted text-primary-hover">impds.nic.in</Link></p>

                            <h5>4. Vaccine Certificate Card</h5>
                            <p>This card is based on information available on the PDF file uploaded by the user, and the user is responsible to validate the authenticity of the ID card PDF Files, still at some level we verify the information from QR code.</p>
                            <p><strong>Disclaimer:</strong> The card we are making is based on the information available on the PDF file uploaded by the user. We do not claim any official affiliation with the government authorities.</p>
                            <p>Source: PDF File Uploaded by User</p>

                            <h5>5. Rajasthan Government Health Scheme - RGHS Card </h5>
                            <p>This card is based on information available on the PDF file uploaded by the user, and the user is responsible to validate the authenticity of the ID card PDF Files </p>
                            <p><strong>Disclaimer:</strong> The card we are making is based on the information available on the PDF file uploaded by the user. We do not claim any official affiliation with the government authorities.</p>
                            <p>Source: PDF File Uploaded by User</p>

                            <h5>6. Haryana Pariwar Pehchan Patra</h5>
                            <p>This card is based on information available on the official Haryana government website.</p>
                            <p><strong>Disclaimer:</strong> The card we are making is based on the information available on the official Haryana government website. We do not claim any official affiliation with the government authorities.</p>
                            <p>Source: <Link to="https://meraparivar.haryana.gov.in/" className="text-muted text-primary-hover">Haryana Government Website</Link></p>

                            <h5>7. Punjab Ration Card</h5>
                            <p>This card is based on information available on the official Punjab government website.</p>
                            <p><strong>Disclaimer:</strong> The card we are making is based on the information available on the official Punjab government website. We do not claim any official affiliation with the government authorities.</p>
                            <p>Source: <Link to="https://ercms.punjab.gov.in" className="text-muted text-primary-hover">Punjab Government Website</Link></p>

                            <h5>8. Jharkhand Ration Card</h5>
                            <p>This card is based on information available on the official Jharkhand government website.</p>
                            <p><strong>Disclaimer:</strong> The card we are making is based on the information available on the official Jharkhand government website. We do not claim any official affiliation with the government authorities.</p>
                            <p>Source: <Link to="https://aahar.jharkhand.gov.in" className="text-muted text-primary-hover">Jharkhand Government Website</Link></p>

                            <h5>9. Madhya Pradesh Ration Card</h5>
                            <p>This card is based on information available on the official Madhya Pradesh government website.</p>
                            <p><strong>Disclaimer:</strong> The card we are making is based on the information available on the official Madhya Pradesh government website. We do not claim any official affiliation with the government authorities.</p>
                            <p>Source: <Link to="https://rationmitra.nic.in" className="text-muted text-primary-hover">Madhya Pradesh Government Website</Link></p>

                            <h5>10. Madhya Pradesh Samagra Card</h5>
                            <p>This card is based on information available on the official Madhya Pradesh government website.</p>
                            <p><strong>Disclaimer:</strong> The card we are making is based on the information available on the official Madhya Pradesh government website. We do not claim any official affiliation with the government authorities.</p>
                            <p>Source: <Link to="https://samagra.gov.in" className="text-muted text-primary-hover">Madhya Pradesh Government Website</Link></p>

                            <h5>11. Chatisgarh Ration Card</h5>
                            <p>This card is based on information available on the official Chatisgarh government website.</p>
                            <p><strong>Disclaimer:</strong> The card we are making is based on the information available on the official Chatisgarh government website. We do not claim any official affiliation with the government authorities.</p>
                            <p>Source: <Link to="https://khadya.cg.nic.in" className="text-muted text-primary-hover">Chatisgarh Government Website</Link></p>

                            <h5>12. Bihar Ration Card</h5>
                            <p>This card is based on information available on the official Bihar government website.</p>
                            <p><strong>Disclaimer:</strong> The card we are making is based on the information available on the official Bihar government website. We do not claim any official affiliation with the government authorities.</p>
                            <p>Source: <Link to="https://epds.bihar.gov.in" className="text-muted text-primary-hover">Bihar Government Website</Link></p>

                            <h5>13. Driving Licence</h5>
                            <p>This card is based on information available on the official Driving Licence website.</p>
                            <p><strong>Disclaimer:</strong> The card we are making is based on the information available on the official Driving Licence website. We do not claim any official affiliation with the government authorities.</p>
                            <p>Source: <Link to="https://parivahan.gov.in" className="text-muted text-primary-hover">Driving Licence Website</Link></p>

                            <h5>14. Andhra Pradesh RC Card</h5>
                            <p>This card is based on information available on the official Andhra Pradesh government website.</p>
                            <p><strong>Disclaimer:</strong> The card we are making is based on the information available on the official Andhra Pradesh government website. We do not claim any official affiliation with the government authorities.</p>
                            <p>Source: <Link to="https://aprtacitizen.epragathi.org/#!/index" className="text-muted text-primary-hover">Andhra Pradesh Government Website</Link></p>

                            <h5>15. Madhya Pradesh RC Card</h5>
                            <p>This card is based on information available on the official Madhya Pradesh government website.</p>
                            <p><strong>Disclaimer:</strong> The card we are making is based on the information available on the official Madhya Pradesh government website. We do not claim any official affiliation with the government authorities.</p>
                            <p>Source: <Link to="http://mis.mptransport.org/MPLogin/eSewa/VehicleSearch.aspx" className="text-muted text-primary-hover">Madhya Pradesh Government Website</Link></p>

                            <blockquote className="blockquote text-center fw-bold my-4">
                                <p>Other Cards on ID Maker</p>
                            </blockquote>

                            <h5>Aadhaar Card, PAN Card, Voter ID, Ayushman, and other cards</h5>
                            <p>Rest of the cards are completely based on the PDF provided by the user,
                                and the user is responsible to validate the authenticity of the ID card PDF Files. In all of those cards, we just provide auto cropping and printing of the card.
                            </p>
                        
                            <hr />

                            <h5>Privacy Policy</h5>
                            <p>Please read our Privacy Policy at <Link to="/privacy" className="text-muted text-primary-hover">https://idcard.store/privacy</Link>.</p>

                            <h5>Terms of Use</h5>
                            <p>Please read our Terms of Use at <Link to="/terms" className="text-muted text-primary-hover">https://idcard.store/terms</Link>.</p>


                            {/* high lighted note */}
                            <div className="bg-light border-left border-primary p-3 text-danger mt-4 fs-6 fw-bold">
                                <p className="mb-0">Note: It is the sole responsibility of the user to validate the authenticity of the ID card PDF Files being
                                    used with ID Maker, with pdf file id maker act as a tool to provide auto cropping and printing of the card.</p>

                            </div>

                        </div>
                    </div>
                </div>
                {/* Content END */}
            </div>
        </section>
    );
}


export default PublicHowWeMakeCards;