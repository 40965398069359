import { useMutationAdminGetAllUsers } from "../../../../api/admin";

import {FormatDate} from "../../../../helper/Time";

function AdminAllUsers() {
    const { mutateAsync, isLoading } = useMutationAdminGetAllUsers();

    function makeTable(data) {
        const keys = ["id", "full_name", "email", "phone", "balance", "created_at"];

        const html = `<!DOCTYPE html>
                    <html data-bs-theme="dark">
                    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" crossorigin="anonymous">
                    <body>
                    
                    <table class="table table-dark table-hover">
                    <thead>
                        <tr>
                            ${
                                keys.map(key => {
                                    return `<th scope="col">${key}</th>`
                                }).join("")
                            }
                        </tr>
                    </thead>
                    <tbody>

                        ${
                            data.map(user => {
                                return `
                                    <tr>
                                        ${
                                            keys.map(key => {
                                                if (key === "created_at") {
                                                    return `<td>${FormatDate(user[key])}</td>`
                                                }
                                                return `<td>${user[key]}</td>`
                                            }).join("")
                                        }
                                    </tr>
                                `
                            }).join("")
                        }

                    </tbody>
                    </table>
                    
                    
                    </body>
                    </html>
                    
                    `
        return html;
    }

    async function onClick() {
        const data = await mutateAsync(null);
        let windowObject = window.open("", "_blank", "height=800,width=800,status=1,toolbar=0,menubar=0,resizable=1,scrollbars=1");
        if (!windowObject || !data ) {
            alert("Please allow popups for this website");
            return;
        }
        const html = makeTable(data);
        console.log(html);
        windowObject.document.open("text/html");
        windowObject.document.write(html);
    }

    return (
        <>
            <div className="row mb-4">
                <div className="col-md-8 mx-auto">
                    <div className="card border">
                        <div className="card-header border-bottom">
                            <h5 className="card-header-title">Get all users.</h5>
                            <p className="mb-0 small">Get all users registered with you.</p>
                        </div>
                        {/* <!-- Card body START --> */}
                        <form>
                            <div className="card-footer border-top">
                                <div className="text-center d-flex justify-content-around">
                                    <button className="btn btn-primary fs-5" type="button" onClick={onClick}>
                                        <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{ display: !isLoading ? 'None' : "" }}></span>
                                        <span role="status">Get All</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                        {/* <!-- Card body END --> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminAllUsers;