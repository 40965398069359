import Card from 'react-bootstrap/Card';

function PublicDashboardPaymentManually() {


    // check if env is prod or dev

    // if dev then show the test payment details

    if (!import.meta.env.DEV) {
        return (
            <div className="row g-4">

                <h3 className="text-center">This feature is disabled in for now</h3>

            </div>
        )
    }


    return (
        <div className="row g-4">

            <div className="col-md-6 mx-auto">
                <Card className='border shadow'>

                    <Card.Header className='mx-0 px-0'>
                        <h5 className="card-header-title text-center bg-light my-3 py-3">Mayank Gupta</h5>
                    </Card.Header>
                    <Card.Body>

                        <div className="text-center fs-5 mb-4 font-monospace">
                            <p className="mb-0">Order ID: ORDER_00005555</p>
                            <h3 className="fw-medium mb-5">INR 20.00</h3>
                        </div>

                        <div className="text-center my-4">
                            <img src="https://i.ibb.co/WFD9ybW/image.png" className="img-fluid" alt="QR Code" width={275} />
                        </div>

                        {/* draw line and OR on that line in between */}
                        <div className="d-block d-md-none d-flex justify-content-center align-items-center">
                            <p className="">Or Direct Via App</p>
                        </div>

                        <div className="list-inline mb-0 mt-0 d-flex justify-content-center align-items-center justify-content-around d-block d-md-none mb-4">

                            <img src="/static/assets/images/phonepe_icon.png" className="h-50px bg-primary rounded border shadow" alt="PhonePe" />
                            <img src="/static/assets/images/gpay_icon.png" className="h-50px bg-white rounded border shadow" alt="Google Pay" />
                            <img src="/static/assets/images/paytm_icon.png" className="h-50px rounded border shadow" alt="Paytm" />
                            <img src="/static/assets/images/upi_icon.png" className="h-50px bg-white rounded border shadow" alt="UPI" />

                        </div>

                        <div className="text-center fw-semibold">
                            <p className="fs-5 mt-3">Scan and pay with PhonePe, Paytm and Google Pay only.</p>
                        </div>

                        <div className="text-center">
                            <img src="/static/assets/images/bhim_trans.png" alt="BHIM" />
                        </div>

                        <ul className="list-inline mb-0 mt-3 d-flex justify-content-center align-items-center justify-content-around">
                            <img src="/static/assets/images/gpay_trans.png" className="h-30px h-md-50px me-3" alt="Google Pay" />
                            <img src="/static/assets/images/phonepe_trans.png" className="h-30px h-md-40px me-3" alt="PhonePe" />
                            <img src="/static/assets/images/paytm_trans.png" className="h-20px h-md-30px" alt="Paytm" />
                        </ul>



                    </Card.Body>

                </Card>

            </div>


        </div>
    )
}

export default PublicDashboardPaymentManually;