import { useMutationAdminGetUser } from '../../../../api/admin';
import KeyValue from '../../Components/KeyValue';


function AdminGetUserDetails() {

    const { data, isLoading, mutate } = useMutationAdminGetUser();

    function onSubmit(e) {
        e.preventDefault();

        if (e.target.email && e.target.email.value !== "") {
            mutate({
                email: e.target.email.value,
            });
            return;
        }
        if (e.target.user_id && e.target.user_id.value !== "") {
            mutate({
                user_id: e.target.user_id.value,
            });
            return;
        }

    }


    return (
    <>
        <div className="row mb-4">
            <div className="col-md-6 mx-auto">
                <div className="card border">
                    <div className="card-header border-bottom">
                        <h5 className="card-header-title">Search Phone.</h5>
                        <p className="mb-0 small">Get all accounts with phone number.</p>
                    </div>
                    {/* <!-- Card body START --> */}
                    <form onSubmit={onSubmit}>
                        <div className="card-body">

                            <div className="mb-3">
                                <label className="form-label">Email</label>
                                <input type="text" name="email" className="form-control" placeholder="Email"/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">User ID</label>
                                <input type="text" name="user_id" className="form-control" placeholder="User ID"/>
                            </div>

                        </div>
                        <div className="card-footer border-top">
                            <div className="text-center d-flex justify-content-around">
                                <button className="btn btn-primary fs-5">
                                    <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{display: !isLoading? 'None': ""}}></span>
                                    <span role="status">Check</span>
                                </button>
                                {/* <button className="btn btn-primary fs-5" type="button" onClick={()=>{refetch()}}>
                                    <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{display: !isRefetching? 'None': ""}}></span>
                                    <span role="status">Refresh</span>
                                </button> */}
                            </div>
                        </div>
                        
                    </form>
                    {/* <!-- Card body END --> */}
                </div>
            </div>
        </div>

        {
            data && !Array.isArray(data) && <KeyValue data={
                {
                    ...data,
                    profile: data.profile? "Created" : "Not Created",
                }
            } />
        }
    </ >
    )
}


export default AdminGetUserDetails;