import { Link } from 'react-router-dom'
import AuthContext from '../../context/auth';
import { useContext } from 'react';

import { useUserStats, useUser } from '../../api/basic';


function SignIN() {
	return (
		<li className="nav-item ms-3">
			<Link to="/login" className="btn btn-sm btn-primary-soft mb-0" ><i className="fa-solid fa-right-to-bracket me-2"></i>Sign Up</Link>
		</li>
	)
}

function ProfileDropdown() {
	const { isLogin, user, logout } = useContext(AuthContext);
	const userInfo = useUser(isLogin)

	if (!isLogin) {
		return <SignIN />
	}

	function get_avatar_url() {
		if (userInfo.isLoading) {
			return ""
		}
		if (userInfo.data?.avatar) {
			return userInfo.data.avatar
		}
		if (userInfo.data?.profile) {
			return `https://cdn.statically.io/avatar/${userInfo.data.profile.full_name}`
		}
		return `https://cdn.statically.io/avatar/${user.full_name}`
	}

	const userstats = useUserStats(isLogin)


	return (
		<li className="nav-item ms-3 dropdown">
			{/* <!-- Avatar --> */}
			<a className="avatar avatar-sm p-0" id="profileDropdown" role="button" data-bs-auto-close="outside" data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false">
				<img className="avatar-img rounded-2" src={userInfo.isLoading ? "" : get_avatar_url()} referrerPolicy="no-referrer" alt="avatar" />
			</a>

			<ul className="dropdown-menu dropdown-animation dropdown-menu-end shadow pt-3" aria-labelledby="profileDropdown">
				{/* <!-- Profile info --> */}
				<li className="px-3 mb-3">
					<div className="d-flex align-items-center">
						{/* <!-- Avatar --> */}
						<div className="avatar me-3">
							<img className="avatar-img rounded-circle shadow" src={userInfo.isLoading ? "" : get_avatar_url()} alt="avatar" referrerPolicy="no-referrer" />
						</div>
						<div>
							<a className="h6 mt-2 mt-sm-0">{userInfo.isLoading ? "" : (userInfo.data?.profile ? userInfo.data?.profile.full_name : userInfo.data?.full_name)}</a>
							<p className="small m-0">{user.email}</p>
							<p className="small m-0">Balance: {userstats.isLoading ? "-" : userstats.data?.user_balance}</p>
							<p className="small m-0">User ID: {user.id}</p>
						</div>
					</div>
				</li>






				{/* <!-- Links --> */}
				<li> <hr className="dropdown-divider" /></li>
				<li><Link className="dropdown-item" to="/u/index" ><i className="bi bi-bookmark-check fa-fw me-2"></i>Dashboard</Link></li>
				<li><Link className="dropdown-item" to="/u/all_cards" ><i className="bi bi-heart fa-fw me-2"></i>All Cards</Link></li>
				<li><Link className="dropdown-item" to="/u/add_money" ><i className="bi bi-info-circle fa-fw me-2"></i>Add Money</Link></li>
				<li><Link className="dropdown-item" to="/u/settings" ><i className="bi bi-gear fa-fw me-2"></i>Settings</Link></li>
				<li><Link className="dropdown-item bg-danger-soft-hover" to="/login" onClick={logout} ><i className="bi bi-power fa-fw me-2"></i>Sign Out</Link></li>
			</ul>
		</li>
	)
}

function NotificationDropdown() {

	return ""

	return (
		<li className="nav-item dropdown ms-3 ms-md-3">
			{/* <!-- Notification button --> */}
			<a className="nav-notification btn btn-light p-0 mb-0" href="hero-image-gallery.html#" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
				<i className="bi bi-bell fa-fw"></i>
			</a>
			{/* <!-- Notification dote --> */}
			<span className="notif-badge animation-blink"></span>

			{/* <!-- Notification dropdown menu START --> */}
			<div className="dropdown-menu dropdown-animation dropdown-menu-end dropdown-menu-size-md shadow-lg p-0">
				<div className="card bg-transparent">
					{/* <!-- Card header --> */}
					<div className="card-header bg-transparent d-flex justify-content-between align-items-center border-bottom">
						<h6 className="m-0">Notifications <span className="badge bg-danger bg-opacity-10 text-danger ms-2">4 new</span></h6>
						<a className="small" href="hero-image-gallery.html#">Clear all</a>
					</div>

					{/* <!-- Card body START --> */}
					<div className="card-body p-0">
						<ul className="list-group list-group-flush list-unstyled p-2">
							{/* <!-- Notification item --> */}
							<li>
								<a href="hero-image-gallery.html#" className="list-group-item list-group-item-action rounded notif-unread border-0 mb-1 p-3">
									<h6 className="mb-2">New! Booking flights from New York ✈️</h6>
									<p className="mb-0 small">Find the flexible ticket on flights around the world. Start searching today</p>
									<span>Wednesday</span>
								</a>
							</li>
							{/* <!-- Notification item --> */}
							<li>
								<a href="hero-image-gallery.html#" className="list-group-item list-group-item-action rounded border-0 mb-1 p-3">
									<h6 className="mb-2">Sunshine saving are here 🌞 save 30% or more on a stay</h6>
									<span>15 Nov 2022</span>
								</a>
							</li>
						</ul>
					</div>
					{/* <!-- Card body END --> */}

					{/* <!-- Card footer --> */}
					<div className="card-footer bg-transparent text-center border-top">
						<a href="hero-image-gallery.html#" className="btn btn-sm btn-link mb-0 p-0">See all incoming activity</a>
					</div>
				</div>
			</div>
			{/* <!-- Notification dropdown menu END --> */}
		</li>
	)
}



export { SignIN, ProfileDropdown, NotificationDropdown }