
var STUDENT_ID_FONTS_LOADED = false;
const PADDING = 25;
const SCHOOL_ID_FONT = "poppins";

export type typeInputData = {
	photo: File,
	logo: File,
	sign: File,
	auth_sign: File,

	school_name: string,
	school_sub_title: string,

	name: string,
	father: string,
	mother: string,
	class: string,
	dob: string,
	roll_no: string,

	address: string,

	phone: string,
	session: string,
	addmission_no: string,

	principal_sign_placeholder: string,

	school_contact: string,

	key_1: string,
	value_1: string,
	key_2: string,
	value_2: string,
	key_3: string,
	value_3: string,
	key_4: string,
	value_4: string,
	key_5: string,
	value_5: string,
	key_6: string,
	value_6: string,

	template: string,
	template_id: string,
	header_color: string,
	data_color: string,
	footer_color: string,


    // lic ["Name", "Division", "Branch", "Code Number", "DOB", "Branch Manager"], 

    division: string,
    branch: string,
    code_number: string,
    branch_manager: string,
    branch_address: string,
    
}

type typeTemplateSettings = {
	[key: string]: (canvas: HTMLCanvasElement) => {}
}

type typePosition = { pos: [number, number]; align: string; size: number; bold: boolean; colour: string };


type typeTemplateSettingsReturnType = {
	school_name_pos?: typePosition
	school_addr_pos?: typePosition
	school_logo_size?: [number, number],
	school_logo_pos?: [number, number],

	student_name_pos?: typePosition
	student_session_pos?: typePosition
	student_addmission_pos?: typePosition
	student_roll_no?: typePosition

	student_picture_size?: [number, number],
	student_picture_pos?: [number, number],

	principal_sign_size?: [number, number],
	principal_sign_pos?: [number, number],

	principal_sign_size1?: [number, number],
	principal_sign_pos1?: [number, number],

	principal_sign_title_pos?: typePosition
	principal_sign_title_pos1?: typePosition

	manager_name_pos?: typePosition
	manmager_title_pos?: typePosition

	student_data_pos: {pos:[number, number], align:string, size:number, bold:boolean, colour:string, auto?: boolean},
	student_data_max_len_size?: number,

	footer_data_pos?: {pos:[number, number], align:string, size:number, bold:boolean, colour:string}
}

const templateSettings:typeTemplateSettings = {
	id001: function (canvas: HTMLCanvasElement):typeTemplateSettingsReturnType {
		return {
			school_name_pos: {pos:[canvas.width/2, 180], align:"center", size:40, bold:true, colour:"#ffffff"},
			school_addr_pos: {pos:[canvas.width/2, 180], align:"center", size:35, bold:true, colour:"#ffffff"},
			school_logo_size: [100, 100],
			school_logo_pos: [(canvas.width/2)-50, 25],

			student_session_pos: {pos:[canvas.width/2, 540], align:"center", size:25, bold:false, colour:"#000000"},
			student_addmission_pos: {pos:[canvas.width-20, 215], align:"right", size:25, bold:false, colour:"#000000"},
			student_roll_no: {pos:[canvas.width/2, 215], align:"center", size:25, bold:false, colour:"#000000"},

			student_picture_size: [150, 150],
			student_picture_pos: [(canvas.width/2)-150/2, 335],

			student_data_pos: {pos:[100, 600], align:"", size:30, bold:false, colour:"#000000"},

			footer_data_pos: {pos:[canvas.width/2, canvas.height-25], align:"center", size:40, bold:true, colour:"#ffffff"},
		}
	},
	id002: function (canvas: HTMLCanvasElement):typeTemplateSettingsReturnType {
		return {
			school_name_pos: {pos:[(canvas.width/2) + 75, 50], align:"center", size:40, bold:true, colour:"#ffffff"},
			school_addr_pos: {pos:[(canvas.width/2) + 75, 50], align:"center", size:35, bold:true, colour:"#ffffff"},
			school_logo_size: [125, 125],
			school_logo_pos: [25, 25],

			student_session_pos: {pos:[canvas.width-20, 215], align:"right", size:25, bold:false, colour:"#000000"},
			student_addmission_pos: {pos:[70, 215], align:"left", size:25, bold:false, colour:"#000000"},
			student_roll_no: {pos:[canvas.width/2, 215], align:"center", size:25, bold:false, colour:"#000000"},

			student_picture_size: [130*2, 170*2],
			student_picture_pos: [50, 230],

			principal_sign_size: [120, 80],
			principal_sign_pos: [canvas.width-150, canvas.height-150],
			principal_sign_title_pos : {pos:[canvas.width-90, canvas.height-65], align:"center", size:20, bold:true, colour:"#000000"},

			student_data_pos: {pos:[130*2+50+25, 275], align:"", size:30, bold:false, colour:"#000000", auto: true},

			footer_data_pos: {pos:[canvas.width/2, canvas.height-15], align:"center", size:40, bold:true, colour:"#ffffff"},
		}
	},
	id003: function (canvas: HTMLCanvasElement):typeTemplateSettingsReturnType {
		return {
			school_name_pos: {pos:[(canvas.width/2) + 75, 50], align:"center", size:40, bold:true, colour:"#ffffff"},
			school_addr_pos: {pos:[(canvas.width/2) + 75, 50], align:"center", size:35, bold:true, colour:"#ffffff"},
			school_logo_size: [125, 125],
			school_logo_pos: [25, 25],

			student_session_pos: {pos:[canvas.width-50, 215], align:"right", size:25, bold:false, colour:"#000000"},
			student_addmission_pos: {pos:[70, 215], align:"left", size:25, bold:false, colour:"#000000"},
			student_roll_no: {pos:[canvas.width/2, 215], align:"center", size:25, bold:false, colour:"#000000"},

			student_picture_size: [130*2, 170*2],
			student_picture_pos: [50, 230],

			principal_sign_size: [120, 80],
			principal_sign_pos: [canvas.width-180, canvas.height-155],
			principal_sign_title_pos : {pos:[canvas.width-120, canvas.height-70], align:"center", size:20, bold:true, colour:"#000000"},


			student_data_pos: {pos:[130*2+50+25, 275], align:"", size:30, bold:false, colour:"#000000",  auto: true},

			footer_data_pos: {pos:[canvas.width/2, canvas.height-15], align:"center", size:40, bold:true, colour:"#ffffff"},
		}
	},
	id004: function (canvas: HTMLCanvasElement):typeTemplateSettingsReturnType {
		return {
			school_name_pos: {pos:[(canvas.width/2) + 75, 65], align:"center", size:40, bold:true, colour:"#000000"},
			school_addr_pos: {pos:[(canvas.width/2) + 75, 50], align:"center", size:35, bold:true, colour:"#000000"},
			school_logo_size: [150, 150],
			school_logo_pos: [25, 25],

			student_session_pos: {pos:[canvas.width-20, 230], align:"right", size:25, bold:false, colour:"#000000"},
			student_addmission_pos: {pos:[70, 230], align:"left", size:25, bold:false, colour:"#000000"},
			student_roll_no: {pos:[canvas.width/2, 230], align:"center", size:25, bold:false, colour:"#000000"},

			student_picture_size: [130*2, 170*2],
			student_picture_pos: [50, 240],

			principal_sign_size: [120, 80],
			principal_sign_pos: [canvas.width-150, canvas.height-150],
			principal_sign_title_pos : {pos:[canvas.width-90, canvas.height-65], align:"center", size:20, bold:true, colour:"#000000"},


			student_data_pos: {pos:[130*2+50+25, 275], align:"", size:30, bold:false, colour:"#000000",  auto: true},

			footer_data_pos: {pos:[canvas.width/2, canvas.height-15], align:"center", size:40, bold:true, colour:"#ffffff"},
		}
	},
	id005: function (canvas: HTMLCanvasElement):typeTemplateSettingsReturnType {
		return {
			school_name_pos: {pos:[(canvas.width/2) + 120, 45], align:"center", size:35, bold:true, colour:"#000000"},
			school_addr_pos: {pos:[(canvas.width/2) + 120, 0], align:"center", size:27, bold:true, colour:"#000000"},
			school_logo_size: [150, 150],
			school_logo_pos: [25, 25],

			student_session_pos: {pos:[canvas.width-20, 175], align:"right", size:25, bold:false, colour:"#000000"},
			student_addmission_pos: {pos:[70, 230], align:"left", size:25, bold:false, colour:"#000000"},
			student_roll_no: {pos:[canvas.width/2 + 10, 175], align:"center", size:25, bold:false, colour:"#000000"},

			student_picture_size: [130*2, 170*2],
			student_picture_pos: [50, 240],

			principal_sign_size: [120, 80],
			principal_sign_pos: [canvas.width-210, canvas.height-135],
			principal_sign_title_pos : {pos:[canvas.width-150, canvas.height-30], align:"center", size:20, bold:true, colour:"#000000"},


			student_data_pos: {pos:[130*2+50+100, 245], align:"", size:25, bold:false, colour:"#000000",  auto: true},

			footer_data_pos: {pos:[(canvas.width/3), canvas.height-15], align:"center", size:35, bold:true, colour:"#000000"},
		}
	},
	id007: function (canvas: HTMLCanvasElement):typeTemplateSettingsReturnType {
		return {
			school_name_pos: {pos:[canvas.width/2, 170], align:"center", size:36, bold:true, colour:"#000000"},
			school_addr_pos: {pos:[canvas.width/2, 180], align:"center", size:25, bold:true, colour:"#000000"},
			school_logo_size: [100, 100],
			school_logo_pos: [(canvas.width/2)-50, 35],

			student_session_pos: {pos:[(canvas.width-210)/2, 465], align:"center", size:25, bold:false, colour:"#000000"},
			student_addmission_pos: {pos:[canvas.width-225, 430], align:"right", size:25, bold:false, colour:"#000000"},
			student_roll_no: {pos:[25, 430], align:"left", size:25, bold:false, colour:"#000000"},

			student_picture_size: [200, 200],
			student_picture_pos: [(canvas.width-210), 300],


			principal_sign_size: [120, 80],
			principal_sign_pos: [canvas.width-150, canvas.height-175],
			principal_sign_title_pos : {pos:[canvas.width-35, canvas.height-75], align:"right", size:20, bold:true, colour:"#000000"},


			principal_sign_size1: [120, 80],
			principal_sign_pos1: [100, canvas.height-135],
			principal_sign_title_pos1 : {pos:[150, canvas.height-30], align:"center", size:20, bold:true, colour:"#000000"},



			student_data_pos: {pos:[100, 550], align:"", size:30, bold:false, colour:"#000000"},

			footer_data_pos: {pos:[canvas.width/2, canvas.height-20], align:"center", size:40, bold:true, colour:"#ffffff"},
		}
	},
	id010: function ():typeTemplateSettingsReturnType {
		return {
			student_picture_size: [130*2, 170*2],
			student_picture_pos: [50, 160],

			student_data_pos: {pos:[130*2+50+25, 180], align:"", size:30, bold:false, colour:"#000000",  auto: true},

		}
	},
	be001: function (canvas: HTMLCanvasElement):typeTemplateSettingsReturnType {
		return {
			school_name_pos: {pos:[canvas.width/2, 170], align:"center", size:30, bold:true, colour:"#000000"},
			school_addr_pos: {pos:[canvas.width/2, 180], align:"center", size:25, bold:true, colour:"#000000"},
			school_logo_size: [100, 100],
			school_logo_pos: [(canvas.width/2)-50, 25],

			student_session_pos: {pos:[(canvas.width-210)/2, 410], align:"center", size:25, bold:false, colour:"#000000"},
			student_addmission_pos: {pos:[canvas.width-225, 410], align:"right", size:25, bold:false, colour:"#000000"},
			student_roll_no: {pos:[25, 410], align:"left", size:25, bold:false, colour:"#000000"},

			student_picture_size: [200, 200],
			student_picture_pos: [(canvas.width-210), 265],


			principal_sign_size: [120, 80],
			principal_sign_pos: [canvas.width-210, canvas.height-135],
			principal_sign_title_pos : {pos:[canvas.width-180, canvas.height-30], align:"center", size:20, bold:true, colour:"#000000"},


			principal_sign_size1: [120, 80],
			principal_sign_pos1: [100, canvas.height-135],
			principal_sign_title_pos1 : {pos:[150, canvas.height-30], align:"center", size:20, bold:true, colour:"#000000"},

			student_data_pos: {pos:[80, 520], align:"", size:30, bold:false, colour:"#000000"},

			footer_data_pos: {pos:[canvas.width/2, canvas.height-25], align:"center", size:40, bold:true, colour:"#ffffff"},
		}
	},
	li001: function (canvas: HTMLCanvasElement):typeTemplateSettingsReturnType {
		return {
			student_name_pos: {pos:[1000 , 545], align:"right", size:40, bold:true, colour:"#000000"},
			student_session_pos: {pos:[650 , 190], align:"right", size:25, bold:false, colour:"#000000"},
			student_addmission_pos: {pos:[70, 230], align:"left", size:25, bold:false, colour:"#000000"},
			student_roll_no: {pos:[canvas.width/4, 230], align:"center", size:25, bold:false, colour:"#000000"},

			student_picture_size: [130*2, 170*2],
			student_picture_pos: [canvas.width - (130*2) - 25, 175],

			student_data_pos: {pos:[50+25, 230], align:"", size:30, bold:false, colour:"#000000"},
			student_data_max_len_size: 450,


			manager_name_pos : {pos:[30, canvas.height-105], align:"left", size:25, bold:false, colour:"#000000"},
			manmager_title_pos : {pos:[30, canvas.height-80], align:"left", size:20, bold:true, colour:"#000000"},

			footer_data_pos: {pos:[canvas.width/2, canvas.height-15], align:"center", size:25, bold:true, colour:"#000000"},
		}
	}
}
templateSettings["id008"] = templateSettings["id007"];
templateSettings["id009"] = templateSettings["id007"];


async function loadFontsFn() {
	if (STUDENT_ID_FONTS_LOADED) return;
	const normal = new FontFace("poppins", "url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecg.woff2)", {
		style: "normal",
		weight: "400"
	});
	const medium = new FontFace("poppins", "url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2)", {
		style: "normal",
		weight: "500"
	});
	const semibold = new FontFace("poppins", "url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2)", {
		style: "normal",
		weight: "600"
	});
	const bold = new FontFace("poppins", "url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2)", {
		style: "normal",
		weight: "700"
	});
	const extrabold = new FontFace("poppins", "url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4Z1xlFQ.woff2)", {
		style: "normal",
		weight: "800"
	});

	await normal.load();
	await medium.load();
	await bold.load();
	await semibold.load();
	await extrabold.load();

	document.fonts.add(normal);
	document.fonts.add(medium);
	document.fonts.add(bold);
	document.fonts.add(semibold);
	document.fonts.add(extrabold);

	STUDENT_ID_FONTS_LOADED = true;
}

type typeWrapTextCustom = {
	context: CanvasRenderingContext2D,
	text: string,
	x: number,
	y: number,
	maxWidth: number,
	bold: boolean,
	fontSize: number,
	fontFace: string
}

function wrapTextCustom(args: typeWrapTextCustom) {
	let {context, text, x, y, maxWidth, bold, fontSize, fontFace} = args;

	var words = text.split(' ');
	var line = '';
	var lineHeight = fontSize+5;

	const font_thing = fontSize + "px " + fontFace;

	if (bold) {
		context.font = "bold " + font_thing;
	} else {
		context.font = font_thing
	}

	for (var n = 0; n < words.length; n++) {
		var testLine = line + words[n] + ' ';
		var metrics = context.measureText(testLine);
		var testWidth = metrics.width;
		if (testWidth > maxWidth) {
			context.fillText(line, x, y);
			line = words[n] + ' ';
			y += lineHeight;
		} else {
			line = testLine;
		}
	}
	context.fillText(line, x, y);
	return (y+lineHeight+5);
}

type typeWriteText = {
	canvas: HTMLCanvasElement,
	text: string | [string, string][],
	pos: typePosition,
	lastY: number,
	NmaxWidth: number
}


function writeText(args :typeWriteText) {
	let {canvas, text, pos, lastY, NmaxWidth} = args;

	if (!text) return lastY;
	var ctx = canvas.getContext("2d");
	if (!ctx) return;

	if (!pos) {
		return lastY;
	}
	let [[x, y], center, fontSize, bold, fontColour] = Object.values(pos) as [[number, number], string, number, boolean, string];
	ctx.fillStyle = fontColour;

	var maxWidth = 0;

	if (center == "center") {
		ctx.textAlign = center;
		maxWidth = canvas.width-PADDING + ((canvas.width/2 - x)*2);
	} else if (center == "right" || center == "left") {
		ctx.textAlign = center;
		maxWidth = canvas.width;
	} else {
		ctx.textAlign = "start";
		maxWidth = canvas.width-PADDING-x;
	}

	if (Array.isArray(text)) {
		ctx.font = "bold " + fontSize + "px " + SCHOOL_ID_FONT;
		let MAX_HEADING = Math.max(...text.map(function(n){return ctx!.measureText(n[0]).width})) + 20;
		let MIDDLE_SPACE = ctx.measureText(`:  `).width;
		for (let [key, value] of text) {
			if (!value) continue;
			ctx.font = "bold " + fontSize + "px " + SCHOOL_ID_FONT;
			ctx.fillText(`${key}`, x, y);
			ctx.fillText(`:  `, x+MAX_HEADING, y);

			maxWidth = NmaxWidth || (canvas.width - (x+MAX_HEADING+MIDDLE_SPACE) - PADDING)
			y = wrapTextCustom({
				context: ctx,
				text: `${value}`,
				x: x+MAX_HEADING+MIDDLE_SPACE,
				y: y,
				maxWidth: maxWidth,
				bold: bold,
				fontSize: fontSize,
				fontFace: SCHOOL_ID_FONT
			})
		}
		return y
	} else {
		return wrapTextCustom({
			context: ctx,
			text: text,
			x: x,
			y: lastY || y,
			maxWidth: maxWidth,
			bold: bold,
			fontSize: fontSize,
			fontFace: SCHOOL_ID_FONT
		})
		
	}
}

type typeRoundedImage = {
	ctx: CanvasRenderingContext2D,
	x: number,
	y: number,
	width: number,
	height: number,
	radius: number
}

function roundedImage(args: typeRoundedImage):void {
	let {ctx, x, y, width, height, radius} = args;

	ctx.beginPath();
	ctx.moveTo(x + radius, y);
	ctx.lineTo(x + width - radius, y);
	ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
	ctx.lineTo(x + width, y + height - radius);
	ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
	ctx.lineTo(x + radius, y + height);
	ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
	ctx.lineTo(x, y + radius);
	ctx.quadraticCurveTo(x, y, x + radius, y);
	ctx.closePath();
}

type typePutImage = {
	canvas: HTMLCanvasElement,
	img: HTMLImageElement,
	size: [number, number],
	pos: [number, number],
	round: boolean
}

function putImage(args: typePutImage): number {

	let {canvas, img, size, pos, round} = args;

	const ctx = canvas.getContext("2d");
	if (!ctx) return 0;

	let [x, y] = pos;
	let [width, height] = resize(img, ...size);

	if (round) {
		let img_canvas = document.createElement('canvas');
		img_canvas.width = width;
		img_canvas.height = height;

		let img_ctx = img_canvas.getContext("2d");
		if (!img_ctx) return 0;

		// ctx.save();
		roundedImage({ctx: img_ctx, x: 0, y: 0, width, height, radius: 20});
		img_ctx.clip();
		img_ctx.drawImage(img, 0, 0, width, height);
		img_ctx.restore();
		ctx.drawImage(img_canvas, x, y, width, height);

	} else {
		ctx.drawImage(img, x, y, width, height);
	}

	return width
}


function resize(img: HTMLImageElement, width:number, height:number): [number, number] {
	let [real_width, real_height] = [img.naturalWidth, img.naturalHeight];

	var approx_width = width;
	var approx_height = Number(real_height*(approx_width/real_width));

	if (approx_height > height) {
		approx_height = height;
		approx_width = Number(real_width*(approx_height/real_height));
	}
	return [approx_width, approx_height]
}

const templates = {
	"id": async function(canvas: HTMLCanvasElement, userInputs: typeInputData, templateSettingsName: string) {
		await loadFontsFn();

		let HEADER_FONT_COLOUR = userInputs.header_color.toLocaleUpperCase();
		let DATA_FONT_COLOUR = userInputs.data_color.toLocaleUpperCase();
		let FOOTER_FONT_COLOUR = userInputs.footer_color.toLocaleUpperCase();

		var current_id_settings = templateSettings[templateSettingsName](canvas) as typeTemplateSettingsReturnType;

		if (HEADER_FONT_COLOUR != "#6F6F6F" && current_id_settings.school_name_pos && current_id_settings.school_addr_pos) {
			current_id_settings.school_name_pos.colour = HEADER_FONT_COLOUR;
			current_id_settings.school_addr_pos.colour = HEADER_FONT_COLOUR;
		}
		if (FOOTER_FONT_COLOUR != "#6F6F6F" && current_id_settings.footer_data_pos) {
			current_id_settings.footer_data_pos.colour = FOOTER_FONT_COLOUR;
		}
		if (DATA_FONT_COLOUR != "#6F6F6F" && current_id_settings.student_data_pos && current_id_settings.student_session_pos && current_id_settings.student_roll_no && current_id_settings.student_addmission_pos) {
			current_id_settings.student_data_pos.colour = DATA_FONT_COLOUR;
			current_id_settings.student_session_pos.colour = DATA_FONT_COLOUR;
			current_id_settings.student_roll_no.colour = DATA_FONT_COLOUR;
			current_id_settings.student_addmission_pos.colour = DATA_FONT_COLOUR;
		}

		if (!(current_id_settings.school_addr_pos && current_id_settings.school_name_pos)) {
			return;
		}

		// Setting school name
		let school_name = userInputs.school_name;
		let lastY = writeText({canvas: canvas, text: school_name, pos: current_id_settings.school_name_pos, lastY: 0, NmaxWidth: 0});
		let school_place = userInputs.school_sub_title;
		writeText({canvas: canvas, text: school_place, pos: current_id_settings.school_addr_pos, lastY: lastY || 0, NmaxWidth: 0});

		var SCHOOL_ID_USER_IMG = new Image();
		SCHOOL_ID_USER_IMG.crossOrigin="anonymous"
		// SCHOOL_ID_USER_IMG.src = "./user.png";
		var SCHOOL_ID_SCHOOL_LOGO = new Image();
		SCHOOL_ID_SCHOOL_LOGO.crossOrigin="anonymous"
		// SCHOOL_ID_SCHOOL_LOGO.src = "./school.png";
		var SCHOOL_PRINCIPAL_SIGN = new Image();
		SCHOOL_PRINCIPAL_SIGN.crossOrigin="anonymous"

		SCHOOL_ID_SCHOOL_LOGO.onload = () => {
			if (!current_id_settings.school_logo_size || !current_id_settings.school_logo_pos) return;
			putImage({
				canvas: canvas,
				img: SCHOOL_ID_SCHOOL_LOGO,
				size: current_id_settings.school_logo_size,
				pos: current_id_settings.school_logo_pos,
				round: true
			});
		}

		SCHOOL_ID_USER_IMG.onload = () => {
			if (!current_id_settings.student_picture_size || !current_id_settings.student_picture_pos) return;

			let image_width = putImage({
				canvas: canvas,
				img: SCHOOL_ID_USER_IMG,
				size: current_id_settings.student_picture_size,
				pos: current_id_settings.student_picture_pos,
				round: true
			});
			let moved_by = current_id_settings.student_picture_size[0] - image_width;

			let data:[string, string][] = [];

			data.push(["Name", userInputs.name]);
			data.push(["Father", userInputs.father]);
			data.push(["Mother", userInputs.mother]);
			data.push(["Class", userInputs.class]);
			data.push(["DOB", userInputs.dob]);
			data.push(["Address", userInputs.address]);
			data.push(["Phone", userInputs.phone]);
			data.push([userInputs.key_1, userInputs.value_1]);
			data.push([userInputs.key_2, userInputs.value_2]);
			data.push([userInputs.key_3, userInputs.value_3]);
			data.push([userInputs.key_4, userInputs.value_4]);
			data.push([userInputs.key_5, userInputs.value_5]);
			data.push([userInputs.key_6, userInputs.value_6]);

			if (current_id_settings.student_data_pos.auto == true) {
				current_id_settings.student_data_pos.pos[0] -= moved_by;
			}

			writeText({canvas: canvas, text: data, pos: current_id_settings.student_data_pos, lastY: 0, NmaxWidth: 0});

		}

		SCHOOL_PRINCIPAL_SIGN.onload = () => {
			if (!current_id_settings.principal_sign_size || !current_id_settings.principal_sign_pos) return;
			putImage({
				canvas: canvas,
				img: SCHOOL_PRINCIPAL_SIGN,
				size: current_id_settings.principal_sign_size,
				pos: current_id_settings.principal_sign_pos,
				round: true
			})

			if (current_id_settings.principal_sign_title_pos) {
				let principal_sign_placeholder = userInputs.principal_sign_placeholder || "Principal Sign";
				writeText({canvas: canvas, text: principal_sign_placeholder, pos: current_id_settings.principal_sign_title_pos, lastY: 0, NmaxWidth: 0});
			}
		}

		let student_profile_obj = userInputs.photo;
		let school_logo = userInputs.logo;
		let principal_sign = userInputs.sign;

		if (principal_sign) {
			SCHOOL_PRINCIPAL_SIGN.src = URL.createObjectURL(principal_sign);
		}

		if (student_profile_obj) {
			SCHOOL_ID_USER_IMG.src = URL.createObjectURL(student_profile_obj);
		} else {
			SCHOOL_ID_USER_IMG.src = "https://framerusercontent.com/images/U2DqsmOjQFFStSthfxTHj2I80.jpg";
		}

		if (school_logo) {
			SCHOOL_ID_SCHOOL_LOGO.src = URL.createObjectURL(school_logo);
		} else {
			SCHOOL_ID_SCHOOL_LOGO.src = "https://framerusercontent.com/images/P45MP1ywPPaHkvdkq3VhLFr4Q.png";
		}

		let addmission_no = userInputs.addmission_no;
		if (addmission_no && current_id_settings.student_addmission_pos) {
			writeText({canvas: canvas, text: `Admission No. ${addmission_no}`, pos: current_id_settings.student_addmission_pos, lastY: 0, NmaxWidth: 0});
		}

		let roll_no = userInputs.roll_no;
		if (roll_no && current_id_settings.student_roll_no) {
			writeText({canvas: canvas, text: `Roll No: ${roll_no}`, pos: current_id_settings.student_roll_no, lastY: 0, NmaxWidth: 0});
		}

		let session = userInputs.session;
		if (session && current_id_settings.student_session_pos) {
			writeText({canvas: canvas, text: `Session : ${session}`, pos: current_id_settings.student_session_pos, lastY: 0, NmaxWidth: 0});
		}

		let footer_data = userInputs.school_contact;
		if (footer_data && current_id_settings.footer_data_pos) {
			writeText({canvas: canvas, text: footer_data, pos: current_id_settings.footer_data_pos, lastY: 0, NmaxWidth: 0});
		}
	},
	"li": async function(canvas: HTMLCanvasElement, userInputs: typeInputData, templateSettingsName: string) {
		await loadFontsFn();

		var current_id_settings = templateSettings[templateSettingsName](canvas) as typeTemplateSettingsReturnType;

		var SCHOOL_ID_USER_IMG = new Image();
		SCHOOL_ID_USER_IMG.crossOrigin = "anonymous";

		SCHOOL_ID_USER_IMG.onload = () => {
			if (!current_id_settings.student_picture_size || !current_id_settings.student_picture_pos) return;

			let image_width = putImage({
				canvas: canvas,
				img: SCHOOL_ID_USER_IMG,
				size: current_id_settings.student_picture_size,
				pos: current_id_settings.student_picture_pos,
				round: true,
			});

			let moved_by = current_id_settings.student_picture_size[0] - image_width;

			let data: [string, string][] = [];

			data.push(["Branch", userInputs.branch]);
			data.push(["Division", userInputs.division]);
			data.push(["Code No.", userInputs.code_number]);
			data.push(["DOB", userInputs.dob]);
			data.push(["Address", userInputs.address]);
			data.push(["Phone", userInputs.phone]);
			data.push([userInputs.key_1, userInputs.value_1]);
			data.push([userInputs.key_2, userInputs.value_2]);
			data.push([userInputs.key_3, userInputs.value_3]);
			data.push([userInputs.key_4, userInputs.value_4]);
			data.push([userInputs.key_5, userInputs.value_5]);
			data.push([userInputs.key_6, userInputs.value_6]);

			if (current_id_settings.student_data_pos.auto == true) {
				current_id_settings.student_data_pos.pos[0] -= moved_by;
			}

            if (!current_id_settings.student_data_max_len_size) return 0;

			writeText({
				canvas: canvas,
				text: data,
				pos: current_id_settings.student_data_pos,
				lastY: 0,
				NmaxWidth: current_id_settings.student_data_max_len_size,
			});
		};

		let student_profile_obj = userInputs.photo;

		if (student_profile_obj) {
			SCHOOL_ID_USER_IMG.src = URL.createObjectURL(student_profile_obj);
		} else {
			SCHOOL_ID_USER_IMG.src = "https://framerusercontent.com/images/U2DqsmOjQFFStSthfxTHj2I80.jpg";
		}

		let session = userInputs.session;
		if (session && current_id_settings.student_session_pos) {
			writeText({
				canvas: canvas,
				text: `Session : ${session}`,
				pos: current_id_settings.student_session_pos,
				lastY: 0,
				NmaxWidth: 0,
			});
		}

		let name = userInputs.name;
		if (name && current_id_settings.student_name_pos) {
			writeText({ canvas: canvas, text: `${name}`, pos: current_id_settings.student_name_pos, lastY: 0, NmaxWidth: 0 });
		}

		let branch_manager = userInputs.branch_manager;
		if (branch_manager && current_id_settings.manager_name_pos && current_id_settings.manmager_title_pos) {
			writeText({ canvas: canvas, text: `${branch_manager}`, pos: current_id_settings.manager_name_pos, lastY: 0, NmaxWidth: 0 });
			writeText({ canvas: canvas, text: `Branch Manager`, pos: current_id_settings.manmager_title_pos, lastY: 0, NmaxWidth: 0 });
		}

		let footer_data = userInputs.branch_address;
		if (footer_data && current_id_settings.footer_data_pos) {
			writeText({ canvas: canvas, text: footer_data, pos: current_id_settings.footer_data_pos, lastY: 0, NmaxWidth: 0 });
		}

	},
    "be": async function(canvas: HTMLCanvasElement, userInputs: typeInputData, templateSettingsName: string) {
        await loadFontsFn();

        let HEADER_FONT_COLOUR = userInputs.header_color.toLocaleUpperCase();
        let DATA_FONT_COLOUR = userInputs.data_color.toLocaleUpperCase();
        let FOOTER_FONT_COLOUR = userInputs.footer_color.toLocaleUpperCase();

        let current_id_settings = templateSettings[templateSettingsName](canvas) as typeTemplateSettingsReturnType;

        if (HEADER_FONT_COLOUR != "#6F6F6F" && current_id_settings.school_name_pos && current_id_settings.school_addr_pos) {
            current_id_settings.school_name_pos.colour = HEADER_FONT_COLOUR;
            current_id_settings.school_addr_pos.colour = HEADER_FONT_COLOUR;
        }
        if (FOOTER_FONT_COLOUR != "#6F6F6F" && current_id_settings.footer_data_pos) {
            current_id_settings.footer_data_pos.colour = FOOTER_FONT_COLOUR;
        }
        if (DATA_FONT_COLOUR != "#6F6F6F" && current_id_settings.student_data_pos && current_id_settings.student_session_pos) {
            current_id_settings.student_data_pos.colour = DATA_FONT_COLOUR;
            current_id_settings.student_session_pos.colour = DATA_FONT_COLOUR;
        }

        if (!(current_id_settings.school_addr_pos && current_id_settings.school_name_pos)) {
            return;
        }

        // Setting school name
        let school_name = userInputs.school_name;
        let lastY = writeText({canvas: canvas, text: school_name, pos: current_id_settings.school_name_pos, lastY: 0, NmaxWidth: 0});
        let school_place = userInputs.school_sub_title;
        writeText({canvas: canvas, text: school_place, pos: current_id_settings.school_addr_pos, lastY: lastY || 0, NmaxWidth: 0});

        var SCHOOL_ID_USER_IMG = new Image();
        SCHOOL_ID_USER_IMG.crossOrigin="anonymous"

        var SCHOOL_ID_SCHOOL_LOGO = new Image();
        SCHOOL_ID_SCHOOL_LOGO.crossOrigin="anonymous"

        var SCHOOL_PRINCIPAL_SIGN = new Image();
        SCHOOL_PRINCIPAL_SIGN.crossOrigin="anonymous"

        var SCHOOL_PRINCIPAL_SIGN1 = new Image();
        SCHOOL_PRINCIPAL_SIGN1.crossOrigin="anonymous"

        SCHOOL_ID_SCHOOL_LOGO.onload = () => {
            if (!current_id_settings.school_logo_size || !current_id_settings.school_logo_pos) return;
            putImage({
                canvas: canvas,
                img: SCHOOL_ID_SCHOOL_LOGO,
                size: current_id_settings.school_logo_size,
                pos: current_id_settings.school_logo_pos,
                round: true
            });
        }

        SCHOOL_ID_USER_IMG.onload = () => {
            if (!current_id_settings.student_picture_size || !current_id_settings.student_picture_pos) return;

            let image_width = putImage({
                canvas: canvas,
                img: SCHOOL_ID_USER_IMG,
                size: current_id_settings.student_picture_size,
                pos: current_id_settings.student_picture_pos,
                round: true
            });

            let moved_by = current_id_settings.student_picture_size[0] - image_width;

            let data:[string, string][] = [];

            data.push(["Name", userInputs.name]);
            data.push(["Father", userInputs.father]);
            data.push(["DOB", userInputs.dob]);
            data.push(["Address", userInputs.address]);
            data.push(["Phone", userInputs.phone]);
			data.push([userInputs.key_1, userInputs.value_1]);
			data.push([userInputs.key_2, userInputs.value_2]);
			data.push([userInputs.key_3, userInputs.value_3]);
			data.push([userInputs.key_4, userInputs.value_4]);
			data.push([userInputs.key_5, userInputs.value_5]);
			data.push([userInputs.key_6, userInputs.value_6]);

            if (current_id_settings.student_data_pos.auto == true) {
                current_id_settings.student_data_pos.pos[0] -= moved_by;
            }

            writeText({canvas: canvas, text: data, pos: current_id_settings.student_data_pos, lastY: 0, NmaxWidth: 0});
        }

        SCHOOL_PRINCIPAL_SIGN.onload = () => {
            if (!current_id_settings.principal_sign_size || !current_id_settings.principal_sign_pos || !current_id_settings.principal_sign_title_pos) return;
            putImage({
                canvas: canvas,
                img: SCHOOL_PRINCIPAL_SIGN,
                size: current_id_settings.principal_sign_size,
                pos: current_id_settings.principal_sign_pos,
                round: false
            });
            writeText({canvas: canvas, text: `Signature of the applicant`, pos: current_id_settings.principal_sign_title_pos, lastY: 0, NmaxWidth: 0});
        }

        SCHOOL_PRINCIPAL_SIGN1.onload = () => {
            if (!current_id_settings.principal_sign_size1 || !current_id_settings.principal_sign_pos1 || !current_id_settings.principal_sign_title_pos1) return;
            putImage({
                canvas: canvas,
                img: SCHOOL_PRINCIPAL_SIGN1,
                size: current_id_settings.principal_sign_size1,
                pos: current_id_settings.principal_sign_pos1,
                round: false
            });
            writeText({canvas: canvas, text: `Authorised Signatory`, pos: current_id_settings.principal_sign_title_pos1, lastY: 0, NmaxWidth: 0});
        }

        let student_profile_obj = userInputs.photo;
        let school_logo = userInputs.logo;
        let principal_sign = userInputs.sign;
        let principal_sign1 = userInputs.auth_sign;

        if (principal_sign) {
            SCHOOL_PRINCIPAL_SIGN.src = URL.createObjectURL(principal_sign);
        }

        if (principal_sign1) {
            SCHOOL_PRINCIPAL_SIGN1.src = URL.createObjectURL(principal_sign1);
        }

        if (student_profile_obj) {
            SCHOOL_ID_USER_IMG.src = URL.createObjectURL(student_profile_obj);
        } else {
            SCHOOL_ID_USER_IMG.src = "https://framerusercontent.com/images/U2DqsmOjQFFStSthfxTHj2I80.jpg";
        }

        if (school_logo) {
            SCHOOL_ID_SCHOOL_LOGO.src = URL.createObjectURL(school_logo);
        } else {
            SCHOOL_ID_SCHOOL_LOGO.src = "https://framerusercontent.com/images/P45MP1ywPPaHkvdkq3VhLFr4Q.png";
        }

        let session = userInputs.session;
        if (session && current_id_settings.student_session_pos) {
            writeText({canvas: canvas, text: `Session : ${session}`, pos: current_id_settings.student_session_pos, lastY: 0, NmaxWidth: 0});
        }
    }
} 


async function schoolIdUpdate(canvas: HTMLCanvasElement, userInputs:typeInputData) {
	const SCHOOL_ID_BACKGROUND_IMG = new Image();
	const ctx = canvas.getContext("2d");
	if (!ctx) return;

	SCHOOL_ID_BACKGROUND_IMG.crossOrigin="anonymous";
	SCHOOL_ID_BACKGROUND_IMG.src = userInputs.template;
	SCHOOL_ID_BACKGROUND_IMG.onload = async () => {
		canvas.height = SCHOOL_ID_BACKGROUND_IMG.naturalHeight;
		canvas.width = SCHOOL_ID_BACKGROUND_IMG.naturalWidth;
		ctx.drawImage(SCHOOL_ID_BACKGROUND_IMG, 0, 0);

		const template_id = userInputs.template_id;
		if (!template_id) return;

		const templateSettingsName = template_id.split("-")[0]
		const templateName = template_id.slice(0, 2);

		console.log(templateName, templateSettingsName);

		await templates[templateName](canvas, userInputs, templateSettingsName);
	}
}

export default schoolIdUpdate;
