

import { useMutation } from "react-query";
import axiosClient from "../hooks/useAxios";
import { AxiosError, AxiosResponse} from "axios";
import { server4XXError } from "./types";
import { handleErrors } from "./handleErrors";
import CustomAlert from "../helper/Alerts";

type typeLoginPayload = {
    credential: string
    client_id: string
    select_by: string
    acquired_by?: number
}

type typeLoginResponse = {
    token: string
}

async function loginGoogle(payload:typeLoginPayload): Promise<typeLoginResponse> {
    const response = await axiosClient.post<typeLoginPayload, AxiosResponse<typeLoginResponse>>(import.meta.env.VITE_API_URL + "/login/google/login", payload);
    return response.data;
}

function useMutationLoginGoogle() {
    return useMutation<typeLoginResponse, AxiosError<server4XXError>, typeLoginPayload>({
        mutationKey: "login",
        mutationFn: loginGoogle,
        onError: handleErrors,
        retry: 3,
    })
}

type typeLoginDirectPayload = {
    email: string
    password: string
    captcha: string
}

type typeSuccessResponse = {
    success: boolean,
}

async function loginDirect(payload: typeLoginDirectPayload): Promise<typeLoginResponse> {
    const response = await axiosClient.post<typeLoginDirectPayload, AxiosResponse<typeLoginResponse>>(import.meta.env.VITE_API_URL + "/login/direct/login", payload);
    return response.data;
}

function useMutationLoginDirect() {
    return useMutation<typeLoginResponse, AxiosError<server4XXError>, typeLoginDirectPayload>({
        mutationKey: "loginDirect",
        mutationFn: loginDirect,
        onError: handleErrors,
    })
}

type typeDirectSignupPayload = {
    email: string,
    full_name: string,
    phone: number,
    acquired_by?: number,
    captcha: string,
}

async function signupDirect(payload: typeDirectSignupPayload): Promise<typeSuccessResponse> {
    const response = await axiosClient.post<typeDirectSignupPayload, AxiosResponse<typeSuccessResponse>>(import.meta.env.VITE_API_URL + "/login/direct/signup", payload);
    return response.data;
}

function useMutationSignupDirect() {
    return useMutation<typeSuccessResponse, AxiosError<server4XXError>, typeDirectSignupPayload>({
        mutationKey: "signupDirect",
        mutationFn: signupDirect,
        onError: handleErrors,
        onSuccess: (data) => {
            if (data.success) {
                CustomAlert.success("success", "Account has been created successfully, Please check your email.");
            }
        }
    })
}

type typeForgotPasswordPayload = {
    email: string,
    captcha: string,
}

async function forgotPassword(payload: typeForgotPasswordPayload): Promise<typeSuccessResponse> {
    const response = await axiosClient.post<typeForgotPasswordPayload, AxiosResponse<typeSuccessResponse>>(import.meta.env.VITE_API_URL + "/login/direct/forgot_password", payload);
    return response.data;
}


type typeUpdatePasswordDirectPayload = {
    current_password: string,
    password: string,
    confirm_password: string,
    captcha: string,
}

async function updatePasswordDirect(payload: typeUpdatePasswordDirectPayload): Promise<typeSuccessResponse> {
    const response = await axiosClient.post<typeUpdatePasswordDirectPayload, AxiosResponse<typeSuccessResponse>>(import.meta.env.VITE_API_URL + "/login/direct/change_password", payload);
    return response.data;
}

function useMutationUpdatePasswordDirect() {
    return useMutation<typeSuccessResponse, AxiosError<server4XXError>, typeUpdatePasswordDirectPayload>({
        mutationKey: "updatePasswordDirect",
        mutationFn: updatePasswordDirect,
        onError: handleErrors,
        onSuccess: (data) => {
            if (data.success) {
                CustomAlert.success("success", "Password has been updated successfully.");
            }
        }
    })
}


type typeUpdatePassowrdForcedPayload = {
    password: string,
    confirm_password: string,
    captcha: string
}


async function updatePasswordForced(payload: typeUpdatePassowrdForcedPayload): Promise<typeSuccessResponse> {
    const response = await axiosClient.post<typeUpdatePassowrdForcedPayload, AxiosResponse<typeSuccessResponse>>(import.meta.env.VITE_API_URL + "/login/direct/force_change_password", payload);
    return response.data;
}

function useMutationUpdatePasswordForced() {
    return useMutation<typeSuccessResponse, AxiosError<server4XXError>, typeUpdatePassowrdForcedPayload>({
        mutationKey: "updatePasswordForced",
        mutationFn: updatePasswordForced,
        onError: handleErrors,
        onSuccess: (data) => {
            if (data.success) {
                CustomAlert.success("success", "Password has been updated successfully.");
            }
        }
    })
}


function useMutationForgotPassword() {
    return useMutation<typeSuccessResponse, AxiosError<server4XXError>, typeForgotPasswordPayload>({
        mutationKey: "forgotPassword",
        mutationFn: forgotPassword,
        onError: handleErrors,
        onSuccess: (data) => {
            if (data.success) {
                CustomAlert.success("success", "Password reset link has been sent to your email.");
            }
        }
    })
}


type typeVerificationPasswordPayload = {
    token: string,
    password: string,
    confirm_password: string
    captcha: string
}

async function verificationUpdatePassword(payload: typeVerificationPasswordPayload): Promise<typeSuccessResponse> {
    const response = await axiosClient.post<typeVerificationPasswordPayload, AxiosResponse<typeSuccessResponse>>(import.meta.env.VITE_API_URL + "/login/validation/update_password", payload);
    return response.data;
}

function useMutationVerificationUpdatePassword() {
    return useMutation<typeSuccessResponse, AxiosError<server4XXError>, typeVerificationPasswordPayload>({
        mutationKey: "verificationUpdatePassword",
        mutationFn: verificationUpdatePassword,
        onError: handleErrors,
        onSuccess: (data) => {
            if (data.success) {
                CustomAlert.success("success", "Password has been updated successfully.");
            }
        }
    })
}


export { useMutationLoginGoogle, useMutationLoginDirect, useMutationSignupDirect, 
    useMutationForgotPassword, useMutationVerificationUpdatePassword, useMutationUpdatePasswordDirect, useMutationUpdatePasswordForced }