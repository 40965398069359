

function AdminDhasboardCards({data}: {data: [string, string][]}) {
    const bg_color: string[] = ["bg-primary", "bg-warning", "bg-info", "bg-success", "bg-danger", "bg-secondary", "bg-dark"];
    return (
        <div className="row g-4 mb-5">
            {/* Display loading state while data is fetching */}

            {
                data.map((item, index) => {
                    return (
                        <div key={index} className="col-6 col-md-3 col-xl-3 col-xxl-2">
                            <div className={`card card-body ${bg_color[index % bg_color.length]} bg-opacity-10 border border-primary border-opacity-25 p-4 h-100`}>
                                <div className="d-flex justify-content-between align-items-center">
                                    {/* <!-- Digit --> */}
                                    <div>
                                        <h4 className="mb-0">{item[1]}</h4>
                                        <span className="h6 fw-light mb-0">{item[0]}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )

}

export default AdminDhasboardCards;