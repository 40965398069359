import { Link } from "react-router-dom";

function PublicPrivacy() {
    return (
        <section className="pt-4 pt-lg-5">
            <div className="container">
                {/* Title */}
                <div className="row">
                    <div className="col-12 text-center mb-4">
                        <h6>PRIVACY POLICY</h6>
                        <h1 className="fs-2 mb-0">Privacy Policy (ID Maker)</h1>
                        <p>Last updated: March 20, 2024</p>
                    </div>
                </div>

                {/* Content START */}
                <div className="row">
                    <div className="col-md-10 mx-auto">
                        <div className="vstack gap-3">

                            <h5>1. Collecting and Using Your Personal Data</h5>

                            <p>We collect the following data during Google OAuth and, optionally, phone number from your dashboard:</p>
                            <ul>
                                <li>Name</li>
                                <li>Email</li>
                                <li>Unique ID</li>
                                <li>Profile</li>
                            </ul>

                            <p>Please note that we do not store access tokens or refresh tokens in our database. During each card processing, we save the following data:</p>
                            <ul>
                                <li>Card Type</li>
                                <li>Card Owner Name</li>
                                <li>Card ID</li>
                                <li>IP Address of Client</li>
                                <li>Processing Timestamp</li>
                                <li>Unique Processing ID</li>
                            </ul>

                            <p>Rest assured, we prioritize your privacy. While we retain the cards generated for your convenience for 7 days, we never store the original PDF files uploaded by users. Uploaded files are processed in server memory, and all data is promptly flushed out at the conclusion of each request, ensuring your information remains secure and private.</p>

                            <h5>2. Law Enforcement</h5>

                            <p>Under specific circumstances, we may disclose Your Data as required by law or in response to valid requests from public authorities (e.g., court orders or government agencies).</p>

                            <h5>3. Personal Data</h5>

                            <p>We collect and store the following personal data as per OAuth:</p>
                            <ul>
                                <li>Name</li>
                                <li>Email</li>
                                <li>Profile Information</li>
                            </ul>

                            <h5>4. Usage Data on Website</h5>

                            <p>For payment processing, we use Paytm as our payment gateway. Please read Paytm's privacy policies <Link to="https://pages.paytm.com/privacy.html" className="text-muted text-primary-hover" target="_blank" rel="noopener noreferrer">here</Link>.</p>

                            <h5>5. Definitions</h5>

                            <p>For this Privacy Policy:</p>
                            <ul>
                                <li><strong>Account</strong>: A unique account created for You to access our Service.</li>
                                <li><strong>Application</strong>: The software program provided by the Company, named "ID Maker."</li>
                                <li><strong>Company</strong>: Refers to ID Maker.</li>
                                <li><strong>Cookies</strong>: Small files placed on Your device by a website, containing details of Your browsing history.</li>
                                <li><strong>Personal Data</strong>: Any information related to an identified or identifiable individual.</li>
                                <li><strong>Service</strong>: Refers to the Application or the Website or both.</li>
                                <li><strong>Usage Data</strong>: Data collected automatically, generated by using the Service or its infrastructure.</li>
                                <li><strong>Website</strong>: Refers to ID Maker, accessible from http://idcard.store.</li>
                                <li><strong>You/User</strong>: The individual or legal entity accessing or using the Service.</li>
                            </ul>

                            <h5>6. Links to Other Websites</h5>

                            <p>Our Service may contain links to third-party websites not operated by Us. We encourage You to review the Privacy Policies of these websites. We do not control or assume responsibility for the content, privacy policies, or practices of third-party sites or services.</p>

                            <h5>7. User Privacy</h5>

                            <p>You have the right to request the deletion of all Your data. This process may take up to 1 week to complete.</p>
                            <p>You allow us to retrieve the necessary information for card creation from our pool of open data sources mentioned in <a href="https://idcard.store/how-we-make-cards" className="text-muted text-primary-hover">How We Make Cards</a>.</p>
                            <p>You also allow us to keep some basic information of the card for account and transaction purposes.</p>

                            <h5>8. Changes to this Privacy Policy</h5>

                            <p>We may update this Privacy Policy periodically. You will be notified of changes via email. Please review this Privacy Policy regularly for updates. Changes become effective when posted on this page.</p>

                            <h5>9. Data Sharing</h5>

                            <p>Your data will not be shared, sold, or used for any internal purposes without consent.</p>


                            <h5>10. Data Retention and Security</h5>

                            <p>We now save all generated cards for 7 days to allow users to download them again within this period. After 7 days, the cards are automatically deleted from the CDN.</p>
                            <p>We implement stringent security measures and adhere to industry standards to ensure the safety of our users' data.</p>


                            <h5>10. Contact Us</h5>

                            <p>If You have any questions or concerns about this Privacy Policy, please contact us at support@idcard.store.</p>

                        </div>
                    </div>
                </div>
                {/* Content END */}
            </div>
        </section>
    );
}


export default PublicPrivacy;