import { useMutationGetAllPendingPaymentsPaytm } from '../../../../api/paytm';
import { useMutationGetAllPendingPayments } from '../../../../api/admin';

import CustomTable from '../../../Admin/Components/CustomTable';


function AdminPendingPayment() {

    const { data, isLoading, mutate } = useMutationGetAllPendingPaymentsPaytm();
    const { data: data2, isLoading: isLoading2, mutate: mutate2 } = useMutationGetAllPendingPayments();

    function onSubmitPaytm () {
        mutate(null)
    }

    function onSubmit () {
        mutate2(null)
    }


    console.log(data)


    return (
        <>
            <div className="row mb-4">
                <div className="col-md-6 mx-auto">
                    <div className="card border">
                        <div className="card-header border-bottom">
                            <h5 className="card-header-title">Pending Payment.</h5>
                            <p className="mb-0 small">Manually Check pending payments.</p>
                        </div>
                        {/* <!-- Card body START --> */}

                        <div className="card-footer border-top">
                            <div className="text-center row">
                                <button className="col m-2 btn btn-primary fs-5" onClick={onSubmitPaytm} disabled={isLoading}>
                                    <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{ display: !isLoading ? 'None' : "" }}></span>
                                    <span role="status">Paytm</span>
                                </button>

                                <button className="col m-2 btn btn-primary fs-5" onClick={onSubmit} disabled={isLoading2}>
                                    <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{ display: !isLoading2 ? 'None' : "" }}></span>
                                    <span role="status">Database</span>
                                </button>
                            </div>
                        </div>


                        {/* <!-- Card body END --> */}
                    </div>
                </div>
            </div>

            { data && <CustomTable data={data as any} columns={["merchantOrderId", "amount", "orderSearchStatus", "orderCompletedTime"]} title={"Pending Payments"} /> }
            { data2 && <CustomTable data={data2 as any} columns={["id", "user_id", "payment_amount", "order_id", "payment_gateway", "check_count", "created_at"]} title={"Pending Payments"} /> }

        </>
    )
}


export default AdminPendingPayment;