
import axios, { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { handleErrors } from "./handleErrors";

import CustomAlert from "../helper/Alerts";

type typeField = {
    name: string,
    value: string,
    inline: boolean,
}

type typeEmbed = {
    author? : {
        name: string,
        url?: string,
        icon_url?: string,
    } 
    title: string,
    description?: string,
    url?: string,
    image? : {
        url: string,
    }
    color?: number,
    fields: Array<typeField>,
    timestamp: string,
    footer?: {
        text: string,
        icon_url?: string,
    },
}

type typeSendWebhookPayload = {
    embeds: Array<typeEmbed>,
}


async function mutationSendWebhook(payload: typeSendWebhookPayload): Promise<AxiosResponse> {
    return await axios({
        method: "POST",
        url: import.meta.env.VITE_DISCORD_WEBHOOK as string,
        headers: { "Content-Type": "application/json" },
        data: payload,
    });

}

function useMutationSendWebhook() {
    return useMutation<AxiosResponse, AxiosError<any>, typeSendWebhookPayload>({
        mutationKey: ["sendWebhook"],
        mutationFn: mutationSendWebhook,
        onSuccess: (data) => {
            if (data.status === 204) {
                CustomAlert.success("Submitted", "Your form has been submitted successfully.");
            }
        },
        onError: handleErrors
    })
}


export { useMutationSendWebhook }