import axios from 'axios';
import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { server4XXError } from "./types";


type typeSystemSettings = {
    PUBLIC_MAINTENANCE: boolean;
}

const getSystemSettings = async (): Promise<typeSystemSettings> => {
    const response = await axios.get<typeSystemSettings>("https://temp15445.blr1.digitaloceanspaces.com/site.json");
    return response.data;
};
const useSystemSettings = (isLogin:boolean) => {
    return useQuery<typeSystemSettings, AxiosError<server4XXError>>({
        queryKey: ["userPayments"],
        queryFn: getSystemSettings,
        retry:false,
        enabled: isLogin,
        refetchOnWindowFocus: false,
    });
};


export { useSystemSettings };