import PublicDashboardIDCard from "./Helper/Card";
import PublicDashboardTitle from "../Components/Title";
// import useHttpClient from "../../../../hooks/useHttpClient";
// import { useParams } from 'react-router-dom';
import { useContext } from "react";
import AuthContext from '../../../../context/auth';
import AdsComponent from '../../../../components/GoogleAds/GoogleAds';

import React from "react";
import { useState } from "react";

function PublicDashboardAllStates() {
    const { states } = useContext(AuthContext);
    const [search, setSearch] = useState("");

    // Function to generate menu items
    const generateMenuItems = () => {
        const menuItems = states
            .filter(n => n.name.toLowerCase().includes(search.toLowerCase()))
            .map((n, index) => (
                <PublicDashboardIDCard key={index} icon={n.icon} name={n.name} to={n.shortName()} imgclass={"w-120px"} />
            ));

        // Insert AdsComponent after every 12 menu items
        const menuItemsWithAds:JSX.Element[] = [];
        for (let i = 0; i < menuItems.length; i++) {
            menuItemsWithAds.push(menuItems[i]);
            if ((i + 1) % 12 === 0) {
                menuItemsWithAds.push(
                    <div className="col-xl-10 mx-auto" key={`ad_${i}`}>
                        <AdsComponent />
                    </div>
                );
            }
        }
        return menuItemsWithAds;
    };

    // Generate menu items and ads
    const menuItems = generateMenuItems();

    return (
        <>
            <PublicDashboardTitle title={"All States"} icon={"fi fi-in fa-fw me-1"} addcardbtn={true} />

            <section className="pt-0">
                <div className="container">


                    <div className="row g-4">


                        <div className="col-xl-10 mx-auto">
                            <div className="card shadow h-100 p-4">
                                <form className="row g-3 justify-content-center align-items-center">
                                    <div className="col-lg-5">
                                        {/* <!-- Input --> */}
                                        <div className="form-input-dropdown position-relative">
                                            <input className="form-control form-control-lg me-1 ps-5"
                                                type="text" placeholder="Search Your State"
                                                aria-expanded="false" onChange={(e) => setSearch(e.target.value)} />

                                            {/* <!-- Icon --> */}
                                            <span className="position-absolute top-50 start-0 translate-middle ps-5"><i className="bi bi-search fs-5"></i></span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>



                        {menuItems.map((menuItem, index) => (
                            <React.Fragment key={index}>
                                {menuItem}
                            </React.Fragment>
                        ))}


                        <div className="col-xl-10 mx-auto">
                            <AdsComponent />
                        </div>
                    </div>


                </div>
            </section>
        </>
    );
}


export default PublicDashboardAllStates;