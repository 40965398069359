import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// import toast from 'react-hot-toast';
import Confetti from './Confetti';
import toast from 'react-hot-toast';
const MySwal = withReactContent(Swal)

function alertLogin() {
    MySwal.fire({
        title: "Please Login First",
        imageUrl: "https://ik.imagekit.io/MayankFawkes/idmaker/assets/login.gif",
        html: (`
                <p>Click the button to open the login page:</p>
                <a href="/login?redirect=${window.location.pathname}">
                    <button type="button" class="btn btn-outline-primary btn-lg">
                        Open Login Page
                    </button>
                </a>`
        ),
        showConfirmButton: false,
        allowOutsideClick: false,
        backdrop: `
                rgba(0,0,0,0.5)
                -webkit-filter: blur(2px)
                opacity: 0.9
                color: #fff`,
    })
}

function alertPaymentSuccess(title: string, text: string) {
    const { PartyPride } = Confetti();
    MySwal.fire({
        imageUrl: 'https://media.tenor.com/0LXnZAxawlEAAAAC/mrunal-thakur-flying-kiss-jersey.gif',
        imageWidth: 200,
        title: title,
        text: text,
        confirmButtonText: 'Lovely!',
        showClass: {
            popup: 'animate__animated animate__flip'
        }
    })
    PartyPride(1);
}


const CustomAlertOld = {
    "success": (title, text) => {
        return Swal.fire({
            icon: 'success',
            title: title,
            text: text,
        })
    },
    "warning": (title, text) => {
        return Swal.fire({
            icon: 'warning',
            title: title,
            text: text,
        })
    },
    "error": (title, text) => {
        return Swal.fire({
            icon: 'error',
            title: title,
            text: text,
        })
    },
    "loading": (title, text, img) => {
        return Swal.fire({
            title: title || "Loading",
            text: text || "Please Wait",
            imageUrl: img || "https://ik.imagekit.io/MayankFawkes/idmaker/assets/tr:h-200/loading.gif",
            showConfirmButton: false,
            allowOutsideClick: false
        });
    }
}


const CustomAlert = {
    "success": (title, text, options = {}) => {
        return toast.success(() => (
            <span>
                <b>{title}</b> {text}
            </span>
        ), options);
    },
    "warning": (title, text) => {
        return Swal.fire({
            icon: 'warning',
            title: title,
            text: text,
        })
    },
    "error": (title, text, options = {}) => {
        return toast.error(() => (
            <span>
                <b>{title}</b> {text}
            </span>
        ), options);
    },
    "newLoading": (title, text, options = {}) => {
        return toast.loading(() => (
            <span>
                <b>{title}</b> {text}
            </span>
        ), options);
    },
    "loading": (title, text, img) => {
        return Swal.fire({
            title: title || "Loading",
            text: text || "Please Wait",
            imageUrl: img || "https://ik.imagekit.io/MayankFawkes/idmaker/assets/tr:h-200/loading.gif",
            showConfirmButton: false,
            allowOutsideClick: false
        });
    }
}



export { alertLogin, alertPaymentSuccess, CustomAlertOld };

export default CustomAlert;