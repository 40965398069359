
import { useEffect, useState } from 'react';

const CONSTANTS = {
    PROJECT_NAME: 'Paytm Blink Checkout JS',
    ENV: {
        PROD: 'prod',
        DEV: 'test'
    },
    ERRORS: {
        INIT: 'An error during initialization!',
        INVOKE: 'An error during invoking!',
        MERCHANT_ID_NOT_FOUND: 'Please provide merchant id!',
        CHECKOUT_NOT_AVAILABLE: 'Checkout JS library not found. Please make sure you have included checkout js!',
        INVALID_CHECKOUT_JS_INSTANCE: 'Invalid instance provided!',
        FAILED_TO_LOAD_SCRIPT: 'Failed to load script!'
    },
    IDS: {
        CHECKOUT_ELEMENT: 'paytm-checkout-wrapper-'
    }
};

type EasebuzzCheckoutType = {
    initiatePayment: (config: any) => Promise<any>;
}

declare global {
    interface Window {
        EasebuzzCheckout: new (merchantId: string, env: string) => EasebuzzCheckoutType;
    }
}


function EaseBuzzCheckOut() {
    const [isLoading, setIsLoading] = useState(true);
    const [checkoutJsInstance, setCheckoutJsInstance] = useState<EasebuzzCheckoutType>({} as EasebuzzCheckoutType);

    const MERCHANT_ID = import.meta.env.VITE_EASEBUZZ_MID;

    const loadCheckoutScript = () => {
        const scriptElement = document.createElement('script');
        scriptElement.async = true;
        scriptElement.src = "https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/v2.0.0/easebuzz-checkout-v2.min.js";
        scriptElement.type = 'application/javascript';
        scriptElement.onload = () => {
            const checkoutJsInstance = new window.EasebuzzCheckout(MERCHANT_ID, import.meta.env.DEV? "test": "prod");

            if (!checkoutJsInstance) {
                console.error(CONSTANTS.ERRORS.CHECKOUT_NOT_AVAILABLE);
                setIsLoading(false);
                return;
            }

            setCheckoutJsInstance(
                checkoutJsInstance
            );
            setIsLoading(false);

        };
        scriptElement.onerror = () => {
            console.error('script load fail!');
            setIsLoading(false);
        }

        document.body.appendChild(scriptElement);
    }
    
    useEffect(() => {
        loadCheckoutScript();
    }, [])

    return { checkoutJsInstance, isLoading }
}

export default EaseBuzzCheckOut;