// import { useParams } from 'react-router-dom';
import PublicDashboardTitle from "../Components/Title";

import AuthContext from '../../../../context/auth';
import { useContext } from 'react';
import PublicDashboardIDCard from "./Helper/Card";
import AdsComponent from '../../../../components/GoogleAds/GoogleAds';

function PublicDashboardCardsByState() {
    const { fn } = useContext(AuthContext);

    const cards = fn.allFree();

    let menuItems: JSX.Element[] = [];
    var count = 0;
    for (let n of cards) {
        menuItems.push(<PublicDashboardIDCard key={count} icon={n.icon} name={n.name} to={n.path} price={"Free"} />);
        count++;
    }

    menuItems.push(
        <div className="col-12" key={999}>
            <AdsComponent dataAdSlot="2043415968" />
        </div>
    )

    return (
        <>
            <PublicDashboardTitle title={"All Free Cards"} icon={""} addcardbtn={true} />

            <section className="pt-0">
                <div className="container">


                    <div className="row g-4">
                        {menuItems}
                    </div>
                    

                </div>
            </section>

        </>
    )
}


export default PublicDashboardCardsByState;