
import axiosClient from "../hooks/useAxios";
import { AxiosResponse, AxiosError } from "axios";

import { server4XXError, typeUserPayment, typeUserTransaction, typeUser} from "./types";
import { useMutation, UseQueryResult, useQuery } from "react-query";
import { handleErrors } from "./handleErrors";

import CustomAlert from "../helper/Alerts";


type typeUserPaymentSlipPayload = {
    unique_id: string,
    captcha: string
}

type typeUserPaymentSlipResponse = {
    payment: typeUserPayment,
    user: typeUser,
    transaction: typeUserTransaction
}

const mutationUserPaymentSlip = async (payload: typeUserPaymentSlipPayload): Promise<typeUserPaymentSlipResponse>  => {
    const response = await axiosClient.post<typeUserPaymentSlipPayload, AxiosResponse<typeUserPaymentSlipResponse>>("payment_slip", payload);
    return response.data;
}

const useMutationUserPaymentSlip = () => {
    return useMutation<typeUserPaymentSlipResponse, AxiosError<server4XXError>, typeUserPaymentSlipPayload>({
        mutationKey: ["UserPaymentSlip"],
        mutationFn: mutationUserPaymentSlip,
        onError: handleErrors
    })
}

const getUserPaymentSlip = async (payload: typeUserPaymentSlipPayload): Promise<typeUserPaymentSlipResponse> => {
    const response = await axiosClient.post<typeUserPaymentSlipPayload, AxiosResponse<typeUserPaymentSlipResponse>>("payment_slip", payload);
    return response.data;
};

const useUserPaymentSlipQuery = (payload: typeUserPaymentSlipPayload): UseQueryResult<typeUserPaymentSlipResponse> => {
    return useQuery(['UserPaymentSlip', payload.unique_id], () => getUserPaymentSlip(payload), {
        onError: handleErrors,
    });
};

type typeUserAddGiftCardPayload = {
    giftcard: string,
    captcha: string,
}

type typeSuccessResponse = {
    success: boolean,
}

const mutationUserAddGiftCard = async (payload: typeUserAddGiftCardPayload): Promise<typeSuccessResponse>  => {
    // console.log("options", options);
    const response = await axiosClient.post<typeUserAddGiftCardPayload, AxiosResponse<typeSuccessResponse>>("giftcard", payload);
    return response.data;
}

const useMutationUserAddGiftCard = () => {
    return useMutation<typeSuccessResponse, AxiosError<server4XXError>, typeUserAddGiftCardPayload>({
        mutationKey: ["UserAddGiftCard"],
        mutationFn: mutationUserAddGiftCard,
        onError: handleErrors,
        onSuccess: (data) => {
            if (data.success) {
                CustomAlert.success("Gift Card Added", "Your gift card has been added successfully.");
            }
        }
    })
}



export { useMutationUserPaymentSlip, useUserPaymentSlipQuery, useMutationUserAddGiftCard }