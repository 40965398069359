import { useState} from "react";

import { useEffect } from "react";

import { FormatDate } from "../../../helper/Time";
import { typeUser } from '../../../api/types';


function UsersTable({data, title=""}: {data: typeUser[], title?: string}) {
    const [page, setPage] = useState(1);
    const [pagination , setPagination] = useState({
        perPage: 5,
        maxPage: 0,
        startIndex: 0,
        endIndex: 5,
        total: 0
    });

    useEffect(() => {
        if (data) {
            setPagination({
                ...pagination,
                maxPage: Math.ceil(data.length / pagination.perPage),
                total: data.length,
                startIndex: (page - 1) * pagination.perPage,
                endIndex: page * pagination.perPage
            })
        }
    }, [data, page])

    return (
        <div className="card shadow mb-5">
            {/* <!-- Card header --> */}
            <div className="card-header border-bottom">
                <h5 className="card-title mb-0">{title || "Recent Users"}</h5>
            </div>

            {/* <!-- Card body START --> */}
            <div className="card-body">
                {/* <!-- Table head --> */}
                <div className="bg-light rounded p-3 d-none d-sm-block">
                    <div className="row row-cols-7 g-4">
                        <div className="col col-md-1"><h6 className="mb-0">Icon</h6></div>
                        <div className="col col-md-1"><h6 className="mb-0">ID</h6></div>
                        <div className="col col-md-2"><h6 className="mb-0">Name</h6></div>
                        <div className="col col-md-3"><h6 className="mb-0">Email</h6></div>
                        <div className="col col-md-2"><h6 className="mb-0">Balance</h6></div>
                        <div className="col col-md-3"><h6 className="mb-0">Time</h6></div>
                    </div>
                </div>

                {/* <!-- Table data --> */}
                {
                    data.length? 
                    (
                        data.slice(pagination.startIndex, pagination.endIndex).map((item, index) => {
                            return (
                                <div key={index} className="row row-cols-xl-7 g-4 align-items-sm-center border-bottom px-2 py-4">

                                    {/* <!-- Data item --> */}
                                    <div className="col col-md-1 d-none d-sm-block">
                                        <div className="avatar">
                                            <img className="fw-light mb-0 avatar-img rounded-circle" src={item.avatar} referrerPolicy="no-referrer" alt="avatar"></img>
                                        </div>
                                    </div>

                                    {/* <!-- Data item --> */}
                                    <div className="col col-md-1">
                                        <small className="d-block d-sm-none">ID:</small>
                                        <h6 className="fw-light mb-0">#{item.id}</h6>
                                    </div>

                                    {/* <!-- Data item --> */}
                                    <div className="col col-md-2">
                                        <small className="d-block d-sm-none">Name:</small>
                                        <h6 className="fw-light mb-0">{item.full_name}</h6>
                                    </div>

                                    {/* <!-- Data item --> */}
                                    <div className="col col-md-3 d-none d-sm-block">
                                        <small className="d-block d-sm-none">Email:</small>
                                        <h6 className="mb-0 fw-light">{item.email}</h6>
                                    </div>

                                    {/* <!-- Data item --> */}
                                    <div className="col col-md-2">
                                        <small className="d-block d-sm-none">Balance:</small>
                                        <h6 className="fw-light mb-0">{item.acquired_by? `A-${item.balance}`: `B-${item.balance}`}</h6>
                                    </div>

                                    {/* <!-- Data item --> */}
                                    <div className="col col-md-3">
                                        <small className="d-block d-sm-none">Time:</small>
                                        <h6 className="fw-light mb-0">{FormatDate(item.created_at)}</h6>
                                    </div>
                                </div>
                            )
                        })
                    ):
                    (
                        <div className="row align-items-center px-2 pt-4">
                            <p className="text-center">No Data Found</p>
                        </div>
                    )
                }


            </div>
            {/* <!-- Card body END --> */}


            <div className="card-footer pt-0">
                {/* <!-- Pagination and content --> */}
                <div className="d-sm-flex justify-content-sm-between align-items-sm-center">
                    {/* <!-- Content --> */}
                    <p className="mb-sm-0 text-center text-sm-start">Showing {pagination.startIndex + 1 } to {pagination.endIndex} of {pagination.total} entries</p>
                    {/* <!-- Pagination --> */}
                    <nav className="mb-sm-0 d-flex justify-content-center" aria-label="navigation">
                        <ul className="pagination pagination-sm pagination-primary-soft mb-0">
                           
                            <li className="page-item">
                                <button className="btn btn-outline-primary btn-sm" onClick={() => setPage( (e) => e-1 ) } disabled={page === 1}><i className="fas fa-angle-left ms-2"/> Prev</button>
                            </li>
                            <li className="page-item">
                                <button className="btn btn-outline-primary btn-sm" onClick={() => setPage( (e) => e+1 ) } disabled={page === pagination.maxPage}>Next <i className="fas fa-angle-right ms-2"/></button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

        </div>

    );
}


export default UsersTable;