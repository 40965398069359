import { createContext } from 'react';
import { TypeCards, TypeStates, TypeCardValue } from "../interfaces/Interface";
import { typeUser as User } from "../api/types";

export interface FnType {
    fullStateName: (sc:any) => string,
    cardsForState: (sc:any) => TypeCardValue[],
    getCardPageSettings: (sc:any) => TypeCardValue,
    allFree: () => TypeCardValue[],
}
console.log(import.meta.env, import.meta.env.SOME_OTHER)
const AuthContext = createContext({
    token: "",
    user: {} as User,
    // @ts-ignore: Unreachable code error
    setToken: (token: string) => {},
    logout: () => {},
    refreshUser: () => {},
    isReady: false,
    isLogin: false,
    states: [] as TypeStates[],
    cards: {} as TypeCards,
    fn: {} as FnType,
    // @ts-ignore: Unreachable code error
    setUser: (user: User) => {},
})


export default AuthContext;